import { Group, SegmentedControl, SegmentedControlProps } from "@mantine/core";
import { css } from "vite-plugin-inline-css-modules";

// .more-specific-css-overide is a hack to override mantine without using !important on every css property

const classes = css`
  .root:not(.more-specific-css-overide) {
    background-color: var(--mantine-color-white) !important;
    border: rem(1px) solid var(--mantine-color-gray-4) !important;
    padding: 2px;
    padding-bottom: 5px;
    padding-top: 2px;
    padding-left: 2px;
    padding-right: 4px;
    height: 37px;
    width: 100%;
    margin-bottom: 4px;
  }

  .indicator:not(.more-specific-css-overide) {
    background: var(--mantine-color-brand-7) !important ;
  }

  .label:not(.more-specific-css-overide) {
    padding-top: 5px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    height: 29px;
    &,
    &:hover {
      &[data-active] {
        color: var(--mantine-color-white) !important;
      }
    }
  }
`;

export const InputDollarPercentSwitch = ({
  value,
  onChange,
  ...props
}: Omit<SegmentedControlProps, "value" | "onChange" | "data"> & {
  value: boolean;
  onChange: (value: string) => void;
}) => {
  return (
    <Group justify="center">
      <SegmentedControl
        {...props}
        classNames={classes}
        fullWidth
        data={[
          { label: "Dollar ($)", value: "dollar" },
          { label: "Percent (%)", value: "percent" },
        ]}
        value={value ? "dollar" : "percent"}
        onChange={onChange}
      />
    </Group>
  );
};
