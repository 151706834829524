import { Header } from "@/components/Header/Header";
import { Box, Container, TypographyStylesProvider } from "@mantine/core";
import { ReactNode } from "react";
import { Footer } from "../Footer/Footer";

export const LayoutBoilerplateText = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <Box>
      <Header />
      <Container size="lg" my={"xl"}>
        <TypographyStylesProvider>{children}</TypographyStylesProvider>
      </Container>
      <Footer />
    </Box>
  );
};
