import { Stack } from "@mantine/core";
import { SegmentDSCR } from "./SegmentDSCR";
import { SegmentFinanceStructure } from "./SegmentFinanceStructure";
import { SegmentLoanAssumptionSBA } from "./SegmentLoanAssumptionSBA";
import { SegmentLoanAssumptionSellersNote } from "./SegmentLoanAssumptionSellersNote";
import { SegmentPurchaseMetrics } from "./SegmentPurchaseMetrics";
import { SegmentTargetPurchaseMultiple } from "./SegmentTargetPurchaseMultiple";

export const Questionnaire = () => {
  return (
    <Stack gap={"xl"}>
      <SegmentPurchaseMetrics />
      <SegmentTargetPurchaseMultiple />
      <SegmentFinanceStructure />
      <SegmentLoanAssumptionSBA />
      <SegmentLoanAssumptionSellersNote />
      <SegmentDSCR />
    </Stack>
  );
};
