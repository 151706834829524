import { Route } from "@/paths";
import { ActionIcon, Group } from "@mantine/core";
import { FaCircleUser } from "react-icons/fa6";
import { Link } from "react-router-dom";

export const HeaderButtons = () => {
  return (
    <Group gap={"xs"}>
      {/* <Button
        visibleFrom="lg"
        size="xs"
        variant="outline"
        leftSection={<RiGraduationCapFill size={16} />}
        color="brand"
      >
        Learning Center
      </Button> */}
      <ActionIcon
        variant="subtle"
        size={"lg"}
        component={Link}
        to={Route.Profile}
      >
        <FaCircleUser size={20} />
      </ActionIcon>
    </Group>
  );
};
