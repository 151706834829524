import { DeferredRender } from "@/components/DeferedRender/DeferedRender";
import {
  Box,
  Divider,
  Paper,
  ScrollArea,
  SimpleGrid,
  Stack,
  Text,
} from "@mantine/core";
import { NameInput } from "../../Sidebar/Sidebar";
import { items } from "../../Sidebar/manifest";

export const PanelInputs = () => {
  return (
    <ScrollArea scrollbarSize={8} h={"100%"} offsetScrollbars>
      <Stack>
        <Paper withBorder p="sm">
          <Text size="sm" fw={600} mb="xs">
            Model Name
          </Text>
          <Divider />
          <NameInput />
        </Paper>
        <SimpleGrid cols={{ base: 1, sm: 1, lg: 5 }}>
          {items.map(
            (item) =>
              !!item.panel && (
                <DeferredRender key={item.key}>
                  <Box>
                    <Paper withBorder p="sm">
                      <Stack gap={"xs"}>
                        <Text size="sm" fw={600}>
                          {item.key}
                        </Text>
                        <Divider mb="xs" />
                        {item.panel}
                      </Stack>
                    </Paper>
                  </Box>
                </DeferredRender>
              ),
          )}
        </SimpleGrid>
      </Stack>
    </ScrollArea>
  );
};
