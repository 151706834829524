import { auth } from "@/firebase";
import { User } from "firebase/auth";
import { ReactNode, createContext, useContext } from "react";
import { useAuthState } from "react-firebase-hooks/auth";

type AuthContextValue = {
  user: User | null | undefined;
  loading: boolean;
  error: Error | undefined;
};

export const AuthContext = createContext<AuthContextValue>({
  user: undefined,
  loading: true,
  error: undefined,
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, loading, error] = useAuthState(auth);

  return (
    <AuthContext.Provider value={{ user, loading, error }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  return useContext(AuthContext);
};
