// ivarkol/paste_excel.js
// Modified from:
// https://gist.github.com/ivarkol/c51a7074789a8ab95c33ba16eb5b7285

export const parseTextToCells = (pastedText: string) => {
  if (!pastedText && pastedText.length) {
    return [];
  }

  // Parse the pasted text from Excel into rows.
  // Pasted text is usually separated by a new line for each row,
  // but a single cell can contain multiple lines, which is what
  // we pars out in the first `replace`.
  //
  // We find all text within double-quotes ('"') which has new
  // lines, and put the text within the quotes into capture
  // groups. For each match, we replace its contents again, by
  // removing the new lines with spaces.
  //
  // Then lastly, once we've joined all the multi line cells, we
  // split the entire pasted content on new lines, which gives
  // us an array of each row.
  //
  // Since Windows usually uses weird line-endings, we need to
  // ensure we check for each of the different possible
  // line-endings in every regexp.
  //
  // It also handles cells which contains quotes. There appears
  // to be two ways this is handled. In Google Docs, quotes within
  // cells are always doubled up when pasting, so " becomes "".
  // In Libre Office, the quotes are not normal quotes, some
  // other character is used, so we don't need to handle it any
  // differently.
  return (
    pastedText
      .replace(
        /(^|\t|\r\n|\n\r|\n|\r)"((?:(?:"{2}|[^"])*(?:\r\n|\n\r|\n|\r))+(?:"{2}|[^"])+)"($|\t|\r\n|\n\r|\n|\r)/gm,
        function (_match, p1, p2, p3) {
          // This function runs for each cell with multi lined text.
          return (
            (p1 + p2 + p3)
              // Replace any double double-quotes with a single
              // double-quote
              .replace(/""/g, '"')
              // Replace all new lines with spaces.
              .replace(/\r\n|\n\r|\n|\r/g, " ")
          );
        },
      )
      // Split each line into rows
      .split(/\r\n|\n\r|\n|\r/g)
      // Split each row into cells
      .map((row) => row.split("\t"))
  );
};
