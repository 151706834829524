import {
  buyerCompBuyersSalaryAtom,
  buyerCompHealthBenefitsAtom,
  buyerCompPayrollTaxesAtom,
  buyerCompTotalAtom,
} from "@/financeModels/owasco/general";
import { formatDollar } from "@/utils/format";
import { toNumberOrNull } from "@/utils/math";
import { Stack } from "@mantine/core";
import { useAtom, useAtomValue } from "jotai";
import { TbCoins } from "react-icons/tb";
import { ComputedResult } from "./ComputedResult";
import { InputDollar } from "./InputDollar";

const Panel = () => {
  const [buyerSalary, setBuyerSalary] = useAtom(buyerCompBuyersSalaryAtom);
  const [buyerPayrollTaxes, setBuyerPayrollTaxes] = useAtom(
    buyerCompPayrollTaxesAtom,
  );
  const [buyerHealthBenefits, setBuyerHealthBenefits] = useAtom(
    buyerCompHealthBenefitsAtom,
  );
  const totalCompensation = useAtomValue(buyerCompTotalAtom);

  return (
    <Stack gap={4}>
      <InputDollar
        label={"Base Salary"}
        value={Number(buyerSalary)}
        onChange={(val) => setBuyerSalary(toNumberOrNull(val))}
      />
      <InputDollar
        label={"Payroll Taxes"}
        value={Number(buyerPayrollTaxes)}
        onChange={(val) => setBuyerPayrollTaxes(toNumberOrNull(val))}
      />
      <InputDollar
        label={"Health Benefits"}
        value={Number(buyerHealthBenefits)}
        onChange={(val) => setBuyerHealthBenefits(toNumberOrNull(val))}
      />

      <ComputedResult
        label=" Total Compensation"
        value={formatDollar(toNumberOrNull(totalCompensation) || 0)}
      />
    </Stack>
  );
};

export const ModuleBuyersCompensation = {
  key: "Annual Buyer's Compensation",
  icon: <TbCoins />,
  control: "Buyer's Compensation",
  panel: <Panel />,
};
