import { firestore } from "@/firebase";
import { addDoc, collection } from "firebase/firestore";
import { useState } from "react";

export const newsletterSignupsRef = collection(firestore, "newsletterSignups");

export const useNewsletterSignup = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const signup = async (email: string) => {
    return addDoc(newsletterSignupsRef, { email, createdAt: new Date() })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return [signup, loading, error] as const;
};
