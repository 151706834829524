import { sidebarAccordionStateAtom } from "@/financeModels/owasco/general";
import { Group, Accordion as MantineAccordion, Text } from "@mantine/core";
import { atom, useAtom, useAtomValue } from "jotai";
import { BiSolidError } from "react-icons/bi";
import { css } from "vite-plugin-inline-css-modules";
import { items } from "./manifest";

const classes = css`
  .item {
    background: white;
    &[data-active] {
      [data-accordion-control="true"] {
        border-bottom: 1px dashed var(--mantine-color-gray-3);
      }

      &[data-rotate] {
        transform: rotate(-90deg);
      }
    }
  }
`;

export const Accordion = () => {
  const [state, setState] = useAtom(sidebarAccordionStateAtom);

  return (
    <MantineAccordion
      variant="separated"
      multiple
      value={Array.isArray(state) ? state : []}
      onChange={(value) => setState(value.length === 0 ? null : value)}
      pr={"sm"}
      radius={"xs"}
    >
      {items.map((item) => (
        <AccordionItem key={item.key} item={item} />
      ))}
    </MantineAccordion>
  );
};

const stubAtom = atom(false);

export const AccordionItem = ({ item }: { item: (typeof items)[0] }) => {
  const isInError = useAtomValue(item?.errorAtom || stubAtom);

  return (
    <MantineAccordion.Item
      className={classes.item}
      value={item.key}
      style={{
        borderLeft: "4px solid var(--mantine-color-infomatic-2)",
      }}
    >
      <MantineAccordion.Control
        icon={item.icon}
        style={{
          background: !isInError
            ? "transparent"
            : `repeating-linear-gradient(
          45deg,
          transparent,
          transparent 10px,
          #97020211 10px,
          #97020211 20px
        )`,
        }}
      >
        <Group align="center">
          <Text size="sm" lh={1.6} fw={600}>
            {item.control}
          </Text>
          {isInError && <BiSolidError size={20} color="red" />}
        </Group>
      </MantineAccordion.Control>
      <MantineAccordion.Panel pt="sm">
        {!!item.panel && item.panel}
      </MantineAccordion.Panel>
    </MantineAccordion.Item>
  );
};
