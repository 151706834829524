import { NumberInput, NumberInputProps } from "@mantine/core";

export const InputPercent = ({
  value,
  onChange,
  labelWidth = 180,
  label,
  ...props
}: NumberInputProps & {
  labelWidth?: number;
}) => {
  return (
    <NumberInput
      allowDecimal={true}
      label={label}
      value={value}
      onChange={onChange}
      rightSection="%"
      min={0}
      max={100}
      step={0.05}
      ta={"right"}
      placeholder="0"
      hideControls
      styles={{
        root: { display: "flex", alignItems: "center" },
        label: { width: labelWidth, textAlign: "left" },
        wrapper: { flex: 1 },
        input: { textAlign: "right" },
      }}
      {...props}
    />
  );
};
