import tinyMinusIcon from "@/assets/minus.png";
import tinyXIcon from "@/assets/multiply.png";
import tinyPlusIcon from "@/assets/plus.png";
import { DeferredRender } from "@/components/DeferedRender/DeferedRender";
import { CellTypes } from "@/financeModels/owasco/types";
import { ZIndexOrder } from "@/theme";
import {
  ActionIcon,
  Box,
  Collapse,
  Group,
  GroupProps,
  Stack,
  Tooltip,
} from "@mantine/core";
import { Atom, PrimitiveAtom } from "jotai";
import { CSSProperties, Fragment, ReactNode, useState } from "react";
import { AiOutlineFunction } from "react-icons/ai";
import { LuSigma } from "react-icons/lu";
import { MdClose } from "react-icons/md";
import { css } from "vite-plugin-inline-css-modules";
import { CellRow } from "./CellRow";
import { defaultHeight } from "./cell/Wrapper";

const color = "var(--mantine-color-brand-6)";
const lineSize = 2;
const dotSize = 10;

const classes = css`
  .indent {
    & .indent {
      transform: translateX(20px);
    }

    :global(.label-column-sticky) {
      box-shadow:
        -5px 0px 0px 0px var(--mantine-color-gray-3),
        -7px 0px 0px 0px var(--mantine-color-brand-8),
        -25px 0px 0px 0px var(--mantine-color-gray-3),
        -27px 0px 0px 0px var(--mantine-color-brand-8),
        -45px 0px 0px 0px var(--mantine-color-gray-3),
        -47px 0px 0px 0px var(--mantine-color-brand-8),
        -65px 0px 0px 0px var(--mantine-color-gray-3),
        -67px 0px 0px 0px var(--mantine-color-brand-8),
        -99px 0px 0px 0px var(--mantine-color-gray-3);
    }
  }

  .shadow {
    box-shadow:
      -5px 0px 0px 0px var(--mantine-color-gray-3),
      -7px 0px 0px 0px var(--mantine-color-brand-8),
      -25px 0px 0px 0px var(--mantine-color-gray-3),
      -27px 0px 0px 0px var(--mantine-color-brand-8),
      -45px 0px 0px 0px var(--mantine-color-gray-3),
      -47px 0px 0px 0px var(--mantine-color-brand-8),
      -65px 0px 0px 0px var(--mantine-color-gray-3),
      -67px 0px 0px 0px var(--mantine-color-brand-8),
      -99px 0px 0px 0px var(--mantine-color-gray-3);
  }

  .line-bg {
    background-image: linear-gradient(
      to right,
      transparent 13px,
      var(--mantine-color-brand-8) 13px,
      var(--mantine-color-brand-8) 15px,
      transparent 15px
    );
    background-repeat: no-repeat;
    background-attachment: scroll;
  }
`;

export const indentClass = classes.indent;
export const lineBackgroundImageClass = classes["line-bg"];

export const Waterfall = ({
  children,
  style,
  mb = 0,
}: {
  children: ReactNode;
  style?: React.CSSProperties;
  mb?: number;
}) => {
  return (
    <div
      className={"indent " + classes.indent}
      style={{
        display: "flex",
        alignItems: "stretch",
        ...style,
        marginBottom: mb,
      }}
    >
      <div
        style={{
          position: "sticky",
          left: 0,
          zIndex: ZIndexOrder.CellGridWaterfallLine,
          width: 0,
          //background: "green",
          paddingTop: defaultHeight / 2 - dotSize / 2,
          paddingBottom: defaultHeight / 2,
        }}
      >
        <DeferredRender>
          <div
            style={{
              height: `100%`,
              width: lineSize,
              background: `
                repeating-linear-gradient(
                transparent, 
                transparent ${dotSize}px, 
                ${color} 0px, 
                ${color} ${defaultHeight}px
                )
                `,
              position: "relative",
              left: 13,
              borderRadius: 10,
            }}
          ></div>
        </DeferredRender>
      </div>
      <Stack gap={0} pos="relative">
        {children}
      </Stack>
    </div>
  );
};

Waterfall.Offset = ({ children }: { children: ReactNode }) => {
  return <div style={{ transform: "translate(-20px, 0px)" }}>{children}</div>;
};

type DotVariant = "add" | "minus" | "multiply" | "blank" | "hollow";
Waterfall.Dot = ({
  children,
  variant = "add",
  ...props
}: GroupProps & {
  children?: ReactNode;
  variant?: DotVariant;
}) => {
  const size = dotSize;
  const left = -1;

  const bgImage = {
    blank: "",
    add: tinyPlusIcon,
    multiply: tinyXIcon,
    minus: tinyMinusIcon,
    hollow: "",
  }[variant];

  const backgroundColor = {
    blank: color,
    add: "var(--mantine-color-green-9)",
    multiply: "var(--mantine-color-grape-9)",
    minus: "var(--mantine-color-red-9)",
    hollow: "var(--mantine-color-gray-3)",
  }[variant];

  const borderRadius = {
    blank: 9999,
    add: 9999,
    multiply: 0,
    minus: 2,
    hollow: 9999,
  }[variant];

  return (
    <Group gap={0} pos={"relative"} left={left} {...props}>
      <div
        style={{
          width: size,
          height: size,
          backgroundColor,
          borderRadius,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          backgroundImage: bgImage && `url(${bgImage})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          marginRight: 8,
          ...(variant === "hollow" && {
            border: "2px solid var(--mantine-color-brand-9)",
          }),
        }}
      ></div>
      {children}
    </Group>
  );
};

Waterfall.Pill = ({
  children,
  style = {},
}: {
  children: ReactNode;
  style?: CSSProperties;
}) => {
  return (
    <Group>
      <Group
        bg={color}
        gap={6}
        px={6}
        align="center"
        justify="center"
        style={{
          borderRadius: 3,
          lineHeight: 1.6,
          height: 22,
          fontWeight: 600,
          //"-webkit-font-smoothing": "antialiased",
          ...style,
        }}
        c={"white"}
        pos={"relative"}
        left={-5}
      >
        {children}
      </Group>
    </Group>
  );
};

Waterfall.Formula = ({ children }: { children: ReactNode }) => {
  return (
    <Waterfall.Pill
      style={{
        letterSpacing: 0.2,
      }}
    >
      <LuSigma style={{ marginLeft: -3 }} size={14} />
      {children}
    </Waterfall.Pill>
  );
};

Waterfall.Stub = ({ children }: { children: ReactNode }) => {
  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <Box
        className={classes.shadow}
        fz={"sm"}
        style={{
          height: defaultHeight,
          display: "flex",
          zIndex: ZIndexOrder.CellGridWaterfallTitle,
          position: "sticky",
          left: 0,
          paddingLeft: 10,
        }}
      >
        {children}
      </Box>
    </div>
  );
};

Waterfall.Collapse = ({
  children,
  title,
  tabY,
  molecule,
  offsetResult,
  dotVariant,
  cap,
}: {
  children: ReactNode;
  title: ReactNode;
  tabY: number;
  molecule: Atom<PrimitiveAtom<CellTypes>[]> | Atom<Atom<CellTypes>[]>;
  offsetResult?: boolean;
  dotVariant?: DotVariant;
  cap?: boolean;
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [open, setOpen] = useState(false);

  const ResultWrapper = offsetResult ? Waterfall.Offset : Fragment;
  const DotWrapper = !dotVariant
    ? Fragment
    : ({ children }: { children: ReactNode }) => (
        <Waterfall.Dot variant={dotVariant || "blank"}>
          {children}
        </Waterfall.Dot>
      );

  return (
    <>
      {cap && open && (
        <Waterfall.Stub>
          <Waterfall.Dot variant={"blank"}> </Waterfall.Dot>
        </Waterfall.Stub>
      )}

      <Collapse in={open}>
        <Waterfall>
          {open && children}

          <ResultWrapper>
            <CellRow
              style={{ position: "relative", left: -23 }}
              tabY={tabY}
              molecule={molecule}
              label={
                <Group gap={4}>
                  <Waterfall.Dot variant={dotVariant || "blank"}>
                    <Waterfall.Formula>{title}</Waterfall.Formula>
                  </Waterfall.Dot>
                  <Tooltip label="Collapse Calculation">
                    <ActionIcon
                      variant="light"
                      size={"sm"}
                      color="brand.8"
                      onClick={() => setOpen((o) => !o)}
                    >
                      <MdClose />
                    </ActionIcon>
                  </Tooltip>
                </Group>
              }
            />
          </ResultWrapper>
        </Waterfall>
      </Collapse>

      <Collapse in={!open}>
        <CellRow
          tabY={tabY}
          molecule={molecule}
          label={
            <Group gap={4}>
              <DotWrapper>
                <Waterfall.Formula>{title}</Waterfall.Formula>
              </DotWrapper>
              <Tooltip label="Expand Calculation">
                <ActionIcon
                  variant="light"
                  size={"sm"}
                  color="accent.4"
                  onClick={() => setOpen((o) => !o)}
                >
                  <AiOutlineFunction />
                </ActionIcon>
              </Tooltip>
            </Group>
          }
        />
      </Collapse>
    </>
  );
};
