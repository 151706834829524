import { router } from "@/main";
import { Route } from "@/paths";
import { Group } from "@mantine/core";
import { Provider } from "jotai";
import { Outlet, matchPath } from "react-router-dom";
import { DeferredRender } from "../DeferedRender/DeferedRender";
import { AdminWarning } from "./AdminWarning";
import { Loader } from "./Loader";
import { NotAvailableOnMobile } from "./NotAvailableOnMobile";
import { Sidebar } from "./Sidebar/Sidebar";

export const PageModel = () => {
  const pathParams = matchPath(
    { path: Route.ModelTab, end: false },
    location.pathname,
  )?.params;

  if (!pathParams) router.navigate("dashboard");

  return (
    <Provider>
      <Group
        h="100dvh"
        w="100dvw"
        bg={"gray.3"}
        flex={1}
        gap={0}
        justify="left"
        align="center"
        style={{ overflow: "hidden" }}
      >
        <Sidebar visibleFrom="md" />
        <DeferredRender>
          <Outlet />
        </DeferredRender>
      </Group>
      <Loader />
      <DeferredRender>
        <AdminWarning />
        <NotAvailableOnMobile />
      </DeferredRender>
    </Provider>
  );
};
