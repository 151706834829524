import { auth, firestore } from "@/firebase";
import { Route } from "@/paths";
import {
  Timestamp,
  addDoc,
  collection,
  doc,
  getDoc,
  query,
  serverTimestamp,
  setDoc,
  where,
} from "firebase/firestore";
import { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useCollection } from "react-firebase-hooks/firestore";
import { generatePath, useNavigate } from "react-router-dom";

export const collectionRef = collection(firestore, "model");

export type Model = {
  id: string;
  name: string;
  creatorId: string | "unknown";
  createdAt: Timestamp;
  status: "trashed" | "published";
};

export const create = async (model?: Partial<Model>) => {
  return await addDoc(collectionRef, {
    createdAt: serverTimestamp() as Timestamp,
    creatorId: auth?.currentUser?.uid || "unknown",
    name: "New Model",
    status: "published",
    ...model,
  } satisfies Omit<Model, "id">);
};

export const remove = async (id: string) => {
  return await setDoc(
    doc(collectionRef, id),
    {
      status: "trashed",
    } as Partial<Model>,
    { merge: true },
  );
};

export const clone = async (id: string) => {
  const modelSnap = await getDoc(doc(collectionRef, id));
  const latestRevisionSnap = await getDoc(
    doc(collectionRef, id, "revision", "latest"),
  );

  if (!modelSnap.exists()) throw new Error("Model does not exist");

  const snapshot = await addDoc(collectionRef, {
    ...modelSnap.data(),
    name: modelSnap.data().name + " (copy)",
    creatorId: auth?.currentUser?.uid,
    createdAt: serverTimestamp(),
    status: "published",
  });

  await setDoc(
    doc(collectionRef, snapshot.id, "revision", "latest"),
    latestRevisionSnap.data(),
  );

  return snapshot;
};

export const useModels = () => {
  const [user] = useAuthState(auth);
  const modelQuery = query(
    collectionRef,
    //orderBy("createdAt", "desc"),
    where("creatorId", "==", user?.uid || ""),
    where("status", "==", "published"),
  );
  const [models, loading, error] = useCollection(modelQuery);

  return [
    (models?.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    })) || []) as Model[],
    loading,
    error,
  ] as const;
};

export const useCreateModel = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const createModel = async (model?: Partial<Model>) => {
    setLoading(true);
    try {
      const snapshot = await create(model);
      navigate(
        generatePath(Route.ModelTab, {
          modelId: snapshot.id,
          modelTabId: "dashboard",
        }),
      );
    } catch (error: unknown) {
      setError(error as Error);
    }
    setLoading(false);
  };

  return [createModel, loading, error] as const;
};

export const useRemoveModel = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const removeModel = async (id: string) => {
    setLoading(true);
    try {
      await remove(id);
    } catch (error: unknown) {
      setError(error as Error);
    }
    setLoading(false);
  };

  return [removeModel, loading, error] as const;
};

export const useCloneModel = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const cloneModel = async (id: string) => {
    setLoading(true);
    try {
      return await clone(id);
    } catch (error: unknown) {
      setError(error as Error);
    }
    setLoading(false);
  };

  return [cloneModel, loading, error] as const;
};
