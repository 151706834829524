import {
  Switch as MantineSwitch,
  Stack,
  SwitchProps,
  Text,
} from "@mantine/core";
import { SidebarInputProps } from "./types";

export const InputToggle = ({
  labelWidth = 228,
  label,
  ...props
}: SidebarInputProps & SwitchProps) => {
  return (
    <Stack h={36} justify="center">
      <MantineSwitch
        labelPosition="left"
        size="md"
        label={
          <Text
            style={{
              fontWeight: 500,
              fontSize: 14,
            }}
            size={"md"}
            w={labelWidth}
          >
            {label}
          </Text>
        }
        {...props}
      />
    </Stack>
  );
};
