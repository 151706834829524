import { ZIndexOrder } from "@/theme";
import { BoxComponentProps, ScrollArea, Stack } from "@mantine/core";
import { ReactNode } from "react";

export const Grid = ({
  children,
  ...props
}: { children: ReactNode } & BoxComponentProps) => {
  return (
    <ScrollArea
      offsetScrollbars
      {...props}
      styles={{
        viewport: {
          scrollPaddingLeft: 500,
          scrollPaddingRight: 300,
          scrollPaddingTop: 150,
          scrollPaddingBottom: 150,
        },
        scrollbar: {
          zIndex: ZIndexOrder.CellGridScrollbars,
        },
      }}
    >
      <Stack gap={0} align="start">
        {children}
      </Stack>
    </ScrollArea>
  );
};
