import { Box, Container, Group, Stack } from "@mantine/core";
import { css } from "vite-plugin-inline-css-modules";

export const defaultHeaderHeight = 42;

const styleClasses = css`
  .panel {
    box-shadow:
      rgba(9, 30, 66, 0.2) 0px 1px 1px,
      rgba(9, 30, 66, 0.1) 0px 0px 1px 1px;
    background: white;
    border-radius: 0px;
    border-bottom: 2px solid #eee;
  }
`;

export const panelClass = styleClasses.panel;

export const LayoutWorkspace = ({
  headerHeight = defaultHeaderHeight,
  children,
  header,
  containerSize = "fluid",
}: {
  headerHeight?: number;
  children: React.ReactNode;
  header: React.ReactNode;
  containerSize?: "fluid" | "sm" | "md" | "lg" | "xl";
}) => {
  return (
    <Stack h={"100%"} flex={1} gap={0} miw={0}>
      <Box
        h={headerHeight}
        mih={headerHeight}
        bg="#F1F5F9"
        style={{
          borderBottom: "1px solid #ddd",
        }}
      >
        <Container
          fluid={containerSize === "fluid"}
          size={containerSize !== "fluid" ? containerSize : undefined}
        >
          <Group
            justify="space-between"
            align="center"
            h={headerHeight}
            mih={headerHeight}
          >
            {header}
          </Group>
        </Container>
      </Box>
      <Box
        flex={1}
        h={"100%"}
        mih={0}
        pos={"relative"}
        bg="transparent"
        p={"sm"}
      >
        {children}
      </Box>
    </Stack>
  );
};
