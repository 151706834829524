import { doc, setDoc } from "firebase/firestore";
import { useState } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import { useParams } from "react-router-dom";
import { Model, collectionRef } from "./useModels";

export const useModelRef = () => {
  const params = useParams();
  const modelId = params.modelId;
  return doc(collectionRef, modelId);
};

export const useModel = () => {
  const modelRef = useModelRef();

  const [model, loading, error] = useDocument(modelRef);

  return [
    !model
      ? undefined
      : ({
          ...model?.data(),
          id: model?.id,
        } as Model),
    loading,
    error,
  ] as const;
};

export const useUpdate = () => {
  const ref = useModelRef();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const update = async (model: Partial<Model>) => {
    setLoading(true);
    setError(null);
    try {
      await setDoc(ref, model, { merge: true });
    } catch (error: unknown) {
      setError(error as Error);
    }
    setLoading(false);
  };

  return [update, loading, error] as const;
};
