import {
  Anchor,
  Divider,
  Drawer,
  Group,
  Title,
  TypographyStylesProvider,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { ReactNode } from "react";

import { IoSchool } from "react-icons/io5";
import { RiQuestionLine } from "react-icons/ri";
import classes from "./LabelExplainer.module.css";

export const LabelExplainer = ({
  label,
  explanation,
  preventDefault,
}: {
  explanation: ReactNode | string;
  label: ReactNode | string;
  preventDefault?: boolean;
}) => {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <Drawer
        opened={opened}
        onClose={close}
        title={
          <Group justify="center" align="center">
            <IoSchool size={30} />
            <Title order={3} pt={4}>
              Learning Center
            </Title>
          </Group>
        }
        offset={8}
      >
        <Divider mb={"md"} />
        <TypographyStylesProvider p={0} classNames={classes}>
          {explanation}
        </TypographyStylesProvider>
      </Drawer>
      <Anchor
        underline="always"
        inherit
        onClick={(e) => {
          if (preventDefault) e.preventDefault();
          open();
        }}
        style={{
          textDecorationStyle: "dotted",
          verticalAlign: "top",
          textDecorationThickness: 1,
          textDecorationColor: "var(--mantine-color-accent-5)",
        }}
      >
        {label} <RiQuestionLine />
      </Anchor>
    </>
  );
};
