import TooltipFinanceStructrue from "@/docs/tooltips/finance-structrue.mdx";
import {
  equityAmountAtom,
  equityPercentAtom,
  sbaLoanAmountAtom,
  sbaLoanPercentAtom,
  sellersNoteAmountAtom,
  sellersNotePercentAtom,
} from "@/financeModels/liteAtoms";
import { formatDollar } from "@/utils/format";
import { isBetween, toNumberOrNull } from "@/utils/math";
import {
  ComboboxData,
  NumberFormatter,
  Select,
  Table,
  Text,
} from "@mantine/core";
import { useAtom } from "jotai";
import { useState } from "react";
import { RiPieChartLine } from "react-icons/ri";
import { LabelExplainer } from "./LabelExplainer";
import { QuestionnaireSection } from "./QuestionnaireSection";
import classes from "./SegmentFinanceStructure.module.css";

export const SegmentFinanceStructure = () => {
  const [isDirty, setIsDirty] = useState(false);

  const [sbaLoanPercent, setSbaLoanPercent] = useAtom(sbaLoanPercentAtom);
  const [sbaLoanAmount] = useAtom(sbaLoanAmountAtom);
  const [equityAmount] = useAtom(equityAmountAtom);
  const [equityPercent, setEquityPercent] = useAtom(equityPercentAtom);
  const [sellersNoteAmount] = useAtom(sellersNoteAmountAtom);
  const [sellersNotePercent, setSellersNotePercent] = useAtom(
    sellersNotePercentAtom,
  );

  const amountTotal =
    (toNumberOrNull(sbaLoanAmount) ?? 0) +
    (toNumberOrNull(sellersNoteAmount) ?? 0) +
    (toNumberOrNull(equityAmount) ?? 0);

  const percentTotal =
    (toNumberOrNull(sbaLoanPercent) ?? 0) +
    (toNumberOrNull(sellersNotePercent) ?? 0) +
    (toNumberOrNull(equityPercent) ?? 0);

  const percents = [...Array(90 / 5 + 1).keys()].map((x) => ({
    value: `${Number((x * 0.05).toFixed(2)).toString()}`,
    label: `${(x * 0.05 * 100).toFixed(2).replace(".00", "")}%`,
  }));

  let error: string | undefined = undefined;
  if (percentTotal !== 1) error = `Percent total must equal 100%.`;
  if (isBetween(sbaLoanAmount, 5000000, 50000000))
    error = "The maximum allowable amount for an SBA 7(a) loan is $5 million.";

  return (
    <QuestionnaireSection
      icon={RiPieChartLine}
      label={
        <LabelExplainer
          label="Finance Structure"
          explanation={<TooltipFinanceStructrue />}
        />
      }
      error={error}
    >
      <Table withRowBorders={false} className={classes.table}>
        <Table.Thead>
          <Table.Tr>
            <Table.Th></Table.Th>
            <Table.Th px={16}>
              <Text
                style={{
                  display: "inline-block",
                  borderBottom: "1px solid var(--mantine-color-default-border)",
                }}
                size="lg"
                fw={600}
              >
                Percent (%)
              </Text>
            </Table.Th>
            <Table.Th px={16}>
              <Text
                style={{
                  display: "inline-block",
                  borderBottom: "1px solid var(--mantine-color-default-border)",
                }}
                size="lg"
                fw={600}
              >
                Amount ($)
              </Text>
            </Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          <Row
            label="SBA 7(a) Loan"
            value={toNumberOrNull(sbaLoanPercent) ?? 0}
            onChange={(value) => {
              const sbaLoanPercent = round(toNumberOrNull(value) ?? 0);
              const cleanSellerNotePercent = round((1 - sbaLoanPercent) / 2);
              const cleanEquityPercent = round(
                1 - sbaLoanPercent - cleanSellerNotePercent,
              );

              if (isDirty) return setSbaLoanPercent(sbaLoanPercent);

              setSbaLoanPercent(sbaLoanPercent);
              setSellersNotePercent(cleanSellerNotePercent);
              setEquityPercent(cleanEquityPercent);
            }}
            data={[...percents].reverse()}
            amount={toNumberOrNull(sbaLoanAmount) ?? 0}
          />
          <Row
            label="Seller's Note"
            value={toNumberOrNull(sellersNotePercent) ?? 0}
            onChange={(value) => {
              setSellersNotePercent(toNumberOrNull(value));
              setIsDirty(true);
            }}
            data={percents}
            amount={toNumberOrNull(sellersNoteAmount) ?? 0}
          />
          <Row
            label="Equity"
            value={toNumberOrNull(equityPercent) ?? 0}
            onChange={(value) => {
              setEquityPercent(toNumberOrNull(value));
              setIsDirty(true);
            }}
            data={percents}
            amount={toNumberOrNull(equityAmount) ?? 0}
            mb="md"
          />
          <Table.Tr style={{ borderTop: "1px solid #eee" }} visibleFrom="sm">
            <Table.Td>
              <Text mt={"xs"} fw={500} fz={"lg"}>
                Total
              </Text>
            </Table.Td>
            <Table.Td>
              <Text mt={"xs"} fz={"lg"} pl={16}>
                {percentTotal > 0 && (
                  <NumberFormatter
                    thousandSeparator
                    decimalScale={0}
                    suffix="%"
                    value={percentTotal * 100}
                  />
                )}
              </Text>
            </Table.Td>
            <Table.Td>
              <Text mt={"xs"} fz={"lg"} ta="left">
                {amountTotal > 0 && formatDollar(amountTotal)}
              </Text>
            </Table.Td>
          </Table.Tr>
        </Table.Tbody>
      </Table>
    </QuestionnaireSection>
  );
};

const Row = ({
  data,
  amount,
  label,
  value,
  onChange,
  mb,
}: {
  data: ComboboxData;
  amount: number;
  label: string;
  value: number;
  onChange: (value: number) => void;
  mb?: string;
}) => {
  return (
    <Table.Tr>
      <Table.Td pb={mb}>
        <Text fw={500} fz={"lg"}>
          {label}
        </Text>
      </Table.Td>
      <Table.Td pb={mb}>
        <Select
          withCheckIcon={false}
          allowDeselect={false}
          w={104}
          size="lg"
          data={data}
          value={value?.toString()}
          onChange={(value) => {
            onChange(Number(value));
          }}
        />
      </Table.Td>
      <Table.Td pb={mb}>
        <Text ta={"left"} fz={"lg"}>
          {amount > 0 && formatDollar(amount)}
        </Text>
      </Table.Td>
    </Table.Tr>
  );
};

const round = (num: number) => Math.round(num * 20) / 20;
