import { hasFinishedLoadingModelOnMountAtom } from "@/financeModels/owasco/save";
import { auth } from "@/firebase";
import { useModel } from "@/hooks/useModel";
import { useCloneModel } from "@/hooks/useModels";
import { Route } from "@/paths";
import { Button, Modal, Stack, Text } from "@mantine/core";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { FaBookReader } from "react-icons/fa";
import { generatePath, useNavigate } from "react-router-dom";

export const AdminWarning = () => {
  const [user] = useAuthState(auth);
  const [model] = useModel();
  const hasLoadedModel = useAtomValue(hasFinishedLoadingModelOnMountAtom);
  const [isVisible, setIsVisible] = useState(false);
  const [clone] = useCloneModel();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) return;
    if (!model) return;
    if (!hasLoadedModel) return;
    if (model.creatorId === user.uid) return;

    const forceRefresh = window.location.search.includes("refreshClaims");
    user.getIdTokenResult(forceRefresh).then((idTokenResult) => {
      if (idTokenResult.claims.role !== "admin") return;
      setIsVisible(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasLoadedModel]);

  return (
    <Modal
      opened={isVisible}
      title="Read Only Mode"
      onClose={() => setIsVisible(false)}
      bg={"red"}
    >
      <Stack justify="center" align="center">
        <FaBookReader size={160} color="var(--mantine-color-blue-6)" />
        <Text ta={"center"}>
          As an admin, you can view the model but not make changes.
        </Text>
        <Button w="100%" color="blue" onClick={() => setIsVisible(false)}>
          Understood
        </Button>
        <Button
          w="100%"
          color="yellow.8"
          onClick={async () => {
            const m = await clone(model!.id);

            navigate(
              generatePath(Route.Model, {
                modelId: m!.id,
              }),
            );
            window.location.reload();
          }}
        >
          Clone
        </Button>
      </Stack>
    </Modal>
  );
};
