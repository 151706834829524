import { cloneFromEpochsAtom } from "@/financeModels/owasco/clone";
import {
  dscrMolecule,
  yearsFromSbaLoanStartDateAtom,
} from "@/financeModels/owasco/projections";
import { ZIndexOrder } from "@/theme";
import {
  ActionIcon,
  Box,
  BoxComponentProps,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import { useAtomValue, useSetAtom } from "jotai";
import { RiLineChartFill } from "react-icons/ri";
import { TbTableImport } from "react-icons/tb";
import { Column } from "../CellGrid/Column";
import { DSCRCell } from "../CellGrid/DSCRCell";
import { defaultGrowthCellWidth } from "../CellGrid/GrowthCell";
import { defaultLabelColumnWidth } from "../CellGrid/LabelColumn";
import { Row } from "../CellGrid/Row";
import { defaultHeight } from "../CellGrid/cell/Wrapper";

export const IterationRow = ({ ...props }: BoxComponentProps) => {
  const years = useAtomValue(yearsFromSbaLoanStartDateAtom);
  const dscrAtoms = useAtomValue(dscrMolecule);
  const clone = useSetAtom(cloneFromEpochsAtom);
  const openModal = () =>
    modals.openConfirmModal({
      title: "Import Historical Data",
      children: (
        <Text size="sm">
          Importing data from your lookback table will overwrite parts of the
          projections table. This cannot be undone. Proceed?
        </Text>
      ),
      labels: { confirm: "Overwrite Projection Table", cancel: "Cancel" },
      onConfirm: clone,
    });

  return (
    <Row
      alwaysRender
      {...props}
      style={{
        position: "sticky",
        top: 0,
        zIndex: ZIndexOrder.CellGridIterationRow,
        borderBottom: "1px solid #ddd",
      }}
      bg={"gray.3"}
    >
      <div
        style={{
          width: defaultLabelColumnWidth,
        }}
      >
        <Tooltip position="right" label={"Import Historical Financials"}>
          <ActionIcon variant="transparent" color="gray.9" onClick={openModal}>
            <TbTableImport height={"100%"} />
          </ActionIcon>
        </Tooltip>
      </div>
      {dscrAtoms.map((atom, i) =>
        i === 1 ? (
          <Column
            key={`${i}`}
            justify="center"
            align={"center"}
            w={defaultGrowthCellWidth}
            px={2}
            style={{
              borderRight: "4px solid #DEE2E6",
            }}
          >
            <Stack
              justify="center"
              align={"center"}
              w="100%"
              bg={"white"}
              h={defaultHeight}
              style={{
                borderTopRightRadius: 8,
              }}
            >
              <Tooltip label="Growth Rate" position="bottom">
                <Box w={24} h={24}>
                  <RiLineChartFill size={26} />
                </Box>
              </Tooltip>
            </Stack>
          </Column>
        ) : (
          <Column key={`${i}`}>
            <DSCRCell dscrAtom={atom} {...(i === 0 ? { bg: "white" } : {})}>
              <Stack gap={1}>
                <Text lh={1} h={14} fz={"md"} fw={500}>
                  {i < 1 ? "Base Year" : years[i - 2]}
                </Text>
                <Text
                  fz={"xs"}
                  style={{
                    fontSize: 9,
                  }}
                  h={10}
                  lh={1}
                >
                  Year {i < 1 ? i : i - 1}
                </Text>
              </Stack>
            </DSCRCell>
          </Column>
        ),
      )}
    </Row>
  );
};
