import { Box, SimpleGrid } from "@mantine/core";
import { Sources } from "./Sources";
import { Uses } from "./Uses";

export const SourcesAndUses = () => {
  return (
    <SimpleGrid cols={2}>
      <Box>
        <Sources />
      </Box>
      <Box>
        <Uses />
      </Box>
    </SimpleGrid>
  );
};
