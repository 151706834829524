import { ReactNode, useEffect, useState } from "react";

interface DeferredRenderProps {
  children: ReactNode;
  idleTimeout?: number;
}

export const DeferredRender = ({
  children,
  idleTimeout = 100,
}: DeferredRenderProps) => {
  const [render, setRender] = useState<boolean>(false);

  useEffect(() => {
    if (render) setRender(false);
    const id = window.requestIdleCallback(() => setRender(true), {
      timeout: idleTimeout,
    });

    return () => window.cancelIdleCallback(id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idleTimeout]);

  if (!render) return null;

  return <>{children}</>;
};

window.requestIdleCallback =
  window.requestIdleCallback ||
  function (cb) {
    const start = Date.now();
    return setTimeout(function () {
      cb({
        didTimeout: false,
        timeRemaining: function () {
          return Math.max(0, 50 - (Date.now() - start));
        },
      });
    }, 1);
  };

window.cancelIdleCallback =
  window.cancelIdleCallback ||
  function (id) {
    clearTimeout(id);
  };
