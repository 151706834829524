import { Logo } from "@/components/Logo/Logo";
import { useAuthContext } from "@/contexts/AuthProvider";
import { Route } from "@/paths";
import { Box, Button, Container, Group, Text } from "@mantine/core";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";

export const Header = () => {
  const { user, loading } = useAuthContext();

  return (
    <Box bg={"brand.8"} py={"xs"}>
      <Container size={"xl"} component={"header"} h={"100%"}>
        <Group justify="space-between" h="100%" px={0}>
          <Group>
            <Logo height={18} py={"sm"} />
            <Text
              size="xs"
              px={3}
              bg="white"
              c="accent"
              ff="mono"
              pos="relative"
              top={-10}
              left={-5}
            >
              BETA
            </Text>
          </Group>

          <Group>
            {loading === false && !user && (
              <>
                <Button variant="light" component={Link} to={Route.Login}>
                  Log in
                </Button>
                <Button component={Link} to={Route.Signup}>
                  Sign Up
                </Button>
              </>
            )}
            {user && (
              <Button
                component={Link}
                to={Route.Models}
                rightSection={<FaArrowRight />}
                variant="outline"
              >
                Go to ETA IQ
              </Button>
            )}
          </Group>
        </Group>
      </Container>
    </Box>
  );
};
