// Import the functions you need from the SDKs you need
//import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getPerformance } from "firebase/performance";

let firebaseConfig = {
  apiKey: "AIzaSyAQuC_-HLJkxXUkZh5aYuwHfFy1BfO71i8",
  authDomain: "eta-iq-staging.firebaseapp.com",
  projectId: "eta-iq-staging",
  storageBucket: "eta-iq-staging.appspot.com",
  messagingSenderId: "442011714047",
  appId: "1:442011714047:web:ffbd68cad9649a7e4367ca",
  measurementId: "", // Not used in staging
};

if (import.meta.env.PROD && !window.location.origin.includes(".web.app")) {
  firebaseConfig = {
    apiKey: "AIzaSyCPJSIMpV1aJqnActJRoNRId1YW6KbY4qk",
    authDomain: "eta-iq-web.firebaseapp.com",
    projectId: "eta-iq-web",
    storageBucket: "eta-iq-web.appspot.com",
    messagingSenderId: "517531746097",
    appId: "1:517531746097:web:30ce8e33b63cdde2f57a10",
    measurementId: "G-4DBKH9TP04",
  };
}

// Initialize Firebase and export the necessary services
export const app = initializeApp(firebaseConfig);
//export const analytics = getAnalytics(app);
export const firestore = getFirestore(app);
export const auth = getAuth(app);
export const perf = getPerformance(app);

// Utils
export const FIREBASE_ERRORS = {
  "Firebase: Error (auth/email-already-in-use).":
    "A user with that email already exists",
  "Firebase: Error (auth/user-not-found).": "Invalid email or password.",
  "Firebase: Error (auth/wrong-password).": "Invalid email or password.",
  "Firebase: Error (auth/invalid-email).": "Invalid email or password.",
  "Firebase: Password should be at least 6 characters (auth/weak-password).":
    "Password should be at least 6 characters ",
} as const;

type FirebaseError = keyof typeof FIREBASE_ERRORS;

export const prettyPrintFirebaseErrorMessage = (error: string) => {
  const messagePart = error.split("/")[1];
  const normalCase = messagePart
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")
    .replace(")", "");

  return FIREBASE_ERRORS[error as FirebaseError] || normalCase;
};
