import { atom } from "jotai";
import { atomEffect } from "jotai-effect";
import { modelAtom } from "./model";
import { hasFinishedLoadingModelOnMountAtom } from "./save";
import { PersistableModel } from "./types";

export const undoRedoEffect = atomEffect((get, set) => {
  get(modelUndoRedoAtomEffect);
  const fn = (e: KeyboardEvent) => {
    if (e.ctrlKey && e.key === "z") set(undoLastModelActionAtom);
    if (e.ctrlKey && e.key === "y") set(redoLastModelActionAtom);
  };

  window.addEventListener("keydown", fn);
  return () => window.removeEventListener("keydown", fn);
});

export const modelUndoHistoryAtom = atom<PersistableModel[]>([]);
export const modelRedoHistoryAtom = atom<PersistableModel[]>([]);
let blockModelRedoReset = false;

export const modelUndoRedoAtomEffect = atomEffect((get, set) => {
  const model = get(modelAtom);

  if (!blockModelRedoReset) {
    set(modelRedoHistoryAtom, []);
  }

  if (!get(hasFinishedLoadingModelOnMountAtom)) return;

  set(modelUndoHistoryAtom, (prev) => [model, ...prev].slice(0, 15));
});

export const undoLastModelActionAtom = atom<null, [], void>(
  null,
  (get, set) => {
    const [currentModel, previousModel, ...rest] = get(modelUndoHistoryAtom);

    if (!previousModel) return;

    blockModelRedoReset = true;
    set(modelUndoHistoryAtom, rest);
    set(modelAtom, previousModel);
    set(modelRedoHistoryAtom, (prev) => [currentModel, ...prev]);
    // set is async so we need to wait for it to finish before we can set blockModelRedoReset to false
    setTimeout(() => {
      blockModelRedoReset = false;
    }, 3);
  },
);

export const redoLastModelActionAtom = atom<null, [], void>(
  null,
  (get, set) => {
    const [model, ...rest] = get(modelRedoHistoryAtom);

    if (!model) return;

    blockModelRedoReset = true;
    set(modelAtom, model);
    set(modelRedoHistoryAtom, rest);
    // set is async so we need to wait for it to finish before we can set blockModelRedoReset to false
    setTimeout(() => {
      blockModelRedoReset = false;
    }, 3);
  },
);
