import { PercentCell, PercentCellProps } from "./PercentCell";

export const defaultGrowthCellWidth = 60;

export const GrowthCell = (props: PercentCellProps) => {
  return (
    <PercentCell
      ta={"center"}
      justifyContent={"center"}
      style={{
        borderRight: "6px solid #DEE2E6",
        textAlign: "center",
        width: defaultGrowthCellWidth,
      }}
      {...props}
    />
  );
};
