import {
  DatePickerInput,
  DatePickerInputProps,
  DateValue,
  DatesRangeValue,
} from "@mantine/dates";
import { SidebarInputProps } from "./types";

export const InputDate = ({
  labelWidth = 181,
  onChange,
  ...props
}: SidebarInputProps &
  DatePickerInputProps & {
    onChange: (value: DateValue | DatesRangeValue | Date[]) => void;
  }) => {
  return (
    <DatePickerInput
      onChange={onChange}
      size="sm"
      styles={{
        root: { display: "flex", alignItems: "center" },
        label: { width: labelWidth },
        wrapper: { flex: 1 },
      }}
      {...props}
    />
  );
};
