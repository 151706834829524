import {
  buyerAnnualCapExAtom,
  buyerDeprAndAmortScheduleAtom,
  companysTaxRateAtom,
} from "@/financeModels/owasco/general";
import { numberToPercentInput, percentInputToNumber } from "@/utils/format";
import { toNumberOrNull } from "@/utils/math";
import { Stack } from "@mantine/core";
import { useAtom } from "jotai";
import { TbFileInvoice } from "react-icons/tb";
import { InputDollar } from "./InputDollar";
import { InputPercent } from "./InputPercent";
import { InputYears } from "./InputYears";
import { LabelTooltip } from "./LabelTooltip";

const Panel = () => {
  const [taxRate, setTaxRate] = useAtom(companysTaxRateAtom);
  const [deprAmortYears, setDeprAmortYears] = useAtom(
    buyerDeprAndAmortScheduleAtom,
  );
  const [capEx, setCapEx] = useAtom(buyerAnnualCapExAtom);

  return (
    <Stack gap={4}>
      <InputPercent
        label={"Company’s Tax Rate"}
        value={numberToPercentInput(taxRate)}
        onChange={(value) => setTaxRate(percentInputToNumber(value))}
      />
      <InputYears
        label={"Goodwill Amortization"}
        value={toNumberOrNull(deprAmortYears) || ""}
        onChange={(value) => setDeprAmortYears(toNumberOrNull(value))}
      />
      <InputDollar
        label={
          <LabelTooltip label="Annual Maintence CapEx">
            Annual CapEx
          </LabelTooltip>
        }
        value={toNumberOrNull(capEx) || ""}
        onChange={(value) => setCapEx(toNumberOrNull(value))}
      />
    </Stack>
  );
};

export const ModuleOperationalAssumptions = {
  key: "Operational Assumptions",
  icon: <TbFileInvoice />,
  control: "Operational Assumptions",
  panel: <Panel />,
};
