import { BoxComponentProps } from "@mantine/core";
import { ReactNode, useEffect, useState } from "react";
import { selectActiveElement } from "../hooks/useTabCoordinates";
import classes from "./Cell.module.css";

const arrowKeys = ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"];

export const Stub = ({
  enableEditing,
  label,
  moveFocus,
  resetCell,
  ta,
  locked,
  overrided,
  animationKey,
  bg = "white",
  blank,
  startEditRegex = /\d/,
  justifyContent = "right",
}: BoxComponentProps & {
  label: ReactNode;
  resetCell?: () => void;
  enableEditing: (value?: unknown) => void;
  moveFocus: (dir: "up" | "down" | "left" | "right") => void;
  ta?: "left" | "right" | "center";
  locked?: boolean;
  overrided?: boolean;
  blank?: boolean;
  animationKey?: string;
  startEditRegex?: RegExp;
  justifyContent?: "left" | "right" | "center";
}) => {
  const [animationKeyCount, setAnimationKeyCount] = useState<number>(-3);

  if (overrided)
    bg = `var(--mantine-color-blue-0) 2px 80% / 16px 16px no-repeat url('data:image/svg+xml,<svg stroke="%2342A5F5" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="200px" width="200px" xmlns="http://www.w3.org/2000/svg"><path d="M4 20h4l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4"></path><path d="M13.5 6.5l4 4"></path><path d="M16 19h6"></path><path d="M19 16v6"></path></svg>')`;
  if (locked) bg = "var(--mantine-color-styledBackgrounds-6)";
  if (blank) bg = "#EBEBEE";

  useEffect(() => {
    if (animationKey === undefined) return;
    setAnimationKeyCount((c) => (c >= 1 ? 0 : c + 1));
  }, [animationKey, setAnimationKeyCount]);

  return (
    <div
      tabIndex={0}
      style={{
        //...props.style,
        height: "100%",
        width: "100%",
        position: "relative",
        userSelect: "none",
        overflow: "hidden",
        textAlign: ta,
        display: "flex",
        justifyContent,
        alignItems: "center",
        background: bg as string,
        padding: "0 8px",
        //...(blank ? { boxShadow: "inset 0 0 0 1px #DEE2E6" } : {}),
      }}
      className={[
        "stub-cell",
        `flash-${animationKeyCount}`,
        classes.cell,
        locked ? classes.locked : "",
      ].join(" ")}
      onDoubleClick={() => {
        enableEditing();
        selectActiveElement();
      }}
      onKeyDown={(e) => {
        if (arrowKeys.includes(e.key)) {
          e.preventDefault();
          moveFocus(
            e.key.replace("Arrow", "").toLowerCase() as
              | "up"
              | "down"
              | "left"
              | "right",
          );
          return;
        }

        if (locked) return;

        if (e.key === "Enter") {
          enableEditing();
          selectActiveElement();
        }

        if (e.key.match(startEditRegex)) {
          enableEditing();
        }

        if (e.key === "Delete") {
          resetCell?.();
        }

        if (e.key === "Backspace") {
          enableEditing(null);
        }
      }}
    >
      {label}
    </div>
  );
};
