import { Stack, StackProps } from "@mantine/core";
import { ReactNode } from "react";

export const defaultWidth = 168;

export const Column = ({
  children,
  w = defaultWidth,
  ...props
}: { children: ReactNode } & StackProps) => {
  return (
    <Stack gap={0} w={w} justify="center" {...props}>
      {children}
    </Stack>
  );
};
