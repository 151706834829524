import { BigDollarInput } from "@/components/BigDollarInput/BigDollarInput";
import { MathResult } from "@/financeModels/types";
import { toNumberOrNull } from "@/utils/math";
import { Button, Menu, Stack, Text } from "@mantine/core";
import { PrimitiveAtom, useAtom } from "jotai";
import { useEffect, useRef, useState } from "react";
import { getFocusableElement } from "../CellGrid/hooks/useTabCoordinates";

export const ExtraPaymentsMenu = ({
  children,
  isActive,
  onToggle,
  onComplete,
  recurringPaymentAtom,
}: {
  children?: React.ReactNode;
  isActive: boolean;
  recurringPaymentAtom: PrimitiveAtom<MathResult>;
  onComplete: () => void;
  onToggle: () => void;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [recurringPayment, setRecurringPaymentAtom] =
    useAtom(recurringPaymentAtom);
  const [inputValue, setInputValue] = useState(0);

  const onSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setRecurringPaymentAtom(inputValue);
    onComplete();
  };

  useEffect(() => {
    setInputValue(Number(recurringPayment));

    if (!isActive) return;
    setTimeout(() => {
      ref.current &&
        (getFocusableElement(ref.current) as HTMLInputElement).select();
    }, 30);
  }, [isActive, recurringPayment]);

  return (
    <Menu
      width={250}
      shadow="md"
      withArrow
      position="right"
      opened={isActive}
      keepMounted={false}
      arrowSize={16}
      onChange={onToggle}
    >
      <Menu.Target>
        <Stack h={"100%"} justify="center" mr={"auto"} px={"sm"}>
          {children}
        </Stack>
      </Menu.Target>

      <Menu.Dropdown>
        <Stack p="xs">
          <form onSubmit={onSubmit}>
            <Stack ref={ref}>
              <Text>Add Recurring Extra Payment</Text>
              <BigDollarInput
                autoFocus
                value={toNumberOrNull(inputValue) ?? 0}
                onChange={(value) => setInputValue(toNumberOrNull(value) || 0)}
              />
              <Button type="submit">Update</Button>
            </Stack>
          </form>
        </Stack>
      </Menu.Dropdown>
    </Menu>
  );
};
