import { defaultHeaderHeight } from "@/components/LayoutWorkspace/LayoutWorkspace";
import { Logo } from "@/components/Logo/Logo";
import { confirmOnCloseAtom } from "@/financeModels/owasco/confirmOnClose";
import { useModel, useUpdate } from "@/hooks/useModel";
import { Route } from "@/paths";
import {
  Group,
  ScrollArea,
  Stack,
  StackProps,
  Text,
  TextInput,
  TextInputProps,
} from "@mantine/core";
import { useAtomValue } from "jotai";
import { memo } from "react";
import { BsCloudCheckFill } from "react-icons/bs";
import { MdKeyboardArrowRight } from "react-icons/md";
import { css } from "vite-plugin-inline-css-modules";
import { Accordion } from "./Accordian";
import { CollapseAllButton } from "./CollapseAllButton";

const borderOnFocusClasses = css`
  .borderOnFocus {
    input:focus {
      border: 1px solid #bbb;
      padding-left: 4px;
    }
  }
`;

export const Sidebar = memo(
  ({
    headerHeight = defaultHeaderHeight,
    ...props
  }: StackProps & { headerHeight?: number }) => {
    return (
      <Stack
        w={368}
        h={"100%"}
        gap={0}
        style={{
          borderRight: "1px solid #ccc",
          boxShadow: "0 0 4px rgba(0, 0, 0, 0.1)",
          background: "linear-gradient(180deg, #f1f5f9 0%, #f1f5f9 100%)",
        }}
        pos={"relative"}
        {...props}
      >
        <Group
          h={headerHeight}
          align="center"
          px={"md"}
          style={{
            borderBottom: "1px solid #ddd",
          }}
          bg="#F1F5F9"
          justify="space-between"
        >
          <Group gap={"xs"}>
            <Logo variant="dark" width={60} to={Route.Models} />
            <MdKeyboardArrowRight />
            <NameInput />
          </Group>
          <Status />
        </Group>

        <Stack
          h={"100%"}
          justify="space-between"
          gap={0}
          pos={"relative"}
          flex={1}
          style={{
            overflow: "hidden",
          }}
        >
          <ScrollArea scrollbarSize={8}>
            <Stack
              flex={1}
              gap={"xs"}
              style={{ overflow: "hidden" }}
              pt={"xs"}
              pb={100}
              pl={"xs"}
            >
              <Accordion />
            </Stack>
          </ScrollArea>
        </Stack>
        <CollapseAllButton />
      </Stack>
    );
  },
);

const Status = () => {
  const isSaving = useAtomValue(confirmOnCloseAtom);

  return (
    <>
      {isSaving && (
        <Text size="xs" c="var(--mantine-color-gray-4)">
          Saving...
        </Text>
      )}
      {!isSaving && (
        <BsCloudCheckFill size={18} color="var(--mantine-color-gray-4)" />
      )}
    </>
  );
};

export const NameInput = (props: TextInputProps) => {
  const [model] = useModel();
  const [updateModel] = useUpdate();

  return (
    <TextInput
      onChange={(e) => {
        updateModel({ name: e.currentTarget.value });
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter") e.currentTarget.blur();
      }}
      value={model?.name || ""}
      fw={600}
      flex={1}
      placeholder="Name"
      variant="unstyled"
      className={borderOnFocusClasses.borderOnFocus}
      autoComplete="off"
      autoCorrect="off"
      autoCapitalize="off"
      spellCheck="false"
      {...props}
    />
  );
};
