import { DeferredRender } from "@/components/DeferedRender/DeferedRender";
import { RenderWhenInView } from "@/components/RenderWhenInView/RenderWhenInView";
import {
  CellTypes,
  DollarCell as DollarCellType,
  PercentCell as PercentCellType,
  TextCell as TextCellType,
} from "@/financeModels/owasco/types";
import { useInViewport } from "@mantine/hooks";
import { Atom, PrimitiveAtom, useAtomValue } from "jotai";
import { memo } from "react";
import { DollarCell } from "./DollarCell";
import { GrowthCell, defaultGrowthCellWidth } from "./GrowthCell";
import { PercentCell } from "./PercentCell";
import { TextCell } from "./TextCell";
import { defaultHeight, defaultWidth } from "./cell/Wrapper";

export const CellSlot = memo(
  ({
    atom,
    ...props
  }: {
    atom: PrimitiveAtom<CellTypes> | Atom<CellTypes>;
    tabX: number;
    tabY: number;
  }) => {
    const cell = useAtomValue(atom);
    const { ref, inViewport } = useInViewport();

    return (
      <div
        ref={ref}
        style={{
          minWidth:
            cell.type === "growth" ? defaultGrowthCellWidth : defaultWidth,
          minHeight: defaultHeight,
        }}
      >
        <DeferredRender>
          <RenderWhenInView>
            {inViewport && cell.type === "dollar" && (
              <DollarCell
                atom={atom as PrimitiveAtom<DollarCellType>}
                {...props}
              />
            )}
            {inViewport && cell.type === "text" && (
              <TextCell atom={atom as PrimitiveAtom<TextCellType>} {...props} />
            )}
            {inViewport && cell.type === "percent" && (
              <PercentCell
                atom={atom as PrimitiveAtom<PercentCellType>}
                {...props}
              />
            )}
            {inViewport && cell.type === "growth" && (
              <GrowthCell
                atom={atom as PrimitiveAtom<PercentCellType>}
                {...props}
              />
            )}
          </RenderWhenInView>{" "}
        </DeferredRender>
      </div>
    );
  },
);
