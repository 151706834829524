import { toNumberOrNull } from "@/utils/math";
import { atom } from "jotai";
import { DollarAmount } from "../types";
import {
  businessAcquisitionCostAtom,
  isRealEstateIncludedAtom,
  lenderLineOfCreditTotalAmountAtom,
  loanTransactionFeeAtom,
  realEstateBusinessValueDollarAtom,
  realEstateDollarValueAtom,
  sbaGuarantyFeeAtom,
  sbaLoanInterestRateAtom,
  sbaLoanStartDateAtom,
  sbaLoanTotalWithFeesWithoutLOCAtom,
  sellersNoteAmortizationScheduleAtom,
  sellersNoteDollarAtom,
  sellersNoteInterestRateAtom,
  sellersNoteStartDateAtom,
} from "./general";

import { pmt } from "@travishorn/finance";
import {
  sba7aPaymentsMetaFocusAtom,
  sellersNotePaymentsMetaFocusAtom,
} from "./paymentsMeta";
import { PaymentMeta } from "./types";

/////
// SBA Loan
/////

export const sbaLoanAmortizationScheduleAtom = atom((get) => {
  if (!get(isRealEstateIncludedAtom)) return 10;
  const realEstateVal = Number(get(realEstateDollarValueAtom)) || 0;
  const bizVal = Number(get(realEstateBusinessValueDollarAtom)) || 0;

  if (realEstateVal / (realEstateVal + bizVal) > 0.51) return 25;

  const bizAcq = Number(get(businessAcquisitionCostAtom)) || 0;
  const transactionFee = Number(get(loanTransactionFeeAtom)) || 0;
  const sbaGuarantyFee = Number(get(sbaGuarantyFeeAtom)) || 0;
  const lenderCredit = Number(get(lenderLineOfCreditTotalAmountAtom)) || 0;

  const total =
    bizAcq + realEstateVal + transactionFee + sbaGuarantyFee + lenderCredit;

  const bizAcqYears = (bizAcq / total) * 10;
  const realEstateYears = (realEstateVal / total) * 25;
  const transactionFeeYears = (transactionFee / total) * 10;
  const sbaGuarantyFeeYears = (sbaGuarantyFee / total) * 10;
  const lenderCreditYears = (lenderCredit / total) * 10;

  return (
    bizAcqYears +
    realEstateYears +
    transactionFeeYears +
    sbaGuarantyFeeYears +
    lenderCreditYears
  );
});

export const sbaLoanNumberOfPaymentsPerYearAtom = atom<number>(() => 12);
export const sbaLoanRecurringPaymentAtom = atom<DollarAmount>(0);
export const sbaLoanAmortizationPaymentsAtom = atom((get) => {
  return getAmoritizationPayments({
    loanAmount: toNumberOrNull(get(sbaLoanTotalWithFeesWithoutLOCAtom)) || 0,
    interestRate: toNumberOrNull(get(sbaLoanInterestRateAtom)) || 0,
    schedule: toNumberOrNull(get(sbaLoanAmortizationScheduleAtom)) || 0,
    paymentPerYear: get(sbaLoanNumberOfPaymentsPerYearAtom),
    startDate: get(sbaLoanStartDateAtom).toDate(),
    paymentsMeta: get(sba7aPaymentsMetaFocusAtom),
    recurringPayment: toNumberOrNull(get(sbaLoanRecurringPaymentAtom)) || 0,
  });
});

/////
// Sellers Note
/////

export const sellersNoteNumberOfPaymentsPerYearAtom = atom<number>(() => 12);

export const sellersNoteRecurringPaymentAtom = atom<DollarAmount>(0);

export const sellersNoteAmortizationPaymentsAtom = atom((get) => {
  return getAmoritizationPayments({
    loanAmount: toNumberOrNull(get(sellersNoteDollarAtom)) || 0,
    interestRate: toNumberOrNull(get(sellersNoteInterestRateAtom)) || 0,
    schedule: toNumberOrNull(get(sellersNoteAmortizationScheduleAtom)) || 0,
    paymentPerYear: get(sellersNoteNumberOfPaymentsPerYearAtom),
    startDate: get(sellersNoteStartDateAtom).toDate(),
    paymentsMeta: get(sellersNotePaymentsMetaFocusAtom),
    recurringPayment: toNumberOrNull(get(sellersNoteRecurringPaymentAtom)) || 0,
  });
});

export type AmortizationPayment = {
  index: number;
  paymentPeriod: number;
  date: Date;
  yearPeriod: number;
  scheduledPayment: number;
  extraPayment: number;
  totalPayment: number;
  principal: number;
  interest: number;
  endingBalance: number;
  cumulativeInterest: number;
  startBalance: number;
  fixedExtraPayment: number;
};

export const getAmoritizationNumberPayments = ({
  schedule,
  paymentPerYear,
}: {
  schedule: number;
  paymentPerYear: number;
}) => {
  const numberOfPayments = Math.ceil(schedule * paymentPerYear) - 1; // Subtract 1 from schedule to account for the first payment
  return numberOfPayments;
};

const getAmoritizationPayments = ({
  loanAmount,
  interestRate,
  schedule,
  paymentPerYear,
  startDate,
  paymentsMeta,
  recurringPayment,
}: {
  loanAmount: number;
  interestRate: number;
  schedule: number;
  paymentPerYear: number;
  startDate: Date;
  paymentsMeta: Record<number, PaymentMeta>;
  recurringPayment?: number;
}) => {
  const numberOfPayments = getAmoritizationNumberPayments({
    schedule,
    paymentPerYear,
  });

  let cumulativeInterest = 0;

  const table: AmortizationPayment[] = [];
  for (let i = 0; i < numberOfPayments; i++) {
    const paymentPeriod = i + 1;
    const yearPeriod = Math.floor(i / paymentPerYear) + 1;
    const date = addMonths(startDate, i);

    const startBalance = i === 0 ? loanAmount : table[i - 1].endingBalance;
    const scheduledPayment =
      pmt(interestRate / 12, numberOfPayments, loanAmount) * -1;
    const interest = startBalance * (interestRate / paymentPerYear);
    const principal = scheduledPayment - interest;
    const extraPayment = paymentsMeta[i]?.extraPayment || 0;
    const fixedExtraPayment = extraPayment || recurringPayment || 0;
    const totalPayment = principal + interest + fixedExtraPayment;
    const endingBalance = Math.max(
      0,
      startBalance - principal - fixedExtraPayment,
    );

    cumulativeInterest += interest;

    table.push({
      index: i,
      yearPeriod,
      paymentPeriod,
      date,
      startBalance,
      extraPayment,
      fixedExtraPayment,
      endingBalance,
      scheduledPayment,
      totalPayment,
      principal,
      interest,
      cumulativeInterest,
    });

    if (endingBalance <= 0) {
      break;
    }
  }

  return table;
};

const addMonths = (date: Date, months: number) => {
  const newDate = new Date(date);
  newDate.setMonth(date.getMonth() + months);
  return newDate;
};
