import TooltipTPM from "@/docs/tooltips/target-purchase-multiple.mdx";

import { isBetween, toNumberOrNull } from "@/utils/math";
import {
  ActionIcon,
  Box,
  Button,
  Grid,
  Group,
  NumberInput,
  SegmentedControl,
  Stack,
  Text,
} from "@mantine/core";
import { format } from "mathjs";
import {
  TbSquareRoundedMinusFilled,
  TbSquareRoundedPlusFilled,
} from "react-icons/tb";

import { AiOutlineFunction } from "react-icons/ai";
import { BigDollarInput } from "../BigDollarInput/BigDollarInput";
import { LabelExplainer } from "./LabelExplainer";
import { QuestionnaireSection } from "./QuestionnaireSection";

import {
  cashFlowAtom,
  getMultipleFromPrice,
  listingPurchaseMultipleAtom,
  targetPurchaseMultipleAtom,
  targetPurchasePriceAtom,
} from "@/financeModels/liteAtoms";
import { useAtom } from "jotai";

export const SegmentTargetPurchaseMultiple = () => {
  const [targetPurchasePrice] = useAtom(targetPurchasePriceAtom);
  const [listingPurchaseMultiple] = useAtom(listingPurchaseMultipleAtom);
  const [cashFlow] = useAtom(cashFlowAtom);
  const [targetPurchaseMultiple, setTargetPurchaseMultiple] = useAtom(
    targetPurchaseMultipleAtom,
  );

  const purchaseMultiple = toNumberOrNull(targetPurchaseMultiple) ?? 0;

  // round listingPurchaseMultiple to the nearest 0.25
  const listingPurchaseMultipleRounded =
    Math.round((toNumberOrNull(listingPurchaseMultiple) ?? 0) * 4) / 4;

  const segmentedData = [1, 2, 3].map((i) => {
    const value = listingPurchaseMultipleRounded - i * 0.25;
    return {
      value: value.toString(),
      label:
        value > 0 && value < 10 ? value.toFixed(2).toString() + "x" : "- - -",
    };
  });

  const segmentIncludePurchaseMultiple = segmentedData.find(
    (d) => d.value === purchaseMultiple.toString(),
  );

  const isValidListingPurchaseMultiple = isBetween(
    listingPurchaseMultiple,
    1,
    9,
  );

  return (
    <QuestionnaireSection
      icon={AiOutlineFunction}
      label={
        <LabelExplainer
          label="Target Purchase Multiple"
          explanation={<TooltipTPM />}
        />
      }
    >
      <Box>
        {!isValidListingPurchaseMultiple && (
          <Stack justify="center" align="center" h="90">
            <Text c="dimmed" px={{ base: 0, md: "15%" }} ta="center">
              Once you enter a valid Listing Price and Cash Flow (SDE) your
              purchase multiple options will appear here.
            </Text>
          </Stack>
        )}
        {isValidListingPurchaseMultiple && (
          <Grid gutter={25} align="center" justify="center">
            <Grid.Col span="content">
              <Group gap={4}>
                <ActionIcon
                  variant="transparent"
                  color={"accent.6"}
                  onClick={() => {
                    setTargetPurchaseMultiple(purchaseMultiple - 0.01);
                  }}
                >
                  <TbSquareRoundedMinusFilled size={30} />
                </ActionIcon>
                <NumberInput
                  styles={{
                    input: { textAlign: "center", padding: 0 },
                  }}
                  allowLeadingZeros={true}
                  allowNegative={false}
                  decimalScale={2}
                  hideControls
                  size={"xl"}
                  value={purchaseMultiple}
                  w={95}
                  suffix="x"
                  clampBehavior="strict"
                  min={0}
                  max={9}
                  fixedDecimalScale
                  onChange={(value) => {
                    setTargetPurchaseMultiple(Number(value));
                  }}
                />
                <ActionIcon
                  variant="transparent"
                  color={"accent.6"}
                  onClick={() => {
                    setTargetPurchaseMultiple(purchaseMultiple + 0.01);
                  }}
                >
                  <TbSquareRoundedPlusFilled size={30} />
                </ActionIcon>
              </Group>
            </Grid.Col>
            <Grid.Col span={{ md: "content" }}>
              <Stack>
                <SegmentedControl
                  color={
                    segmentIncludePurchaseMultiple ? "accent" : "transparent"
                  }
                  value={purchaseMultiple.toString()}
                  size="md"
                  data={segmentedData}
                  onChange={(value) => {
                    setTargetPurchaseMultiple(Number(value));
                  }}
                />
                <Button
                  size="md"
                  variant="light"
                  disabled={!isBetween(listingPurchaseMultiple, 0, 10)}
                  onClick={() => {
                    setTargetPurchaseMultiple(listingPurchaseMultiple);
                  }}
                >
                  Reset
                  {isBetween(listingPurchaseMultiple, 0, 10)
                    ? " (" +
                      format(listingPurchaseMultiple, {
                        notation: "fixed",
                        precision: 2,
                      }) +
                      "x)"
                    : ""}
                </Button>
              </Stack>
            </Grid.Col>
            <Grid.Col span="content">
              <BigDollarInput
                value={toNumberOrNull(targetPurchasePrice) ?? ""}
                pos={"relative"}
                top={-10}
                w={190}
                size={"lg"}
                label="Target Purchase Price:"
                onChange={(value) => {
                  const multiple = getMultipleFromPrice({
                    price: toNumberOrNull(value ?? 0),
                    cashFlow,
                  });
                  setTargetPurchaseMultiple(multiple);
                }}
              />
            </Grid.Col>
          </Grid>
        )}
      </Box>
    </QuestionnaireSection>
  );
};
