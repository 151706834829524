import {
  isRollinLoanTransactionFeeAtom,
  isRollinSBAGuarantyFeeAtom,
  sbaLoanTotalWithOptionalFeesAtom,
} from "@/financeModels/owasco/general";
import { formatDollar } from "@/utils/format";
import { useAtom, useAtomValue } from "jotai";
import { ChangeEvent } from "react";
import { ComputedResult } from "./ComputedResult";
import { InputToggle } from "./InputToggle";

export const DealFees = () => {
  const [isRollinLoanTransactionFee, setRollinLoanTransactionFee] = useAtom(
    isRollinLoanTransactionFeeAtom,
  );
  const [isRollinSBAGuarantyFee, setRollinSBAGuarantyFee] = useAtom(
    isRollinSBAGuarantyFeeAtom,
  );
  //const loanTransactionFee = useAtomValue(loanTransactionFeeAtom);
  //const sbaGuarantyFee = useAtomValue(sbaGuarantyFeeAtom);
  const sbaLoanTotalWithOptionalFees = useAtomValue(
    sbaLoanTotalWithOptionalFeesAtom,
  );
  return (
    <>
      {/* <ComputedResult
        label="Loan Transaction Fee"
        value={formatDollar(loanTransactionFee)}
        variant="default"
      /> */}

      <InputToggle
        label="Roll-in Loan Transation Fee"
        checked={isRollinLoanTransactionFee}
        onChange={(event) =>
          setRollinLoanTransactionFee(
            (event as ChangeEvent<HTMLInputElement>).currentTarget.checked,
          )
        }
      />

      {/* <ComputedResult
        label="SBA Guaranty Fee"
        value={formatDollar(sbaGuarantyFee)}
        variant="default"
      /> */}

      <InputToggle
        label="Roll-in SBA's Guaranty Fee"
        checked={isRollinSBAGuarantyFee}
        onChange={(event) =>
          setRollinSBAGuarantyFee(
            (event as ChangeEvent<HTMLInputElement>).currentTarget.checked,
          )
        }
      />

      <ComputedResult
        label="SBA 7(a) Loan Total"
        value={formatDollar(sbaLoanTotalWithOptionalFees)}
      />
    </>
  );
};
