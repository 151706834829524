import { Paper, Stack, Text } from "@mantine/core";
import { captureException } from "@sentry/react";
import { useEffect } from "react";
import { useRouteError } from "react-router-dom";
import { LayoutFullscreen } from "../LayoutFullscreen/LayoutFullscreen";

export const PageError = () => {
  const routeError = useRouteError();

  useEffect(() => {
    captureException(routeError, { level: "fatal" });
  }, [routeError]);

  return (
    <LayoutFullscreen>
      <Paper withBorder p="lg" maw={600} mb={60}>
        <Stack align="center">
          <Text size="xl">Something went wrong.</Text>
          <Text size="lg" ta={"center"}>
            We're sorry, an unexpected error occured. Please refresh the page
            and try again. If the problem persists, please contact support at
            contact@etaiq.com
          </Text>
        </Stack>
      </Paper>
    </LayoutFullscreen>
  );
};
