export class MathEvaluteError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "MathEvaluteError";
    //console.warn("MathEvaluteError", message);
  }
}

export type MathResult = number | null | MathEvaluteError;
export type DollarAmount = MathResult;
export type PercentAmount = MathResult;
export type YearAmount = MathResult;
export type CellValue = DollarAmount | PercentAmount | YearAmount;
