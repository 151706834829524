import { panelClass } from "@/components/LayoutWorkspace/LayoutWorkspace";
import {
  cashRequiredByBorrowerClosingDayAtom,
  cashToSellerOnClosingDayAtom,
} from "@/financeModels/owasco/general";
import { formatDollar } from "@/utils/format";
import { Group, SimpleGrid, Stack, Text } from "@mantine/core";
import { useAtomValue } from "jotai";

export const CashRequired = () => {
  const cashRequiredByBorrowerClosingDay = useAtomValue(
    cashRequiredByBorrowerClosingDayAtom,
  );
  const cashToSellerOnClosingDay = useAtomValue(cashToSellerOnClosingDayAtom);

  return (
    <SimpleGrid cols={{ base: 1, xs: 2, md: 2 }}>
      <Stack gap={0} className={panelClass} p="md">
        <Group gap={"xs"}>
          <Text size="xl">Cash Required by Borrower on Closing Day</Text>
        </Group>

        <Text size="xl" fz={30} fw={600}>
          {formatDollar(cashRequiredByBorrowerClosingDay)}
        </Text>
      </Stack>
      <Stack gap={0} className={panelClass} p="md">
        <Group gap={"xs"}>
          <Text size="xl">Amount Paid to Seller on Closing Day</Text>
        </Group>

        <Text size="xl" fz={30} fw={600}>
          {formatDollar(cashToSellerOnClosingDay)}
        </Text>
      </Stack>
    </SimpleGrid>
  );
};
