import { LayoutPage } from "@/components/LayoutPage/LayoutPage";
import { useAuthContext } from "@/contexts/AuthProvider";
import { auth } from "@/firebase";
import { Button, Container, Group, Stack, Text } from "@mantine/core";
import { useSignOut } from "react-firebase-hooks/auth";

export const PageProfile = () => {
  const { user } = useAuthContext();
  const [signout] = useSignOut(auth);
  return (
    <LayoutPage>
      <Container my={"xl"}>
        <Group justify="space-between">
          <Stack>
            <Text fz={40} size={"xs"}>
              Profile
            </Text>
            <Text>Welcome {user?.displayName}!</Text>
            <Text>Email: {user?.email}</Text>
          </Stack>
          <Button w={100} variant="outline" onClick={signout}>
            Log out
          </Button>
        </Group>
      </Container>
    </LayoutPage>
  );
};
