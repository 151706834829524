import { LayoutPage } from "@/components/LayoutPage/LayoutPage";
import { Container, TypographyStylesProvider } from "@mantine/core";
import { ReactNode } from "react";

export const LayoutDoc = ({ children }: { children: ReactNode }) => {
  return (
    <LayoutPage>
      <Container size="lg" my={"xl"}>
        <TypographyStylesProvider>{children}</TypographyStylesProvider>
      </Container>
    </LayoutPage>
  );
};
