import { TextCell as TextCellType } from "@/financeModels/owasco/types";
import { TextInput } from "@mantine/core";
import { PrimitiveAtom, useAtom } from "jotai";
import { memo, useState } from "react";
import { Stub } from "./cell/Stub";
import { Wrapper } from "./cell/Wrapper";
import { useTabCoordinates } from "./hooks/useTabCoordinates";

export type TextCellProps = {
  tabX: number;
  tabY: number;
  atom: PrimitiveAtom<TextCellType>;
  ta?: "left" | "right" | "center";
};

export const TextCell = memo(({ tabX, tabY, atom, ta }: TextCellProps) => {
  const [cell, setCell] = useAtom(atom);
  const [inputValue, setInputValue] = useState(cell.value);
  const [isEditing, setIsEditing] = useState(false);

  const { id, moveFocus } = useTabCoordinates({ tabX, tabY });

  const onChange = (value: string) => {
    setInputValue(value);
  };

  const resetCell = () => {
    setCell((c) => ({ ...c, value: null }));
  };

  const { isLocked, isOverrided } = cell;
  const isEditMode = !isLocked && isEditing;

  return (
    <Wrapper id={id}>
      {!isEditMode && (
        <Stub
          ta={ta}
          moveFocus={moveFocus}
          resetCell={resetCell}
          label={cell.value}
          enableEditing={() => setIsEditing(true)}
          locked={isLocked}
          overrided={isOverrided}
        />
      )}
      {isEditMode && (
        <TextInput
          value={inputValue || ""}
          onChange={(event) => onChange(event.currentTarget.value)}
          autoFocus
          radius={0}
          size="sm"
          w={"100%"}
          onBlur={() => {
            setIsEditing(false);
            setCell((c) => ({ ...c, value: inputValue }));
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setCell((c) => ({ ...c, value: inputValue }));
              setIsEditing(false);
              moveFocus("down");
            }
          }}
        />
      )}
    </Wrapper>
  );
});
