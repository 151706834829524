import {
  cashFlowAtom,
  getPurchaseMultiple,
  listingPriceAtom,
  targetPurchaseMultipleAtom,
} from "@/financeModels/liteAtoms";
import { useAtom, useSetAtom } from "jotai";
import { BigDollarInput } from "../BigDollarInput/BigDollarInput";

export const NumberInputPurchasePrice = () => {
  const [listingPrice, setListingPrice] = useAtom(listingPriceAtom);
  const [cashFlow] = useAtom(cashFlowAtom);
  const setTargetPurchaseMultiple = useSetAtom(targetPurchaseMultipleAtom);

  return (
    <BigDollarInput
      value={typeof listingPrice === "number" ? listingPrice : undefined}
      onChange={(value) => {
        const newListingPrice = typeof value === "number" ? value : null;
        setListingPrice(newListingPrice);
        setTargetPurchaseMultiple(
          getPurchaseMultiple({
            price: newListingPrice,
            cashFlow,
          }),
        );
      }}
      label="Listing Price:"
      placeholder="$"
    />
  );
};
