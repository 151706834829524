import { isCloningFromEpochsAtom } from "@/financeModels/owasco/clone";
import { isProjectionDirtyAtom } from "@/financeModels/owasco/projections";
import { useAtomValue } from "jotai";
import { DataGrid } from "./DataGrid";
import { Start } from "./Start";

export const PanelProjections = () => {
  const isDirty = useAtomValue(isProjectionDirtyAtom);
  const isCloning = useAtomValue(isCloningFromEpochsAtom);

  if (isCloning) return null;

  return <>{isDirty ? <DataGrid /> : <Start />}</>;
};
