import { evaluteWithErrors as solve } from "@/utils/math";
import { getMoleculeValueAtIndex } from "@/utils/molecule";
import { Atom, Getter, PrimitiveAtom, atom } from "jotai";
import { focusAtom } from "jotai-optics";
import { DollarAmount } from "../types";
import {
  AmortizationPayment,
  sbaLoanAmortizationPaymentsAtom,
  sellersNoteAmortizationPaymentsAtom,
} from "./amortization";
import {
  buyerAnnualCapExAtom,
  buyerCompTotalAtom,
  buyerDeprAndAmortScheduleAtom,
  companysTaxRateAtom,
  lenderLineOfCreditDayOneDrawdownAtom,
  sbaLoanInterestRateAtom,
  sbaLoanStartDateAtom,
  targetPurchasePriceAtom,
} from "./general";
import { getSummaryValueAtIndex } from "./itemization";
import {
  createAggregatedIterationAtom,
  createComputedIterationMolecule,
  createGrowthRateCellAtom,
  createGrowthRateIterationMolecule,
  createStorableDollarIterationMolecule,
  iterationCountAtom,
} from "./iterations";
import { modelAtom } from "./model";
import { CellTypes } from "./types";

export const isProjectionDirtyAtom = focusAtom(modelAtom, (optic) =>
  optic.prop("general").prop("isProjectionInitialized").valueOr(false),
);

export const yearsFromSbaLoanStartDateAtom = atom((get) => {
  const startDate = get(sbaLoanStartDateAtom);
  const iterationCount = get(iterationCountAtom);
  return Array.from({ length: iterationCount }, (_, i) => {
    return i + startDate.toDate().getFullYear();
  });
});

export const grossRevenueItemization = createAggregatedIterationAtom(
  "projections|grossRevenue",
);

export const grossRevenueYoYGrowth = createComputedIterationMolecule((i) => {
  return atom((get) => {
    const currentYear = Number(
      getSummaryValueAtIndex(grossRevenueItemization, i, get),
    );

    const previousYear = Number(
      getSummaryValueAtIndex(
        grossRevenueItemization,
        Math.max(0, i === 2 ? 0 : i - 1),
        get,
      ),
    );

    return {
      type: "percent",
      isLocked: true,
      value: i < 2 ? null : currentYear / previousYear - 1,
    };
  });
});

export const costOfGoodsSoldItemization = createAggregatedIterationAtom(
  "projections|costOfGoodsSold",
  { isNegative: true },
);

export const grossProfitMolecule = createComputedIterationMolecule((i) => {
  return atom((get) => {
    return {
      type: "dollar",
      isLocked: true,
      value:
        Number(getSummaryValueAtIndex(grossRevenueItemization, i, get)) -
        Number(getSummaryValueAtIndex(costOfGoodsSoldItemization, i, get)),
    };
  });
});

export const grossMarginsMolecule = createComputedIterationMolecule((i) =>
  atom((get) => {
    return {
      type: "percent",
      isLocked: true,
      value:
        Number(get(get(grossProfitMolecule)[i]).value) /
        Number(getSummaryValueAtIndex(grossRevenueItemization, i, get)),
    };
  }),
);

export const operatingExpensesItemization = createAggregatedIterationAtom(
  "projections|operatingExpenses",
  { isNegative: true },
);

export const buyersCompMolecule = createGrowthRateIterationMolecule(
  "projections|buyersComp",
  atom((get) => {
    return {
      type: "dollar",
      isLocked: true,
      isNegative: true,
      value: get(buyerCompTotalAtom),
    };
  }) as PrimitiveAtom<CellTypes>,
  createGrowthRateCellAtom("buyersCompGrowthCell"),
);

export const ebitdaMolecule = createComputedIterationMolecule((i) => {
  return atom((get) => {
    return {
      type: "dollar",
      isLocked: true,
      value:
        Number(get(get(grossProfitMolecule)[i]).value || 0) -
        Number(get(get(buyersCompMolecule)[i]).value || 0) +
        Number(getSummaryValueAtIndex(operatingExpensesItemization, i, get)),
    };
  });
});

export const ebitdaMarginsMolecule = createComputedIterationMolecule((i) => {
  return atom((get) => {
    return {
      type: "percent",
      isLocked: true,
      value:
        Number(get(get(ebitdaMolecule)[i]).value) /
        Number(getSummaryValueAtIndex(grossRevenueItemization, i, get)),
    };
  });
});

export const operatingIncomeMolecule = createComputedIterationMolecule((i) => {
  return atom((get) => {
    return {
      type: "dollar",
      isLocked: true,
      value:
        Number(get(get(ebitdaMolecule)[i]).value) -
        Number(get(get(depreciationAndAmortizationMolecule)[i]).value),
    };
  });
});

const getYearlyExpense = (
  get: Getter,
  year: number,
  paymentsAtom: Atom<AmortizationPayment[]>,
  key: keyof AmortizationPayment,
) => {
  const yearPayments = get(paymentsAtom).filter((payment) => {
    return payment.yearPeriod === Math.max(1, year); // year 0 is not a valid year
  });

  return yearPayments.reduce((acc, payment) => {
    return acc + Number(payment[key]);
  }, 0);
};

const getAmortizationYearlyExpensesMolecules = (
  paymentsAtom: Atom<AmortizationPayment[]>,
) => {
  const interestMolecule = createComputedIterationMolecule((_i, iter) => {
    return atom((get) => {
      return {
        type: "dollar",
        isNegative: true,
        isLocked: true,
        value: getYearlyExpense(get, iter, paymentsAtom, "interest"),
      };
    });
  });

  const principalMolecule = createComputedIterationMolecule((_i, iter) => {
    return atom((get) => {
      return {
        type: "dollar",
        isLocked: true,
        value: getYearlyExpense(get, iter, paymentsAtom, "principal"),
      };
    });
  });

  const extraPaymentsMolecule = createComputedIterationMolecule((_i, iter) => {
    return atom((get) => {
      return {
        type: "dollar",
        isLocked: true,
        value: getYearlyExpense(get, iter, paymentsAtom, "fixedExtraPayment"),
      };
    });
  });

  const startingBalanceMolecule = createComputedIterationMolecule(
    (_i, iter) => {
      return atom((get) => {
        return {
          type: "dollar",
          isLocked: true,
          value:
            get(paymentsAtom)
              .filter((payment) => {
                return payment.yearPeriod === Math.max(1, iter); // year 0 is not a valid year
              })
              .shift()?.startBalance || 0,
        };
      });
    },
  );

  const endingBalanceMolecule = createComputedIterationMolecule((_i, iter) => {
    return atom((get) => {
      return {
        type: "dollar",
        isLocked: true,
        value:
          get(paymentsAtom)
            .filter((payment) => {
              return payment.yearPeriod === Math.max(1, iter); // year 0 is not a valid year
            })
            .pop()?.endingBalance || 0,
      };
    });
  });

  return {
    interestMolecule,
    principalMolecule,
    extraPaymentsMolecule,
    startingBalanceMolecule,
    endingBalanceMolecule,
  };
};

export const {
  interestMolecule: sba7aLoanExpenseInterestMolecule,
  principalMolecule: sba7aLoanExpensePrincipalMolecule,
  startingBalanceMolecule: sba7aLoanExpenseStartingBalanceMolecule,
  endingBalanceMolecule: sba7aLoanExpenseEndingBalanceMolecule,
  extraPaymentsMolecule: sba7aLoanExpenseExtraPaymentsMolecule,
} = getAmortizationYearlyExpensesMolecules(sbaLoanAmortizationPaymentsAtom);

export const {
  interestMolecule: sellersNoteExpenseInterestMolecule,
  principalMolecule: sellersNoteExpensePrincipalMolecule,
  startingBalanceMolecule: sellersNoteExpenseStartingBalanceMolecule,
  endingBalanceMolecule: sellersNoteExpenseEndingBalanceMolecule,
  extraPaymentsMolecule: sellersNoteExpenseExtraPaymentsMolecule,
} = getAmortizationYearlyExpensesMolecules(sellersNoteAmortizationPaymentsAtom);

export const depreciationAndAmortizationMolecule =
  createComputedIterationMolecule((_i, iter) => {
    return atom((get) => {
      let value =
        Number(get(targetPurchasePriceAtom)) /
        Number(get(buyerDeprAndAmortScheduleAtom));

      if (iter >= Number(get(buyerDeprAndAmortScheduleAtom))) value = 0;

      return {
        type: "dollar",
        isLocked: true,
        isNegative: true,
        value,
      };
    });
  });

export const annualMaintenanceCapExMolecule = createComputedIterationMolecule(
  () => {
    return atom((get) => {
      return {
        type: "dollar",
        isLocked: true,
        isNegative: true,
        value: get(buyerAnnualCapExAtom),
      };
    });
  },
);

export const taxableIncomeMolecule = createComputedIterationMolecule((i) => {
  return atom((get) => {
    const operatingIncome = Number(get(get(operatingIncomeMolecule)[i]).value);
    const interestExpensesba7aLoan = Number(
      get(get(sba7aLoanExpenseInterestMolecule)[i]).value,
    );
    const interestExpenseSellersNote = Number(
      get(get(sellersNoteExpenseInterestMolecule)[i]).value,
    );
    const annualMaintenanceCapEx =
      Number(get(get(annualMaintenanceCapExMolecule)[i]).value) || 0;

    const value = Math.max(
      0,
      operatingIncome -
        (interestExpensesba7aLoan +
          interestExpenseSellersNote +
          annualMaintenanceCapEx),
    );

    return {
      type: "dollar",
      isLocked: true,
      isNegative: true,
      value: value < 0 ? 0 : value,
    };
  });
});

export const companysTaxRateMolecule = createComputedIterationMolecule(() => {
  return atom((get) => {
    return {
      type: "percent",
      isLocked: true,
      value: get(companysTaxRateAtom),
    };
  });
});

export const incomeTaxesMolecule = createComputedIterationMolecule((i) => {
  return atom((get) => {
    const taxableIncome = Number(get(get(taxableIncomeMolecule)[i]).value);
    const companysTaxRate = Number(get(get(companysTaxRateMolecule)[i]).value);
    return {
      type: "dollar",
      isLocked: true,
      isNegative: true,
      value: taxableIncome * companysTaxRate,
    };
  });
});

// Net Income = [Operating Income] - [Income Taxes] - [SBA 7(a) Interest Exp.] - [Seller’s Note Interest Exp.]
export const netIncomeMolecule = createComputedIterationMolecule((i) => {
  return atom((get) => {
    const operatingIncome = Number(get(get(operatingIncomeMolecule)[i]).value);
    const incomeTaxes = Number(get(get(incomeTaxesMolecule)[i]).value);
    const interestExpensesba7aLoan = Number(
      get(get(sba7aLoanExpenseInterestMolecule)[i]).value,
    );
    const interestExpenseSellersNote = Number(
      get(get(sellersNoteExpenseInterestMolecule)[i]).value,
    );

    return {
      type: "dollar",
      isLocked: true,
      value:
        operatingIncome -
        incomeTaxes -
        interestExpensesba7aLoan -
        interestExpenseSellersNote,
    };
  });
});

export const unleveredFreeCashFlowMolecule = createComputedIterationMolecule(
  (i) => {
    return atom((get) => {
      const operatingIncome = Number(get(get(ebitdaMolecule)[i]).value);
      const incomeTaxes = Number(get(get(incomeTaxesMolecule)[i]).value);
      const annualMaintenanceCapEx =
        Number(get(get(annualMaintenanceCapExMolecule)[i]).value) || 0;
      return {
        type: "dollar",
        isLocked: true,
        value: operatingIncome - incomeTaxes - annualMaintenanceCapEx,
      };
    });
  },
);

export const sba7aLoanPaymentMolecule = createComputedIterationMolecule((i) => {
  return atom((get) => {
    const interest = getMoleculeValueAtIndex(
      get,
      sba7aLoanExpenseInterestMolecule,
      i,
      0,
    );

    const principal = getMoleculeValueAtIndex(
      get,
      sba7aLoanExpensePrincipalMolecule,
      i,
      0,
    );

    return {
      type: "dollar",
      isLocked: true,
      value: interest + principal,
    };
  });
});

export const lineOfCreditComputedInstallmentsAtom = atom((get) => {
  const payments = get(lineOfCreditPrincipalPaymentMolecule);

  const installments: {
    startBalance: number;
    interestPayment: number;
    principalPayment: number;
    endingBalance: number;
  }[] = [];

  return payments.map((_payment, i) => {
    const startBalance =
      i <= 2
        ? Number(get(lenderLineOfCreditDayOneDrawdownAtom))
        : installments[i - 1]?.endingBalance;

    const interestPayment = startBalance * Number(get(sbaLoanInterestRateAtom));
    const principalPayment = Number(
      get(get(lineOfCreditPrincipalPaymentMolecule)[i]).value,
    );
    const endingBalance = Math.max(0, startBalance - principalPayment);

    installments.push({
      startBalance,
      interestPayment,
      principalPayment,
      endingBalance,
    });

    return installments.slice(-1)[0];
  });
});

export const lineOfCreditStartBalanceMolecule = createComputedIterationMolecule(
  (i) =>
    atom((get) => {
      return {
        type: "dollar",
        isLocked: true,
        value: get(lineOfCreditComputedInstallmentsAtom)[i].startBalance,
      };
    }),
);

export const lineOfCreditInterestPaymentMolecule =
  createComputedIterationMolecule((i) =>
    atom((get) => {
      return {
        type: "dollar",
        isLocked: true,
        value: get(lineOfCreditComputedInstallmentsAtom)[i].interestPayment,
      };
    }),
  );

export const lineOfCreditPrincipalPaymentMolecule =
  createStorableDollarIterationMolecule("lineOfCreditPrincipalPayment", {
    isLocked: true,
  });

export const lineOfCreditPaymentMolecule = createComputedIterationMolecule(
  (i) => {
    return atom((get) => {
      const interest = getMoleculeValueAtIndex(
        get,
        lineOfCreditInterestPaymentMolecule,
        i,
        0,
      );

      const principal = getMoleculeValueAtIndex(
        get,
        lineOfCreditPrincipalPaymentMolecule,
        i,
        0,
      );

      return {
        type: "dollar",
        isLocked: true,
        value: interest + principal,
      };
    });
  },
);

export const lineOfCreditEndBalanceMolecule = createComputedIterationMolecule(
  (i) =>
    atom((get) => {
      return {
        type: "dollar",
        isLocked: true,
        value: get(lineOfCreditComputedInstallmentsAtom)[i].endingBalance,
      };
    }),
);

export const sellersNoteLoanPaymentMolecule = createComputedIterationMolecule(
  (i) => {
    return atom((get) => {
      const interest = getMoleculeValueAtIndex(
        get,
        sellersNoteExpenseInterestMolecule,
        i,
        0,
      );

      const principal = getMoleculeValueAtIndex(
        get,
        sellersNoteExpensePrincipalMolecule,
        i,
        0,
      );

      return {
        type: "dollar",
        isLocked: true,
        value: interest + principal,
      };
    });
  },
);

export const totalDebtPaymentMolecule = createComputedIterationMolecule((i) => {
  return atom((get) => {
    const sba7aLoan = Number(get(get(sba7aLoanPaymentMolecule)[i]).value);
    const sellersNote = Number(
      get(get(sellersNoteLoanPaymentMolecule)[i]).value,
    );
    return {
      type: "dollar",
      isLocked: true,
      value: sba7aLoan + sellersNote,
    };
  });
});

export const totalDebtServiceMolecule = createComputedIterationMolecule((i) => {
  return atom((get) => {
    const payment = Number(get(get(totalDebtPaymentMolecule)[i]).value);
    const lineOfCredit = Number(
      get(get(lineOfCreditEndBalanceMolecule)[i]).value,
    );

    return {
      type: "dollar",
      isLocked: true,
      isNegative: true,
      value: payment + lineOfCredit,
    };
  });
});

export const distributableFreeCashFlowMolecule =
  createComputedIterationMolecule((i) => {
    return atom((get) => {
      const unleveredFreeCashFlow = Number(
        get(get(unleveredFreeCashFlowMolecule)[i]).value,
      );
      const totalDebtService = Number(
        get(get(totalDebtServiceMolecule)[i]).value,
      );

      return {
        type: "dollar",
        isLocked: true,
        value: unleveredFreeCashFlow - totalDebtService,
      };
    });
  });

export const equityInvestorsPreferredDividendsMolecule =
  createStorableDollarIterationMolecule("equityInvestorsPreferredDividends");

export const equityInvestorsPrincipalPaymentsMolecule =
  createStorableDollarIterationMolecule("equityInvestorsPrincipalPayments");

export const equityInvestorsProfitDistributionsMolecule =
  createStorableDollarIterationMolecule("equityInvestorsProfitDistributions");

export const equityInvestorsTotalDistributionsMolecule =
  createComputedIterationMolecule((i) => {
    return atom((get) => {
      const preferredDividends = Number(
        get(get(equityInvestorsPreferredDividendsMolecule)[i]).value,
      );
      const principalPayments = Number(
        get(get(equityInvestorsPrincipalPaymentsMolecule)[i]).value,
      );
      const profitDistributions = Number(
        get(get(equityInvestorsProfitDistributionsMolecule)[i]).value,
      );

      return {
        type: "dollar",
        isLocked: true,
        value: preferredDividends + principalPayments + profitDistributions,
      };
    });
  });

export const usableFreeCashFlowMolecule = createComputedIterationMolecule(
  (i) => {
    return atom((get) => {
      const distributableFreeCashFlow = Number(
        get(get(distributableFreeCashFlowMolecule)[i]).value,
      );
      const totalEquityInvestorsDistributions = Number(
        get(get(equityInvestorsTotalDistributionsMolecule)[i]).value,
      );

      return {
        type: "dollar",
        isLocked: true,
        value: distributableFreeCashFlow - totalEquityInvestorsDistributions,
      };
    });
  },
);

export const dscrMolecule = createComputedIterationMolecule((i) => {
  return atom((get) => {
    return {
      type: "dollar",
      isLocked: true,
      value: solve<DollarAmount>(
        "abs(unleveredFreeCashFlow / totalDebtPayment)",
        {
          unleveredFreeCashFlow: get(get(unleveredFreeCashFlowMolecule)[i])
            .value,
          totalDebtPayment: get(get(totalDebtServiceMolecule)[i]).value,
        },
      ),
    };
  });
});
