import { focusAtom } from "jotai-optics";
import { modelAtom } from "./model";

export const paymentsMetaFocusAtom = focusAtom(modelAtom, (optic) =>
  optic.prop("paymentsMeta"),
);

export const sba7aPaymentsMetaFocusAtom = focusAtom(
  paymentsMetaFocusAtom,
  (optic) => optic.prop("sba7a"),
);

export const sellersNotePaymentsMetaFocusAtom = focusAtom(
  paymentsMetaFocusAtom,
  (optic) => optic.prop("sellersNote"),
);
