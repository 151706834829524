import { routes } from "@/docs/_routes";
import {
  Anchor,
  Card,
  Container,
  Grid,
  Group,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { Link } from "react-router-dom";
import { LayoutPage } from "../LayoutPage/LayoutPage";
import { TableOfContents } from "./TableOfContents";

export const categories = [
  {
    title: "Educate",
    description: "What’s possible.",
  },
  {
    title: "Search",
    description: "Where to start, how to start.",
  },
  {
    title: "Evaluate",
    description: "Financial analysis & structuring deals.",
  },
  {
    title: "Acquire",
    description: "LOI & due diligence.",
  },
  {
    title: "Finance",
    description: "Securing debt & equity funding.",
  },
  {
    title: "Acquire",
    description: "From LOI to closing on a business.",
  },
  {
    title: "Transition",
    description: "From Search Funder to business owner.",
  },
  {
    title: "Grow",
    description: "Sales, Marketing, Operations",
  },
];

const articles = routes
  .filter((r) => r.path.includes("/learn"))
  .map((r) => {
    return { ...r, category: r.path.split("/")[2] };
  });

export const PageLearn = () => {
  return (
    <LayoutPage>
      <Container size={"xl"} py={"xl"}>
        <Grid>
          <Grid.Col span={3}>
            <TableOfContents />
          </Grid.Col>
          <Grid.Col span={9}>
            <Title>Learning Center</Title>
            <Text mb={"xl"}>
              The Learning Center is a collection of resources to help you
              understand the process of buying a business. It is organized into
              categories to help you find what you need.
            </Text>

            {categories.map((category) => {
              return (
                <Stack key={category.title} mb={"lg"}>
                  <Title order={3}>{category.title}</Title>
                  <Text>{category.description}</Text>
                  <Group>
                    {articles
                      .filter(
                        (a) =>
                          a.category.toLowerCase() ===
                          category.title.toLowerCase(),
                      )
                      .map((a) => {
                        return (
                          <Card
                            key={a.path}
                            withBorder
                            radius={"md"}
                            mb={"sm"}
                            p={"sm"}
                          >
                            <Text></Text>
                            <Anchor component={Link} to={a.path}>
                              {a.title}
                            </Anchor>
                          </Card>
                        );
                      })}
                  </Group>
                </Stack>
              );
            })}
          </Grid.Col>
        </Grid>
      </Container>
    </LayoutPage>
  );
};
