import {
  isRealEstateInErrorAtom,
  isRealEstateIncludedAtom,
  realEstateBusinessValueDollarAtom,
  realEstateBusinessValuePercentAtom,
  realEstateErrorMessageAtom,
  realEstateIsDollarFormat,
  realEstateValueInputDollarAtom,
  realEstateValuePercentAtom,
  targetPurchasePriceAtom,
} from "@/financeModels/owasco/general";
import { numberToPercentInput, percentInputToNumber } from "@/utils/format";
import { toNumberOrNull } from "@/utils/math";
import { Alert, Stack } from "@mantine/core";
import { useAtom, useAtomValue } from "jotai";
import { ChangeEvent } from "react";
import { TbHome } from "react-icons/tb";
import { InputDollar } from "./InputDollar";
import { InputDollarPercentSwitch } from "./InputDollarPercentSwitch";
import { InputPercent } from "./InputPercent";
import { InputToggle } from "./InputToggle";

const Panel = () => {
  const targetPurchasePrice = useAtomValue(targetPurchasePriceAtom);
  const [isIncluded, setIncluded] = useAtom(isRealEstateIncludedAtom);
  const [isDollarFormat, setIsDollarFormat] = useAtom(realEstateIsDollarFormat);
  const [realEstateDollarValue, setRealEstateDollarValue] = useAtom(
    realEstateValueInputDollarAtom,
  );
  const [realEstateBusinessDollarValue, setRealEstateBusinessDollarValue] =
    useAtom(realEstateBusinessValueDollarAtom);

  const [realEstatePercentValue, setRealEstatePercentValue] = useAtom(
    realEstateValuePercentAtom,
  );
  const [realEstateBusinessPercentValue, setRealEstateBusinessPercentValue] =
    useAtom(realEstateBusinessValuePercentAtom);

  const realEstateErrorMessage = useAtomValue(realEstateErrorMessageAtom);

  const onChangeFormat = (value: string) => {
    setIsDollarFormat(value === "dollar");
    if (value === "dollar") {
      const realEstateDollar = Math.round(
        Number(realEstatePercentValue) * Number(targetPurchasePrice),
      );

      setRealEstateDollarValue(realEstateDollar, true);
      setRealEstateBusinessDollarValue(
        Math.round(Number(targetPurchasePrice) - realEstateDollar),
        true,
      );
    } else {
      setRealEstatePercentValue(
        Math.round(
          (Number(realEstateDollarValue) / Number(targetPurchasePrice)) * 100,
        ) / 100,
        true,
      );
    }
  };

  return (
    <Stack gap={4}>
      <InputToggle
        label="Real Estate Included?"
        checked={isIncluded}
        onChange={(event) => {
          setIncluded(
            (event as ChangeEvent<HTMLInputElement>).currentTarget.checked,
          );

          onChangeFormat("dollar");
        }}
      />
      {isIncluded && (
        <>
          <InputDollarPercentSwitch
            value={isDollarFormat}
            onChange={onChangeFormat}
          />
          {!isDollarFormat && (
            <>
              <InputPercent
                label={"Real Estate Value"}
                value={numberToPercentInput(realEstatePercentValue)}
                onChange={(value) =>
                  setRealEstatePercentValue(percentInputToNumber(value))
                }
              />

              <InputPercent
                label={"Business Value"}
                value={numberToPercentInput(realEstateBusinessPercentValue)}
                onChange={(value) =>
                  setRealEstateBusinessPercentValue(percentInputToNumber(value))
                }
              />
            </>
          )}

          {isDollarFormat && (
            <>
              <InputDollar
                label={"Real Estate Value"}
                value={Number(realEstateDollarValue)}
                onChange={(value) => {
                  const realEstateDollar = toNumberOrNull(value) || 0;
                  setRealEstateDollarValue(realEstateDollar);
                  if (realEstateDollar > Number(targetPurchasePrice)) {
                    setRealEstateBusinessDollarValue(0);
                    return;
                  }
                  setRealEstateBusinessDollarValue(
                    Math.round(Number(targetPurchasePrice) - realEstateDollar),
                  );
                }}
              />

              <InputDollar
                label={"Business Value"}
                value={Number(realEstateBusinessDollarValue)}
                onChange={(value) => {
                  const buisnessDollar = toNumberOrNull(value) || 0;
                  setRealEstateBusinessDollarValue(buisnessDollar);
                  if (buisnessDollar > Number(targetPurchasePrice)) {
                    setRealEstateDollarValue(0);
                    return;
                  }
                  setRealEstateDollarValue(
                    Math.round(Number(targetPurchasePrice) - buisnessDollar),
                  );
                }}
              />
            </>
          )}

          {realEstateErrorMessage && (
            <Alert variant="light" color="red" ta={"center"}>
              {realEstateErrorMessage}
            </Alert>
          )}
        </>
      )}
    </Stack>
  );
};

export const ModuleRealEstate = {
  key: "Real Estate",
  icon: <TbHome />,
  control: "Real Estate",
  panel: <Panel />,
  errorAtom: isRealEstateInErrorAtom,
};
