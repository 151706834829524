import { annualDebtSellersNoteAtom, dscrAtom } from "@/financeModels/liteAtoms";
import { toNumberOrNull } from "@/utils/math";
import {
  Box,
  Button,
  Group,
  Modal,
  NumberFormatter,
  Stack,
  Text,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useAtom } from "jotai";
import { IoStatsChartSharp } from "react-icons/io5";
import { Stats } from "./Stats";

export const MobileStats = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const [annualDebtSellersNote] = useAtom(annualDebtSellersNoteAtom);
  const [dscr] = useAtom(dscrAtom);

  return (
    <>
      <Box
        style={{
          position: "sticky",
          bottom: 0,
          top: 0,
          zIndex: 100,
          borderLeft: "4px solid var(--mantine-color-infomatic-2)",
          borderTop: "1px solid var(--mantine-color-infomatic-2)",
          borderBottom: "1px solid var(--mantine-color-infomatic-2)",
          borderRight: "1px solid var(--mantine-color-infomatic-2)",
        }}
        bg={"infomatic.0"}
        w="100%"
        hiddenFrom="sm"
        mb="lg"
        p="md"
      >
        <Group justify="space-between">
          <Group gap="lg">
            <Stack gap={4}>
              <Text fw="bold">Annual Debt</Text>
              <Text inherit fz="xl" fw="bold" c="red">
                <NumberFormatter
                  thousandSeparator
                  prefix="$"
                  decimalScale={0}
                  value={toNumberOrNull(annualDebtSellersNote) ?? 0}
                />
              </Text>
            </Stack>
            <Stack gap={4}>
              <Text fw="bold">DSCR</Text>
              <Text inherit fz="xl" fw="bold" c="black">
                {(toNumberOrNull(dscr) ?? 0).toFixed(2)}x
              </Text>
            </Stack>
          </Group>
          <Button onClick={open} size="md" px={"xs"}>
            <IoStatsChartSharp style={{ marginRight: 8 }} /> See all stats
          </Button>
        </Group>
      </Box>{" "}
      <Modal
        opened={opened}
        onClose={close}
        withCloseButton={false}
        size="auto"
        padding={0}
      >
        <Box onClick={close}>
          <Box p="md" w="90vw" style={{ overflow: "hidden" }}>
            <Stats />
          </Box>
        </Box>
      </Modal>
    </>
  );
};
