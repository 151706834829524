import { Getter, PrimitiveAtom, Setter, atom } from "jotai";
import { createAggregatedDollarItemizationAtom } from "./itemization";
import { createAggregatedIterationAtom } from "./iterations";

import { grossRevenueItemization as lbGrossRevenueItemization } from "./lookback";
import { grossRevenueItemization as pjGrossRevenueItemization } from "./projections";

import { costOfGoodsSoldItemization as lbCostOfGoodsSoldItemization } from "./lookback";
import { costOfGoodsSoldItemization as pjCostOfGoodsSoldItemization } from "./projections";

import { operatingExpensesItemization as lbOperatingExpensesItemization } from "./lookback";
import { operatingExpensesItemization as pjOperatingExpensesItemization } from "./projections";

import { CellTypes } from "./types";

export const isCloningFromEpochsAtom = atom(false);

const clonePairs = [
  [lbGrossRevenueItemization, pjGrossRevenueItemization],
  [lbCostOfGoodsSoldItemization, pjCostOfGoodsSoldItemization],
  [lbOperatingExpensesItemization, pjOperatingExpensesItemization],
];

export const cloneFromEpochsAtom = atom<null, [], void>(null, (get, set) => {
  set(isCloningFromEpochsAtom, true);
  setTimeout(() => {
    for (let i = 0; i < clonePairs.length; i++) {
      cloneItemization(get, set, clonePairs[i][0], clonePairs[i][1]);
    }
    set(isCloningFromEpochsAtom, false);
  }, 40);
});

export const cloneItemization = (
  get: Getter,
  set: Setter,
  epochItemizationAtom: ReturnType<
    typeof createAggregatedDollarItemizationAtom
  >,
  growthItemizationAtom: ReturnType<typeof createAggregatedIterationAtom>,
) => {
  const consolidatedLastValue =
    get(
      get(get(epochItemizationAtom).consolidatedMolecule).at(
        -1,
      ) as PrimitiveAtom<CellTypes>,
    )?.value || null;

  const consolidatedFirstAtom = get(
    get(growthItemizationAtom).consolidatedMolecule,
  ).at(0);

  if (!consolidatedFirstAtom) return;

  set(consolidatedFirstAtom, (prev) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return { ...prev, value: consolidatedLastValue as any };
  });

  const epoch = get(get(epochItemizationAtom).itemizationFocusAtom);
  const growth = get(get(growthItemizationAtom).itemizationFocusAtom);

  set(get(growthItemizationAtom).itemizationFocusAtom, {
    ...growth,
    active: epoch.active,
    lineItems: epoch.lineItems.map((lineItem) => {
      return {
        ...lineItem,
        id: Math.random().toString(36).slice(-5),
      };
    }),
  });

  for (let i = 0; i < epoch.lineItems.length; i++) {
    const value = get(
      get(get(epochItemizationAtom).lineItemsMolecules[i]).at(
        -1,
      ) as PrimitiveAtom<CellTypes>,
    ).value;

    set(
      get(get(growthItemizationAtom).lineItemsMolecules[i]).at(
        0,
      ) as PrimitiveAtom<CellTypes>,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (c) => ({ ...c, value: value as any }),
    );
  }
};
