import { hasFinishedLoadingModelOnMountAtom } from "@/financeModels/owasco/save";
import { ZIndexOrder } from "@/theme";
import { LoadingOverlay } from "@mantine/core";
import { useAtomValue } from "jotai";

export const Loader = () => {
  const hasFinishedLoadingModelOnMount = useAtomValue(
    hasFinishedLoadingModelOnMountAtom,
  );

  return (
    <LoadingOverlay
      visible={!hasFinishedLoadingModelOnMount}
      zIndex={ZIndexOrder.ModelOnPageLoaderSpinner}
      overlayProps={{ radius: "sm", blur: 2 }}
    />
  );
};
