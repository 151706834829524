import { ZIndexOrder } from "@/theme";
import { Box } from "@mantine/core";

export const NotAvailableOnMobile = () => {
  return (
    <Box
      pos="fixed"
      left={0}
      top={0}
      bg="gray.3"
      w="100dvw"
      h="100dvh"
      style={{ zIndex: ZIndexOrder.NotAvailableOnMobileWarning }}
      hiddenFrom="xs"
      p={"xl"}
      ta={"center"}
    >
      The ETA IQ app only works on desktop and tablets right now. Please use a
      larger device to access the app.
    </Box>
  );
};
