import {
  annualMaintenanceCapExMolecule,
  buyersCompMolecule,
  companysTaxRateMolecule,
  costOfGoodsSoldItemization,
  depreciationAndAmortizationMolecule,
  distributableFreeCashFlowMolecule,
  ebitdaMarginsMolecule,
  ebitdaMolecule,
  grossMarginsMolecule,
  grossProfitMolecule,
  grossRevenueItemization,
  grossRevenueYoYGrowth,
  incomeTaxesMolecule,
  lineOfCreditEndBalanceMolecule,
  lineOfCreditInterestPaymentMolecule,
  lineOfCreditPaymentMolecule,
  lineOfCreditPrincipalPaymentMolecule,
  lineOfCreditStartBalanceMolecule,
  netIncomeMolecule,
  operatingExpensesItemization,
  operatingIncomeMolecule,
  sba7aLoanExpenseEndingBalanceMolecule,
  sba7aLoanExpenseExtraPaymentsMolecule,
  sba7aLoanExpenseInterestMolecule,
  sba7aLoanExpensePrincipalMolecule,
  sba7aLoanExpenseStartingBalanceMolecule,
  sba7aLoanPaymentMolecule,
  sellersNoteExpenseEndingBalanceMolecule,
  sellersNoteExpenseExtraPaymentsMolecule,
  sellersNoteExpenseInterestMolecule,
  sellersNoteExpensePrincipalMolecule,
  sellersNoteExpenseStartingBalanceMolecule,
  sellersNoteLoanPaymentMolecule,
  taxableIncomeMolecule,
  totalDebtServiceMolecule,
  unleveredFreeCashFlowMolecule,
} from "@/financeModels/owasco/projections";
import { Badge, Divider } from "@mantine/core";
import { CellRow } from "../CellGrid/CellRow";
import { ComputedLabel } from "../CellGrid/ComputedLabel";
import { Grid } from "../CellGrid/Grid";
import { Itemization } from "../CellGrid/Itemization";
import { TitleRow } from "../CellGrid/TitleRow";
import { Waterfall } from "../CellGrid/Waterfall";
import { IterationRow } from "./IterationRow";

export const DataGrid = () => {
  let tabYIndex = 0;

  return (
    <Grid w={"100%"} h={"100%"}>
      <IterationRow />
      <TitleRow>Company Financials</TitleRow>
      <Waterfall>
        <Itemization
          itemizationAtom={grossRevenueItemization}
          label={<Waterfall.Dot variant="blank">Gross Revenue</Waterfall.Dot>}
          tabY={tabYIndex++}
        />
        <CellRow
          molecule={grossRevenueYoYGrowth}
          label={
            <ComputedLabel
              tooltip={
                <>
                  = <Badge color="blue">Gross Revenue</Badge>/
                  <Badge color="blue">Previous Gross Revenue</Badge> - 1
                </>
              }
            >
              Gross Revenue YoY Growth
            </ComputedLabel>
          }
          tabY={tabYIndex++}
          labelWidth={220}
          variant="transparentWaterfall"
        />
        <Itemization
          itemizationAtom={costOfGoodsSoldItemization}
          label={
            <Waterfall.Dot variant="minus">
              Cost of Goods Sold (COGS)
            </Waterfall.Dot>
          }
          tabY={tabYIndex++}
        />
        <CellRow
          molecule={grossProfitMolecule}
          label={<Waterfall.Formula>Gross Profit</Waterfall.Formula>}
          tabY={tabYIndex++}
        />
      </Waterfall>
      <CellRow
        molecule={grossMarginsMolecule}
        label={
          <ComputedLabel
            tooltip={
              <>
                = <Badge color="blue">Gross Profit</Badge>/
                <Badge color="blue">Cost Of Goods</Badge>
              </>
            }
          >
            Gross Margins
          </ComputedLabel>
        }
        tabY={tabYIndex++}
        labelWidth={140}
        variant="transparent"
      />
      <Divider my="xs" />
      <Waterfall>
        <Waterfall.Stub>
          <Waterfall.Dot variant="blank">
            <Waterfall.Pill>Gross Profit</Waterfall.Pill>
          </Waterfall.Dot>
        </Waterfall.Stub>

        <Itemization
          itemizationAtom={operatingExpensesItemization}
          label={
            <Waterfall.Dot variant="minus">Operating Expenses</Waterfall.Dot>
          }
          tabY={tabYIndex++}
        />

        <CellRow
          molecule={buyersCompMolecule}
          label={
            <Waterfall.Dot variant="minus">Buyer's Compensation</Waterfall.Dot>
          }
          tabY={tabYIndex++}
        />

        <CellRow
          molecule={ebitdaMolecule}
          label={<Waterfall.Formula>EBITDA</Waterfall.Formula>}
          tabY={tabYIndex++}
        />
      </Waterfall>
      <CellRow
        molecule={ebitdaMarginsMolecule}
        label={
          <ComputedLabel
            tooltip={
              <>
                = <Badge color="blue">EBITDA</Badge>/
                <Badge color="blue">Gross Revenue</Badge>
              </>
            }
          >
            EBITDA Margins
          </ComputedLabel>
        }
        tabY={tabYIndex++}
        labelWidth={150}
        variant="transparent"
      />
      <Divider my="xs" />
      <Waterfall>
        <Waterfall.Stub>
          <Waterfall.Dot variant="blank">
            <Waterfall.Pill>EBITDA</Waterfall.Pill>
          </Waterfall.Dot>
        </Waterfall.Stub>
        <CellRow
          molecule={depreciationAndAmortizationMolecule}
          label={
            <Waterfall.Dot variant="minus">
              Depreciation & Amortization
            </Waterfall.Dot>
          }
          tabY={tabYIndex++}
        />
        <CellRow
          molecule={operatingIncomeMolecule}
          label={<Waterfall.Formula>Operating Income</Waterfall.Formula>}
          tabY={tabYIndex++}
        />
      </Waterfall>
      <Divider my="xs" />
      <Waterfall>
        <Waterfall.Stub>
          <Waterfall.Dot variant="blank">
            <Waterfall.Pill>Operating Income</Waterfall.Pill>
          </Waterfall.Dot>
        </Waterfall.Stub>

        <Waterfall.Collapse
          title={"Income Taxes"}
          tabY={tabYIndex++}
          molecule={incomeTaxesMolecule}
          offsetResult
          dotVariant="minus"
        >
          <Waterfall.Stub>
            <Waterfall.Dot variant="blank">
              <Waterfall.Pill>Operating Income</Waterfall.Pill>
            </Waterfall.Dot>
          </Waterfall.Stub>

          <CellRow
            tabY={tabYIndex++}
            molecule={annualMaintenanceCapExMolecule}
            label={
              <Waterfall.Dot variant="minus">
                Annual Maintenance CapEx
              </Waterfall.Dot>
            }
          />

          <CellRow
            tabY={tabYIndex++}
            molecule={sba7aLoanExpenseInterestMolecule}
            label={
              <Waterfall.Dot variant="minus">
                Interest Expense - SBA 7(a) Loan
              </Waterfall.Dot>
            }
          />
          <CellRow
            tabY={tabYIndex++}
            molecule={sellersNoteExpenseInterestMolecule}
            label={
              <Waterfall.Dot variant="minus">
                Interest Expense - Sellers Note
              </Waterfall.Dot>
            }
          />
          <CellRow
            tabY={tabYIndex++}
            molecule={taxableIncomeMolecule}
            label={<Waterfall.Formula>Taxable Income</Waterfall.Formula>}
          />
          <CellRow
            tabY={tabYIndex++}
            molecule={companysTaxRateMolecule}
            label={
              <Waterfall.Dot variant="multiply">
                Company's Tax Rate
              </Waterfall.Dot>
            }
          />
        </Waterfall.Collapse>

        <CellRow
          molecule={sba7aLoanExpenseInterestMolecule}
          label={
            <Waterfall.Dot variant="minus">
              Interest Expense - SBA 7(a) Loan
            </Waterfall.Dot>
          }
          tabY={tabYIndex++}
        />
        <CellRow
          molecule={sellersNoteExpenseInterestMolecule}
          label={
            <Waterfall.Dot variant="minus">
              Interest Expense - Sellers Note
            </Waterfall.Dot>
          }
          tabY={tabYIndex++}
        />

        <CellRow
          molecule={netIncomeMolecule}
          label={<Waterfall.Formula>Net Income</Waterfall.Formula>}
          tabY={tabYIndex++}
        />
      </Waterfall>

      <Divider my="xs" />

      <TitleRow>Debt Service</TitleRow>

      <Waterfall>
        <Waterfall.Collapse
          title={"Senior Debt - SBA 7(a) Loan"}
          tabY={tabYIndex++}
          molecule={sba7aLoanPaymentMolecule}
          dotVariant="add"
          offsetResult
          cap
        >
          <Waterfall.Stub>
            <Waterfall.Dot variant="hollow">
              <Waterfall.Pill>Principal & Interest Payments</Waterfall.Pill>
            </Waterfall.Dot>
          </Waterfall.Stub>
          <CellRow
            molecule={sba7aLoanExpenseStartingBalanceMolecule}
            label={"SBA 7(a) - Beginning Balance"}
            tabY={tabYIndex++}
            variant="transparentWaterfall"
            labelWidth={240}
          />
          <CellRow
            molecule={sba7aLoanExpenseInterestMolecule}
            label={
              <Waterfall.Dot variant="add">
                SBA 7(a) - Interest Payment
              </Waterfall.Dot>
            }
            tabY={tabYIndex++}
          />
          <CellRow
            molecule={sba7aLoanExpensePrincipalMolecule}
            label={
              <Waterfall.Dot variant="add">
                SBA 7(a) - Principal Balance
              </Waterfall.Dot>
            }
            tabY={tabYIndex++}
          />
          <CellRow
            molecule={sba7aLoanExpenseExtraPaymentsMolecule}
            label={
              <Waterfall.Dot variant="add">
                SBA 7(a) - Extra Payments
              </Waterfall.Dot>
            }
            tabY={tabYIndex++}
          />
          <CellRow
            molecule={sba7aLoanExpenseEndingBalanceMolecule}
            label={"SBA 7(a) - Ending Balance"}
            tabY={tabYIndex++}
            variant="transparentWaterfall"
            labelWidth={220}
          />
        </Waterfall.Collapse>
        <Waterfall.Collapse
          title={
            <>
              Line Of Credit{" "}
              <span
                style={{
                  fontSize: 11,
                  paddingTop: 2,
                  verticalAlign: "bottom",
                }}
              >
                (Working Capital)
              </span>
            </>
          }
          tabY={tabYIndex++}
          molecule={lineOfCreditPaymentMolecule}
          dotVariant="add"
          offsetResult
        >
          <Waterfall.Stub>
            <Waterfall.Dot variant="hollow">
              <Waterfall.Pill>Principal & Interest Payments</Waterfall.Pill>
            </Waterfall.Dot>
          </Waterfall.Stub>
          <CellRow
            molecule={lineOfCreditStartBalanceMolecule}
            label={"Line of Credit - Beginning Balance"}
            tabY={tabYIndex++}
            variant="transparentWaterfall"
            labelWidth={250}
          />
          <CellRow
            molecule={lineOfCreditInterestPaymentMolecule}
            label={
              <Waterfall.Dot variant="add">
                Line of Credit - Interest Payment
              </Waterfall.Dot>
            }
            tabY={tabYIndex++}
          />
          <CellRow
            molecule={lineOfCreditPrincipalPaymentMolecule}
            label={
              <Waterfall.Dot variant="add">
                Line of Credit - Principal Payment
              </Waterfall.Dot>
            }
            tabY={tabYIndex++}
          />
          <CellRow
            molecule={lineOfCreditEndBalanceMolecule}
            label={"Line of Credit - Ending Balance"}
            tabY={tabYIndex++}
            variant="transparentWaterfall"
            labelWidth={230}
          />
        </Waterfall.Collapse>
        <Waterfall.Collapse
          title={"Sellers Note - Loan"}
          tabY={tabYIndex++}
          molecule={sellersNoteLoanPaymentMolecule}
          dotVariant="add"
          offsetResult
        >
          <Waterfall.Stub>
            <Waterfall.Dot variant="hollow">
              <Waterfall.Pill>Principal & Interest Payments</Waterfall.Pill>
            </Waterfall.Dot>
          </Waterfall.Stub>
          <CellRow
            molecule={sellersNoteExpenseStartingBalanceMolecule}
            label={"Sellers Note - Beginning Balance"}
            tabY={tabYIndex++}
            variant="transparentWaterfall"
            labelWidth={250}
          />
          <CellRow
            molecule={sellersNoteExpenseInterestMolecule}
            label={
              <Waterfall.Dot variant="add">
                Sellers Note - Interest Payment
              </Waterfall.Dot>
            }
            tabY={tabYIndex++}
          />
          <CellRow
            molecule={sellersNoteExpensePrincipalMolecule}
            label={
              <Waterfall.Dot variant="add">
                Sellers Note - Principal Payment
              </Waterfall.Dot>
            }
            tabY={tabYIndex++}
          />
          <CellRow
            molecule={sellersNoteExpenseExtraPaymentsMolecule}
            label={
              <Waterfall.Dot variant="add">
                Sellers Note - Extra Payments
              </Waterfall.Dot>
            }
            tabY={tabYIndex++}
          />
          <CellRow
            molecule={sellersNoteExpenseEndingBalanceMolecule}
            label={"Sellers Note - Ending Balance"}
            tabY={tabYIndex++}
            variant="transparentWaterfall"
            labelWidth={230}
          />
        </Waterfall.Collapse>
        <CellRow
          molecule={totalDebtServiceMolecule}
          label={<Waterfall.Formula>Total Debt Service</Waterfall.Formula>}
          tabY={tabYIndex++}
        />
      </Waterfall>

      <Divider my="xs" />

      <TitleRow>Owner & Equity Investor Distributions</TitleRow>

      <Waterfall.Collapse
        molecule={unleveredFreeCashFlowMolecule}
        title={"Unlevered Free Cash Flow"}
        tabY={tabYIndex++}
        offsetResult
      >
        <CellRow
          label={
            <Waterfall.Dot variant="blank">
              <Waterfall.Pill>EBITDA</Waterfall.Pill>
            </Waterfall.Dot>
          }
          tabY={tabYIndex++}
          molecule={ebitdaMolecule}
        />
        <CellRow
          label={
            <Waterfall.Dot variant="minus">
              <Waterfall.Pill>Income Taxes</Waterfall.Pill>
            </Waterfall.Dot>
          }
          tabY={tabYIndex++}
          molecule={incomeTaxesMolecule}
        />
        <CellRow
          label={
            <Waterfall.Dot variant="minus">
              Annual Maintenance CapEx
            </Waterfall.Dot>
          }
          tabY={tabYIndex++}
          molecule={annualMaintenanceCapExMolecule}
        />
      </Waterfall.Collapse>

      <Divider my="xs" />

      <Waterfall.Collapse
        molecule={distributableFreeCashFlowMolecule}
        title={"Distributable Free Cash Flow"}
        tabY={tabYIndex++}
      >
        <CellRow
          molecule={unleveredFreeCashFlowMolecule}
          label={
            <Waterfall.Dot variant="blank">
              <Waterfall.Pill>Unlevered Free Cash Flow</Waterfall.Pill>
            </Waterfall.Dot>
          }
          tabY={tabYIndex++}
        />
        <CellRow
          molecule={totalDebtServiceMolecule}
          label={
            <Waterfall.Dot variant="minus">
              <Waterfall.Pill>Total Debt Service</Waterfall.Pill>
            </Waterfall.Dot>
          }
          tabY={tabYIndex++}
        />
      </Waterfall.Collapse>

      {/* <Divider my="xs" />
      <Waterfall>
        <Waterfall.Collapse
          molecule={usableFreeCashFlowMolecule}
          title={"Useable Free Cash Flow"}
          tabY={tabYIndex++}
          offsetResult
          cap
        >
          <CellRow
            molecule={distributableFreeCashFlowMolecule}
            label={
              <Waterfall.Dot variant="blank">
                <Waterfall.Pill>Distributable Free Cash Flow</Waterfall.Pill>
              </Waterfall.Dot>
            }
            tabY={tabYIndex++}
          />
        </Waterfall.Collapse>
      </Waterfall> */}

      <Divider mb={200} />
    </Grid>
  );
};
