import {
  Box,
  Button,
  Container,
  Grid,
  Group,
  Paper,
  Stack,
  Text,
  Title,
} from "@mantine/core";

import { Route } from "@/paths";
import { Link } from "react-router-dom";
import classes from "./Jumbotron.module.css";
import { NumberInputCashFlow } from "./NumberInputCashFlow";
import { NumberInputPurchasePrice } from "./NumberInputPurchasePrice";

export const Jumbotron = ({ scrollTo }: { scrollTo: () => void }) => {
  return (
    <Box bg="styledBackgrounds.5">
      <Container
        size={"xl"}
        pt={{ sm: "18vh", base: 50 }}
        pb={{ sm: "26vh", base: 140 }}
        px={{ base: 18, sm: 40, xl: 0 }}
      >
        <Grid gutter={{ base: 12, sm: 60 }} align="center">
          <Grid.Col
            span={{ base: 12, md: 7 }}
            style={{ container: " jumbotron-text / inline-size" }}
          >
            <Title
              fw={400}
              c="white"
              fz={{ base: 38, sm: 35, md: 50, lg: 62 }}
              mb={"sm"}
              style={{ letterSpacing: 1.2 }}
            >
              <Text className={classes.wipe} inherit component="span">
                Supercharge
              </Text>
              <Text component="span" px={10} inherit>
                your
              </Text>
              <Text
                inherit
                display={{
                  base: "block",
                  sm: "inline",
                  xs: "block",
                  md: "block",
                }}
              >
                Search Fund journey
              </Text>
            </Title>
            <Text c="gray.4" fz={{ base: 16, sm: 20, md: 22 }} mb={40}>
              Unlock success in the Search Fund acquisition journey with{" "}
              <strong>ETA IQ</strong>. Our tailored toolkit and expertise
              accelerate your deal from workflow to deal flow, focusing on what
              truly matters — <strong>closing deals with confidence</strong>.
            </Text>
            <Group align="center">
              <Button component={Link} to={Route.Signup} size="lg">
                Get Started — it's free
              </Button>
            </Group>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 5 }}>
            <Stack align="center">
              <Text
                c={{ base: "transparent", sm: "gray.3" }}
                mb={"sm"}
                ta={"center"}
              >
                Gauge your deal fundamentals in seconds:
              </Text>
              <Paper radius={0} p="xl" withBorder maw={500} w={"100%"}>
                <form
                  onSubmit={(e) => {
                    // stop form submission
                    e.preventDefault();
                    scrollTo();
                  }}
                >
                  <Stack>
                    <NumberInputPurchasePrice />
                    <NumberInputCashFlow />
                    <Button fullWidth size="lg" type="submit" justify="center">
                      Analyze Your Deal
                    </Button>
                  </Stack>
                </form>
              </Paper>
            </Stack>
          </Grid.Col>
        </Grid>
      </Container>
    </Box>
  );
};
