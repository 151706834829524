import {
  equityInputDollarAtom,
  equityPercentAtom,
  financeStructureErrorMessageAtom,
  financeStructureIsDollarFormatAtom,
  isFinanceStructureInErrorAtom,
  sbaLoanInputDollarAtom,
  sbaLoanPercentAtom,
  sellersNoteInputDollarAtom,
  sellersNotePercentAtom,
  targetPurchasePriceAtom,
} from "@/financeModels/owasco/general";
import { numberToPercentInput, percentInputToNumber } from "@/utils/format";
import { toNumberOrNull } from "@/utils/math";
import { Alert, Stack } from "@mantine/core";
import { useAtom, useAtomValue } from "jotai";
import { TbReportMoney } from "react-icons/tb";
import { InputDollar } from "./InputDollar";
import { InputDollarPercentSwitch } from "./InputDollarPercentSwitch";
import { InputPercent } from "./InputPercent";

const Panel = () => {
  const [isDollarFormat, setIsDollarFormat] = useAtom(
    financeStructureIsDollarFormatAtom,
  );
  const [sbaLoanPercent, setSbaLoanPercent] = useAtom(sbaLoanPercentAtom);
  const [sellersNotePercent, setSellersNotePercent] = useAtom(
    sellersNotePercentAtom,
  );
  const [equityPercent, setEquityPercent] = useAtom(equityPercentAtom);

  const [sbaLoanDollar, setSbaLoanDollar] = useAtom(sbaLoanInputDollarAtom);
  const [sellersNoteDollar, setSellersNoteDollar] = useAtom(
    sellersNoteInputDollarAtom,
  );
  const [equityDollar, setEquityDollar] = useAtom(equityInputDollarAtom);

  const purchasePrice = useAtomValue(targetPurchasePriceAtom);

  const financeStructureErrorMessage = useAtomValue(
    financeStructureErrorMessageAtom,
  );

  const onChangeFormat = (value: string) => {
    setIsDollarFormat(value === "dollar");
    if (value === "dollar") {
      setSbaLoanDollar(
        Number(sbaLoanPercent) * Number(purchasePrice) || 0,
        true,
      );
      setSellersNoteDollar(
        Number(sellersNotePercent) * Number(purchasePrice) || 0,
        true,
      );
      setEquityDollar(Number(equityPercent) * Number(purchasePrice) || 0, true);
    } else {
      setSbaLoanPercent(Number(sbaLoanDollar) / Number(purchasePrice), true);
      setSellersNotePercent(
        Number(sellersNoteDollar) / Number(purchasePrice),
        true,
      );
      setEquityPercent(Number(equityDollar) / Number(purchasePrice), true);
    }
  };

  return (
    <Stack gap={4}>
      <InputDollarPercentSwitch
        value={isDollarFormat}
        onChange={onChangeFormat}
      />

      {!isDollarFormat && (
        <>
          <InputPercent
            label={"SBA 7(a) Loan"}
            value={numberToPercentInput(sbaLoanPercent)}
            onChange={(value) => setSbaLoanPercent(percentInputToNumber(value))}
          />

          <InputPercent
            label={"Seller's Note"}
            value={numberToPercentInput(sellersNotePercent)}
            onChange={(value) =>
              setSellersNotePercent(percentInputToNumber(value))
            }
          />
          <InputPercent
            label={"Equity"}
            value={numberToPercentInput(equityPercent)}
            onChange={(value) => setEquityPercent(percentInputToNumber(value))}
          />
        </>
      )}

      {isDollarFormat && (
        <>
          <InputDollar
            label={"SBA 7(a) Loan"}
            value={Number(sbaLoanDollar)}
            onChange={(value) => setSbaLoanDollar(toNumberOrNull(value))}
          />

          <InputDollar
            label={"Seller's Note"}
            value={Number(sellersNoteDollar)}
            onChange={(value) => setSellersNoteDollar(toNumberOrNull(value))}
          />
          <InputDollar
            label={"Equity"}
            value={Number(equityDollar)}
            onChange={(value) => setEquityDollar(toNumberOrNull(value))}
          />
        </>
      )}

      {financeStructureErrorMessage && (
        <Alert variant="light" color="red" ta={"center"}>
          {financeStructureErrorMessage}
        </Alert>
      )}
    </Stack>
  );
};

export const ModuleFinanceStructure = {
  key: "Finance Structure",
  icon: <TbReportMoney />,
  control: "Finance Structure",
  panel: <Panel />,
  errorAtom: isFinanceStructureInErrorAtom,
};
