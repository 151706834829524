import { DeferredRender } from "@/components/DeferedRender/DeferedRender";
import { CellTypes } from "@/financeModels/owasco/types";
import { isBetween } from "@/utils/math";
import { Badge, BoxComponentProps, Group, Stack, Tooltip } from "@mantine/core";
import { Atom, useAtomValue } from "jotai";
import { ReactNode, memo } from "react";
import { defaultHeight } from "../CellGrid/cell/Wrapper";

export const DSCRCell = memo(
  ({
    children,
    dscrAtom,
    ...props
  }: BoxComponentProps & {
    children: ReactNode;
    dscrAtom: Atom<CellTypes>;
  }) => {
    const dscr = useAtomValue(dscrAtom);

    const showDscr =
      dscr !== null &&
      dscr !== undefined &&
      Number(dscr.value) > 0 &&
      Number(dscr.value) < 99;

    const dscrLabel = !showDscr ? "---" : Number(dscr.value).toFixed(2) + "x";

    let dscrBgColor = "gray.6";
    const dscrTooltipMessage =
      "Debt Service Coverage Ratio (DSCR) is a metric used to assess a company's ability to generate enough cash flow to cover its debt obligations. Some lenders will accept a DSCR between 1.25x - 1.50x, but most require at least 1.50x.";

    if (isBetween(dscr.value, 0.01, 1.25)) {
      dscrBgColor = "red.5";
    }
    if (isBetween(dscr.value, 1.25, 1.5)) {
      dscrBgColor = "yellow.8";
    }

    if (isBetween(dscr.value, 1.5, 20)) {
      dscrBgColor = "green.5";
    }

    return (
      <Group
        gap={0}
        h={defaultHeight}
        justify="space-between"
        align="center"
        style={{
          backgroundColor: "white",
          backgroundImage: `linear-gradient(#aaa,#aaa)`,
          backgroundSize: "1px 30%",
          backgroundPosition: "right bottom",
          backgroundRepeat: "no-repeat",
          borderTopLeftRadius: 0,
          borderTopRightRadius: 13,
        }}
        pr={4}
        pl={6}
        {...props}
      >
        <div>{children}</div>
        <DeferredRender>
          <Tooltip
            events={{ hover: true, focus: true, touch: true }}
            withArrow
            multiline
            w={300}
            position="bottom"
            label={dscrTooltipMessage}
          >
            <Badge
              color={dscrBgColor}
              size="lg"
              tt={"none"}
              fz={12}
              h={30}
              style={{
                borderRadius: 13,
                borderBottomRightRadius: 0,
                borderBottomLeftRadius: 4,
                borderTopLeftRadius: 4,
                cursor: "help",
              }}
            >
              <Stack gap={1} lh={1} justify="center" align="center">
                <small style={{ fontSize: 13, letterSpacing: 0.9 }}>
                  {dscrLabel}
                </small>
                <small style={{ fontSize: 10, letterSpacing: 1 }}>DSCR</small>
              </Stack>
            </Badge>
          </Tooltip>
        </DeferredRender>
      </Group>
    );
  },
);
