import TooltipCashFlow from "@/docs/tooltips/cash-flow.mdx";
import {
  cashFlowAtom,
  getPurchaseMultiple,
  listingPriceAtom,
  targetPurchaseMultipleAtom,
} from "@/financeModels/liteAtoms";
import { useAtom, useSetAtom } from "jotai";
import { BigDollarInput } from "../BigDollarInput/BigDollarInput";
import { QuestionTooltip } from "../QuestionTooltip/QuestionTooltip";
import { LabelExplainer } from "./LabelExplainer";

export const NumberInputCashFlow = ({
  showExplanation,
}: {
  showExplanation?: boolean;
}) => {
  const [listingPrice] = useAtom(listingPriceAtom);
  const [cashFlow, setCashFlow] = useAtom(cashFlowAtom);
  const setTargetPurchaseMultiple = useSetAtom(targetPurchaseMultipleAtom);

  return (
    <BigDollarInput
      value={typeof cashFlow === "number" ? cashFlow : undefined}
      onChange={(value) => {
        const newCashFlow = typeof value === "number" ? value : null;
        setCashFlow(newCashFlow);
        setTargetPurchaseMultiple(
          getPurchaseMultiple({
            price: listingPrice,
            cashFlow: newCashFlow,
          }),
        );
      }}
      label={
        <>
          {showExplanation && (
            <LabelExplainer
              label={"Cash Flow (SDE)"}
              explanation={<TooltipCashFlow />}
            />
          )}
          {!showExplanation && (
            <QuestionTooltip
              label={
                <>
                  Cash flow or Seller’s Discretionary Earnings refers to the
                  <br />
                  cash flow generated by a business before deducting owner's
                  <br />
                  discretionary expenses and other financial adjustments.
                </>
              }
            >
              Cash Flow (SDE)
            </QuestionTooltip>
          )}{" "}
          :
        </>
      }
      placeholder="$"
    />
  );
};
