import { atom } from "jotai";
import { atomEffect } from "jotai-effect";
import { isInputDebouncingAtom } from "./general";
import { isSavingAtom } from "./save";

export const isWorkingAtom = atom((get) => {
  return get(isSavingAtom) || get(isInputDebouncingAtom);
});

export const confirmOnCloseAtom = atom((get) => {
  get(confirmOnCloseEffect);
  return get(isWorkingAtom);
});

const beforeLoadFn = (e: BeforeUnloadEvent) => {
  e.preventDefault();
  return "Currently saving. Are you sure you want to leave?";
};

const confirmOnCloseEffect = atomEffect((get) => {
  const isWorking = get(isWorkingAtom);
  if (isWorking) window.addEventListener("beforeunload", beforeLoadFn);
  else window.removeEventListener("beforeunload", beforeLoadFn);

  return () => {
    window.removeEventListener("beforeunload", beforeLoadFn);
  };
});
