import { Route } from "@/paths";
import { Anchor, Box, Group, Stack, Text } from "@mantine/core";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { Logo } from "../Logo/Logo";

export const LayoutFullscreen = ({ children }: { children: ReactNode }) => {
  return (
    <Stack
      w="100dvw"
      h="100dvh"
      justify="center"
      align="center"
      bg={"gray.0"}
      pos={"relative"}
    >
      <Group pos={"absolute"} top={0} w={"100%"} p={"lg"}>
        <Logo width={70} variant="light" bg="gray.5" p="xs" />
      </Group>

      <Box>{children}</Box>
      <Group
        pos={"absolute"}
        bottom={0}
        w={"100%"}
        justify="space-between"
        p={"lg"}
      >
        <Text c="gray.5" size="xs">
          ETA IQ &copy; {new Date().getFullYear()}
        </Text>
        <Group>
          <Anchor
            size="xs"
            c="gray.5"
            component={Link}
            to={Route.PrivacyPolicy}
          >
            Privacy Policy
          </Anchor>
          <Anchor
            size="xs"
            c="gray.5"
            component={Link}
            to={Route.TermOfService}
          >
            Terms of Service
          </Anchor>
        </Group>
      </Group>
    </Stack>
  );
};
