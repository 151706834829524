import { useInViewport } from "@mantine/hooks";
import { ReactNode } from "react";
import { defaultHeight } from "../PageModel/Main/CellGrid/cell/Wrapper";

export const RenderWhenInView = ({
  children,
  height = defaultHeight,
}: {
  children: ReactNode;
  height?: number;
}) => {
  const { ref, inViewport } = useInViewport();
  return (
    <div ref={ref} style={{ height }}>
      {inViewport && children}
    </div>
  );
};
