import { focusAtom } from "jotai-optics";
import { atomFamily } from "jotai/utils";
import { modelAtom } from "./model";
import { Cell, CellTypes } from "./types";

const cache = atomFamily(
  ({ cell, id }: { id: string; cell: Cell }) =>
    focusAtom(modelAtom, (optic) =>
      optic
        .prop("cells")
        .prop(id)
        .valueOr(cell as CellTypes),
    ),
  (a, b) => a.id === b.id,
);

export const createStorableCellAtom = (id: string, cell: CellTypes) => {
  return cache({ id, cell });
};
