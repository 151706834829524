import { MathEvaluteError } from "@/financeModels/types";
import { ipmt, pmt } from "@travishorn/finance";
import { bankersRound } from "bankers-round";
import { EvalFunction, all, create, factory } from "mathjs/number";

export const math = create(all);

const expressionCache: Record<string, EvalFunction> = {};

// import the function into math.js
math.import({
  ipmt: factory("impt", [], function () {
    return ipmt;
  }),
  pmt: factory("pmt", [], function () {
    return pmt;
  }),
  bankRound: factory("bankRound", [], function () {
    return (value: number) => bankersRound(value, 2);
  }),
});

export function evaluteWithErrors<Type>(
  expression: string,
  scope: {
    [key: string]: string | number | Error | null;
  } = {},
): Type | MathEvaluteError {
  let result;

  //scope object convert empty string to null
  for (const key in scope) {
    if (
      scope[key] === "" ||
      scope[key] === null ||
      scope[key] instanceof Error
    ) {
      return new MathEvaluteError(
        `Empty string, null or error value in scope '${key}'`,
      );
    }
  }

  try {
    expressionCache[expression] =
      expressionCache[expression] || math.parse(expression);

    result = expressionCache[expression].evaluate(scope);
  } catch (error) {
    return new MathEvaluteError((error as Error).message);
  }

  if (math.isNumeric(result)) {
    return parseFloat(Number(result).toPrecision(14)) as Type;
  }

  return new MathEvaluteError("Result is not a number.");
}

export const isNumber = (value: unknown): value is number => {
  return typeof value === "number" && !isNaN(value) && isFinite(value);
};

export const toNumberOrNull = (value: unknown): number | null => {
  return isNumber(value) ? value : null;
};

export const isBetween = (
  value: unknown,
  min: number,
  max: number,
): boolean => {
  if (!isNumber(value)) return false;

  return value >= min && value <= max;
};
