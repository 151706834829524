// This fixes issue where going from 10000 to 00000 would clear the input
// https://github.com/mantinedev/mantine/issues/4901

import { MathResult } from "@/financeModels/types";
import { isNumber } from "@/utils/math";
import { useEffect, useState } from "react";

export const useNumberInputValueFix = (numberInputValue: MathResult) => {
  const [textValue, setTextValue] = useState("");

  useEffect(() => {
    if (
      numberInputValue !== null &&
      (isNumber(numberInputValue) ||
        (numberInputValue || "")?.toString().length > 0)
    ) {
      setTextValue(numberInputValue.toString());
    }
  }, [numberInputValue]);

  const onChange = (value: string | number) => {
    if (value === 0 && textValue.length > 3) {
      setTextValue(textValue.replace(/[^0]/g, ""));
    } else {
      setTextValue(value.toString());
    }
  };

  return { textValue, setTextValue, onChange };
};
