import { Box, BoxComponentProps, Group, Input, Paper } from "@mantine/core";
import { ReactNode } from "react";

export const ComputedResult = ({
  label,
  value,
  variant = "light",
  ...props
}: BoxComponentProps & {
  label: ReactNode;
  value: ReactNode;
  variant?: "light" | "default";
}) => {
  if (variant === "default")
    return (
      <Group my="sm" justify="space-between" {...props}>
        <Input.Label component={"p"} size="sm" m={0} mb={0}>
          {label}
        </Input.Label>
        <Box w={120}>
          <Input.Label component={"p"} size="sm" m={0} mb={0}>
            {value}
          </Input.Label>
        </Box>
      </Group>
    );

  return (
    <Paper mt={"xs"} bg={"gray.1"} px="sm" py="sm" withBorder {...props}>
      <Group justify="space-between">
        <Input.Label component={"p"} size="sm" m={0} mb={0}>
          {label}
        </Input.Label>
        <Box w={110}>
          <Input.Label component={"p"} size="sm" m={0} p={0} mb={0}>
            {value}
          </Input.Label>
        </Box>
      </Group>
    </Paper>
  );
};
