import { parseTextToCells } from "@/utils/clipboard";
import { atom, useSetAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { useCallback, useEffect, useMemo } from "react";

type TabCoordinateItem = {
  id: string;
  tabX: number;
  tabY: number;
  isFullWidth?: boolean;
  onPaste?: (value: string) => void;
  onCopy?: () => string;
};

const tabCoordinatesAtom = atom<TabCoordinateItem[]>([]);
const previousTabCoordinates = atom<TabCoordinateItem | null>(null);

export const useTabCoordinates = (tabCoord: Omit<TabCoordinateItem, "id">) => {
  const setTabCoordinates = useSetAtom(tabCoordinatesAtom);

  const id = useMemo(
    () =>
      `tab-coord-${tabCoord.tabX}-${tabCoord.tabY}-${Math.random()
        .toString()
        .slice(2, 8)}`.replace(".", "dot"),
    [tabCoord.tabX, tabCoord.tabY],
  );

  const currTabCoord: TabCoordinateItem = useMemo(
    () => ({
      ...tabCoord,
      id,
    }),
    [tabCoord, id],
  );

  useEffect(() => {
    setTabCoordinates((prev) => [...prev, currTabCoord]);

    return () => {
      setTabCoordinates((prev) => prev.filter((tab) => tab.id !== id));
    };
  }, []);

  const getTabByDir = useAtomCallback(
    useCallback(
      (
        get,
        _set,
        initTabCoord: TabCoordinateItem,
        dir: "up" | "down" | "left" | "right",
      ) => {
        const tabCoordinates = get(tabCoordinatesAtom);
        let candidates: TabCoordinateItem[] = [];

        if (dir === "right") {
          candidates = tabCoordinates
            .filter(
              (tab) =>
                tab.tabX > initTabCoord.tabX && tab.tabY === initTabCoord.tabY,
            )
            .sort((a, b) => a.tabX - b.tabX);
        }

        if (dir === "left") {
          candidates = tabCoordinates
            .filter(
              (tab) =>
                tab.tabX < initTabCoord.tabX && tab.tabY === initTabCoord.tabY,
            )
            .sort((a, b) => b.tabX - a.tabX);
        }

        const searchX = !initTabCoord.isFullWidth
          ? initTabCoord.tabX
          : get(previousTabCoordinates)?.tabX;

        if (dir === "down") {
          candidates = tabCoordinates
            .filter(
              (tab) =>
                tab.tabY > initTabCoord.tabY &&
                (tab.tabX === searchX || tab.isFullWidth),
            )
            .sort((a, b) => a.tabY - b.tabY);
        }

        if (dir === "up") {
          candidates = tabCoordinates
            .filter(
              (tab) =>
                tab.tabY < initTabCoord.tabY &&
                (tab.tabX === searchX || tab.isFullWidth),
            )
            .sort((a, b) => b.tabY - a.tabY);
        }

        return candidates;
      },
      [],
    ),
  );

  const moveFocus = useAtomCallback(
    useCallback(
      (_get, set, dir: "up" | "down" | "left" | "right") => {
        const candidates = getTabByDir(currTabCoord, dir);
        if (candidates.length === 0) return;

        // get first element from canditates
        for (const candidate of candidates) {
          const ele = document.getElementById(candidate.id);
          if (!ele) continue;

          getFocusableElement(ele)?.focus();
          set(previousTabCoordinates, currTabCoord);
          break;
        }
      },
      [currTabCoord, getTabByDir],
    ),
  );

  useEffect(() => {
    const handlePaste = (e: ClipboardEvent) => {
      const id = document?.activeElement?.parentElement?.id;
      if (id !== currTabCoord.id) return;

      const text = e.clipboardData?.getData("text") || "";
      const cells = parseTextToCells(text);
      let rowCoord: TabCoordinateItem = currTabCoord;
      let colCoord: TabCoordinateItem = currTabCoord;

      if (!rowCoord) return;

      cells.forEach((row) => {
        row.forEach((cell) => {
          if (!colCoord) return;
          colCoord.onPaste?.(cell);
          colCoord = getTabByDir(colCoord, "right")[0];
        });

        if (!rowCoord) return;
        rowCoord = getTabByDir(rowCoord, "down")[0];
        colCoord = rowCoord;
      });
    };

    document.addEventListener("paste", handlePaste);

    return () => document.removeEventListener("paste", handlePaste);
  }, [currTabCoord, currTabCoord.id, getTabByDir, tabCoord]);

  useEffect(() => {
    const handleCopy = () => {
      const id = document?.activeElement?.parentElement?.id;
      if (id !== currTabCoord.id) return;

      navigator.clipboard.writeText(currTabCoord?.onCopy?.() || "");
    };

    document.addEventListener("copy", handleCopy);

    return () => document.removeEventListener("copy", handleCopy);
  }, [currTabCoord, currTabCoord.id, getTabByDir, tabCoord]);

  return { id, moveFocus, getTabByDir };
};

export const getFocusableElement = (
  element: HTMLElement,
  selector: string = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
) => {
  const focusable = element.querySelectorAll(selector);
  return focusable?.[0] as HTMLElement;
};

export const selectActiveElement = () => {
  setTimeout(() => (document.activeElement as HTMLInputElement)?.select(), 50);
};
