import { sbaLoanAmortizationScheduleAtom } from "@/financeModels/owasco/amortization";
import {
  sbaLoanInterestRateAtom,
  sbaLoanStartDateAtom,
} from "@/financeModels/owasco/general";
import { numberToPercentInput, percentInputToNumber } from "@/utils/format";
import { Stack } from "@mantine/core";
import { Timestamp } from "firebase/firestore";
import { useAtom, useAtomValue } from "jotai";
import { TbBuildingBank } from "react-icons/tb";
import { ComputedResult } from "./ComputedResult";
import { DealFees } from "./DealFees";
import { InputDate } from "./InputDate";
import { InputPercent } from "./InputPercent";

const Panel = () => {
  const [interestRate, setInterestRate] = useAtom(sbaLoanInterestRateAtom);
  const [loanStartDate, setLoanStartDate] = useAtom(sbaLoanStartDateAtom);
  const amortSchedule = useAtomValue(sbaLoanAmortizationScheduleAtom);
  return (
    <Stack gap={4}>
      <InputPercent
        label={"Interest Rate"}
        value={numberToPercentInput(interestRate)}
        onChange={(value) => setInterestRate(percentInputToNumber(value))}
      />

      <ComputedResult
        label="Amortization Schedule"
        value={amortSchedule.toFixed(2).replace(".00", "") + " years"}
        variant="default"
        my={6}
      />
      <InputDate
        label={"Loan Start Date"}
        value={loanStartDate.toDate()}
        onChange={(e) => setLoanStartDate(Timestamp.fromDate(e as Date))}
      />
      <DealFees />
    </Stack>
  );
};

export const ModuleSBA7aLoan = {
  key: "SBA 7(a) Loan",
  icon: <TbBuildingBank />,
  control: "SBA 7(a) Loan",
  panel: <Panel />,
};
