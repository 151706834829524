import { LayoutDoc } from "@/components/LayoutDoc/LayoutDoc";
import { PageForgotPassword } from "@/components/PageForgotPassword/PageForgotPassword";
import { PageHome } from "@/components/PageHome/PageHome";
import { PageLearn } from "@/components/PageLearn/PageLearn";
import { PageLogin } from "@/components/PageLogin/PageLogin";
import { PageModel } from "@/components/PageModel/PageModel";
import { PageNotFound } from "@/components/PageNotFound/PageNotFound";
import { PageProfile } from "@/components/PageProfile/PageProfile";
import { PageSignup } from "@/components/PageSignup/PageSignup";
import { routes as docRoutes } from "@/docs/_routes";
import { Route } from "@/paths";
import React from "react";
import { Outlet } from "react-router-dom";
import { LayoutBoilerplateText } from "./components/LayoutBoilerplateText/LayoutBoilerplateText";
import { PageError } from "./components/PageError/PageError";
import { Main } from "./components/PageModel/Main/Main";
import { PageModels } from "./components/PageModels/PageModels";
import { RequireAuth } from "./components/RequireAuth/RequireAuth";

const PrivacyPolicy = React.lazy(() => import("@/docs/privacy-policy.mdx"));
const TermsOfService = React.lazy(() => import("@/docs/terms-of-service.mdx"));

export const routes = [
  {
    ...(import.meta.env.PROD ? { errorElement: <PageError /> } : {}),
    children: [
      ...docRoutes.map((route) => {
        const Component = route.element;
        return {
          path: route.path,
          element: (
            <LayoutDoc>
              <Component />
            </LayoutDoc>
          ),
        };
      }),
      {
        path: Route.Home,
        element: <PageHome />,
      },
      {
        path: Route.Learn,
        element: <PageLearn />,
      },

      // Auth routes
      {
        path: Route.Login,
        element: <PageLogin />,
      },
      {
        path: Route.Signup,
        element: <PageSignup />,
      },
      {
        path: Route.ForgotPassword,
        element: <PageForgotPassword />,
      },

      {
        element: <RequireAuth />,
        children: [
          {
            path: Route.Profile,
            element: <PageProfile />,
          },

          // Model routes
          {
            path: Route.Models,
            element: <PageModels />,
          },
          {
            path: Route.Model,
            element: <PageModel />,
            children: [
              {
                path: ":modelTabId",
                element: <Main />,
              },
            ],
          },
        ],
      },

      // Legal routes
      {
        element: (
          <LayoutBoilerplateText>
            <Outlet />
          </LayoutBoilerplateText>
        ),
        children: [
          {
            path: Route.PrivacyPolicy,
            element: <PrivacyPolicy />,
          },
          {
            path: Route.TermOfService,
            element: <TermsOfService />,
          },
        ],
      },
      {
        path: Route.NotFound,
        element: <PageNotFound />,
      },
    ],
  },
];
export { Route };
