import dotsBg from "@/assets/dots.png";
import rippleSvg from "@/assets/ripple.svg";
import "@fontsource-variable/dm-sans";
import "@fontsource-variable/roboto-mono";
import "@fontsource/marcellus";

import {
  Button,
  createTheme,
  CSSVariablesResolver,
  Input,
} from "@mantine/core";

export const textFontFamily = `'DM Sans Variable', sans-serif`;
export const monospaceFontFamily = `'Roboto Mono Variable', monospace`;
export const inputFontFamily = monospaceFontFamily;

export const theme = createTheme({
  primaryColor: "brand",
  primaryShade: 6,
  colors: {
    accent: [
      "#E3F2FD",
      "#BBDEFB",
      "#90CAF9",
      "#64B5F6",
      "#42A5F5",
      "#2196F3",
      "#1E88E5",
      "#1976D2",
      "#1565C0",
      "#0D47A1",
    ],
    brand: [
      "#6F9FDB",
      "#4D81C3",
      "#265A9E",
      "#0E4175",
      "#083567",
      "#052B58",
      "#063446",
      "#063042",
      "#021B2B",
      "#010D16",
    ],
    infomatic: [
      "#EFFCF8",
      "#C7E8E0",
      "#9ED4C8",
      "#76C0AF",
      "#4EAC97",
      "#339684",
      "#1F775E",
      "#175F4B",
      "#0D4635",
      "#072B1F",
    ],
    styledBackgrounds: [
      `red`,
      `red`,
      `red`,
      `red`,
      `red`,
      `var(--mantine-color-brand-8) url("${rippleSvg}") no-repeat center bottom`,
      `#F4F6F9 url("${dotsBg}")`,
      `red`,
      `red`,
      `red`,
    ],
  },

  fontFamily: textFontFamily,
  headings: {
    fontFamily: "Marcellus, sans-serif",
    //fontWeight: "500",
    // sizes: {
    //   h1: HeadingStyle;
    //   h2: HeadingStyle;
    //   h3: HeadingStyle;
    //   h4: HeadingStyle;
    //   h5: HeadingStyle;
    //   h6: HeadingStyle;
    // };
  },

  defaultGradient: {
    from: "brand.4",
    to: "brand.6",
    deg: 30,
  },

  components: {
    Button: Button.extend({
      defaultProps: {
        color: "accent",
        //ff: inputFontFamily,

        fw: 600,
        tt: "capitalize",
        //variant: "gradient",
        //gradient: { from: "brand.3", to: "brand.7", deg: 0 },
        //color: "brand.7",
        radius: 0,
      },
      vars: (_theme, props) => {
        if (props.variant === "primary") {
          return {
            root: {
              // "--button-fz": "16px",
              // fontFamily: "Inter"
              // "--button-hover": "2px solid white"
            },
          };
        }

        return { root: {} };
      },
    }),
    Badge: {
      defaultProps: {
        fw: 500,
      },
    },
    Grid: {
      defaultProps: {
        overflow: "hidden",
        mb: "sm",
      },
    },
    Input: {
      defaultProps: {
        // fw: 500,
        // bg: "red",
        //variant: "filled",
      },
    },
    InputLabel: Input.Label.extend({
      defaultProps: {
        // fw: 500,
        // bg: "red",
        mb: 4,
      },
    }),
    NumberInput: {
      styles: {
        input: {
          fontFamily: inputFontFamily,
          lineHeight: 0.8,
        },
      },
    },
    Select: {
      styles: {
        dropdown: { fontFamily: inputFontFamily },
      },
    },
  },
});

export const resolver: CSSVariablesResolver = (theme) => ({
  variables: {},
  light: {
    "--_input-font-family": inputFontFamily,
    "--mantine-color-text": theme.colors.brand[8],
    //"--mantine-color-body": "#F8F8F5",
    "--mantine-color-default-border": theme.colors.gray[2],
    "--_input-bg": "red",
  },
  dark: { "--mantine-color-text": theme.colors.brand[2] },
});

export enum ZIndexOrder {
  ZeroIndexPlaceHolderIgnore,
  CellGridStickyLabels,
  CellGridWaterfallTitle,
  CellGridWaterfallLine,
  CellGridEpochRow,
  CellGridIterationRow,
  CellGridTitleRow,
  CellGridScrollbars,
  SidebarCollapseAllButton,
  ModelOnPageLoaderSpinner,
  NotAvailableOnMobileWarning,
}
