import { Grid } from "@mantine/core";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { NumberInputCashFlow } from "./NumberInputCashFlow";
import { NumberInputPurchasePrice } from "./NumberInputPurchasePrice";
import { QuestionnaireSection } from "./QuestionnaireSection";

export const SegmentPurchaseMetrics = () => {
  return (
    <QuestionnaireSection
      label={"Purchase Metrics"}
      icon={RiMoneyDollarCircleLine}
    >
      <Grid>
        <Grid.Col span={{ md: 6, base: 12 }}>
          <NumberInputPurchasePrice />
        </Grid.Col>
        <Grid.Col span={{ md: 6, base: 12 }}>
          <NumberInputCashFlow showExplanation />
        </Grid.Col>
      </Grid>
    </QuestionnaireSection>
  );
};
