import { LayoutPage } from "@/components/LayoutPage/LayoutPage";
import { Box, Container, Grid, Stack, Text, Title } from "@mantine/core";
import { useScrollIntoView } from "@mantine/hooks";
import { NewsletterBanner } from "../NewsletterBanner/NewsletterBanner";
import { FeaturesTitle } from "./FeaturesTitle";
import { Jumbotron } from "./Jumbotron";
import { MobileStats } from "./MobileStats";
import { Questionnaire } from "./Questionnaire";
import { Stats } from "./Stats";

export const PageHome = () => {
  const { scrollIntoView, targetRef } = useScrollIntoView<HTMLDivElement>({
    offset: 160,
    duration: 800,
  });

  return (
    <LayoutPage>
      <Jumbotron
        scrollTo={() => {
          scrollIntoView({
            alignment: "start",
          });
        }}
      />
      <FeaturesTitle />
      <Box
        bg={"styledBackgrounds.6"}
        style={{
          borderTop: "1px solid var(--mantine-color-gray-2)",
        }}
        pb={{ base: 40, md: 80 }}
      >
        <Container size={"lg"}>
          <Stack p={{ base: 40, md: 80 }}>
            <Title ref={targetRef} ta={"center"}>
              Deal Snapshot
            </Title>

            <Text fz={{ base: 16, sm: 20, md: 24 }} ta={"center"}>
              Plug in your deal's assumptions to quickly calculate the
              fundamentals.
            </Text>
          </Stack>
          <MobileStats />
          <Grid
            gutter={{ base: "lg", md: "xl" }}
            p={0}
            style={{ overflow: "clip" }}
          >
            <Grid.Col span={{ sm: 7, md: 8 }}>
              <Questionnaire />
            </Grid.Col>
            <Grid.Col span={{ sm: 5, md: 4 }} visibleFrom="sm">
              <Stats />
            </Grid.Col>
          </Grid>
        </Container>
      </Box>
      <NewsletterBanner />
    </LayoutPage>
  );
};
