import {
  Box,
  BoxComponentProps,
  MantineColor,
  RingProgress,
  StyleProp,
  Text,
} from "@mantine/core";
import { useElementSize } from "@mantine/hooks";
import type { Property } from "csstype";
import classes from "./Dial.module.css";

export const Dial = ({
  title,
  subtitle,
  color = "brand.8",
  bg = "gray.2",
  value = 50,
  w = "100%",
  ...props
}: BoxComponentProps & {
  title: string;
  subtitle: string;
  color?: MantineColor;
  value?: number;
  bg?: MantineColor;
  w?: StyleProp<Property.Width<string | number>>;
}) => {
  const { ref, width } = useElementSize();

  const max = 50 + 10 * 2;
  const adjustedValue = (value / 100) * max;

  return (
    <Box
      {...props}
      ref={ref}
      w={w}
      h={width * 0.98}
      style={{ overflow: "none" }}
    >
      <RingProgress
        className={classes.ring}
        size={width}
        thickness={width / 15}
        rootColor="transparent"
        sections={[
          { value: adjustedValue, color },
          { value: max - adjustedValue, color: bg },
        ]}
        label={
          <div>
            <Text
              c={color}
              fw={"bold"}
              ta="center"
              fz={width / 5}
              mt={-width / 20}
            >
              {title}
            </Text>
            <Text
              w={width / 1.34}
              ta="center"
              fz={width / 10}
              c={color}
              fw={"bold"}
              lh={1.3}
              px={width / 10}
              pos="absolute"
            >
              {subtitle}
            </Text>
          </div>
        }
      />
    </Box>
  );
};
