import { lazy as lazyload } from "react";

export const routes = [
  {
    path: "/kitchen-sink",
    element: lazyload(() => import("@/docs/kitchen-sink.mdx")),
  },

  {
    title: "How to care for your dog",
    path: "/learn/educate/dog-care",
    element: lazyload(
      () => import("@/docs/learn/educate/how-to-care-for-your-dog.mdx"),
    ),
  },

  {
    title: "This is a test article",
    path: "/learn/search/dogs",
    element: lazyload(() => import("@/docs/learn/test.mdx")),
  },

  {
    title: "Lorum ipsum",
    path: "/learn/evaluate/cats",
    element: lazyload(() => import("@/docs/learn/test.mdx")),
  },

  {
    title: "David Bowie's Hat",
    path: "/learn/evaluate/acquire/mice",
    element: lazyload(() => import("@/docs/learn/test.mdx")),
  },

  {
    title: "The mouse",
    path: "/learn/cat/cat/mouse",
    element: lazyload(() => import("@/docs/learn/test.mdx")),
  },
];
