import { CellTypes } from "@/financeModels/owasco/types";
import { MathResult } from "@/financeModels/types";
import { Atom, Getter, PrimitiveAtom } from "jotai";

export const getMoleculeValueAtIndex = <T = MathResult>(
  get: Getter,
  molecule: Atom<PrimitiveAtom<CellTypes>[]> | Atom<Atom<CellTypes>[]>,
  index: number,
  fallbackValue: T,
) => {
  const atoms = get(molecule);

  if (!Array.isArray(atoms)) return fallbackValue;
  if (!atoms[index]) return fallbackValue;

  return get(atoms[index]).value as T;
};
