import {
  sellersNoteAmortizationScheduleAtom,
  sellersNoteInterestRateAtom,
  sellersNoteStartDateAtom,
} from "@/financeModels/owasco/general";
import { numberToPercentInput, percentInputToNumber } from "@/utils/format";
import { toNumberOrNull } from "@/utils/math";
import { Stack } from "@mantine/core";
import { Timestamp } from "firebase/firestore";
import { useAtom } from "jotai";
import { TbId } from "react-icons/tb";
import { InputDate } from "./InputDate";
import { InputPercent } from "./InputPercent";
import { InputYears } from "./InputYears";

const Panel = () => {
  const [interestRate, setInterestRate] = useAtom(sellersNoteInterestRateAtom);
  const [schedule, setSchedule] = useAtom(sellersNoteAmortizationScheduleAtom);
  const [loanStartDate, setLoanStartDate] = useAtom(sellersNoteStartDateAtom);

  return (
    <Stack gap={4}>
      <InputPercent
        label={"Interest Rate"}
        value={numberToPercentInput(interestRate)}
        onChange={(value) => setInterestRate(percentInputToNumber(value))}
      />
      <InputYears
        label={"Amortization Schedule"}
        value={toNumberOrNull(schedule) || ""}
        onChange={(value) => setSchedule(toNumberOrNull(value))}
      />
      <InputDate
        label={"Loan Start Date"}
        value={loanStartDate.toDate()}
        onChange={(e) => setLoanStartDate(Timestamp.fromDate(e as Date))}
      />
      {/* <InputToggle label="Full Standby" onChange={() => {}} />
      <InputToggle label="Partial Standby" onChange={() => {}} /> */}
    </Stack>
  );
};

export const ModuleSellersNote = {
  key: "Seller's Note",
  icon: <TbId />,
  control: "Seller's Note",
  panel: <Panel />,
};
