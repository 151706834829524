import { panelClass } from "@/components/LayoutWorkspace/LayoutWorkspace";
import {
  isRealEstateIncludedAtom,
  lenderLineOfCreditTotalAmountAtom,
  loanTransactionFeeAtom,
  realEstateDollarValueAtom,
  sbaGuarantyFeeAtom,
  targetPurchasePriceAtom,
} from "@/financeModels/owasco/general";
import { MathResult } from "@/financeModels/types";
import { monospaceFontFamily } from "@/theme";
import { formatDecimalPercent, formatDollar } from "@/utils/format";
import { Table } from "@mantine/core";
import { useAtomValue } from "jotai";

export const Uses = () => {
  const targetPurchasePrice = useAtomValue(targetPurchasePriceAtom);
  const loanTransactionFee = useAtomValue(loanTransactionFeeAtom);
  const sbaguarantyFee = useAtomValue(sbaGuarantyFeeAtom);
  const isRealEstateIncluded = useAtomValue(isRealEstateIncludedAtom);
  const realEstateDollarValue = useAtomValue(realEstateDollarValueAtom);
  const lineOfCredit = useAtomValue(lenderLineOfCreditTotalAmountAtom);

  const items: [string, MathResult][] = [
    ["Business Acquisition", targetPurchasePrice],
    ...(isRealEstateIncluded
      ? [["Real Estate Transaction", realEstateDollarValue]]
      : // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ([] as any)),

    ["Loan Transaction Fee", loanTransactionFee],
    ["SBA Guaranty Fee", sbaguarantyFee],
  ];

  if (lineOfCredit)
    items.push(["Line of Credit (Working Capital)", lineOfCredit]);

  const totalProjectCost = items.reduce(
    (acc, [, value]) => acc + Number(value),
    0,
  );

  return (
    <Table striped className={panelClass}>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Uses</Table.Th>
          <Table.Th ta={"right"} w={160} pr={35}>
            Amount
          </Table.Th>
          <Table.Th ta={"right"}>% of Total</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {items.map((item) => (
          <Table.Tr key={item[0]}>
            <Table.Td>{item[0]}</Table.Td>
            <Table.Td ta={"right"} w={160} ff={monospaceFontFamily}>
              {formatDollar(Number(item[1]))}
            </Table.Td>
            <Table.Td ff={monospaceFontFamily}>
              {formatDecimalPercent(Number(item[1]) / Number(totalProjectCost))}
            </Table.Td>
          </Table.Tr>
        ))}

        <Table.Tr fw={700}>
          <Table.Td>Total Project Cost</Table.Td>
          <Table.Td ta={"right"} w={160} ff={monospaceFontFamily}>
            {formatDollar(totalProjectCost)}
          </Table.Td>
          <Table.Td fw={700} ff={monospaceFontFamily}>
            100%
          </Table.Td>
        </Table.Tr>
      </Table.Tbody>
    </Table>
  );
};
