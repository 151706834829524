import { useNumberInputValueFix } from "@/hooks/useNumberInputValueFix";
import { NumberInput, NumberInputProps } from "@mantine/core";

export const BigDollarInput = ({
  textAlign,
  ...props
}: NumberInputProps & {
  onChange?: (value: number | string) => void;
  textAlign?: "left" | "center" | "right";
}) => {
  const { onChange, textValue } = useNumberInputValueFix(
    props.value === undefined || typeof props.value === "string"
      ? null
      : props.value,
  );

  return (
    <NumberInput
      placeholder="$"
      thousandSeparator=","
      prefix="$"
      allowNegative={false}
      decimalScale={2}
      hideControls
      size="lg"
      {...props}
      value={textValue}
      onChange={(value) => {
        onChange(value);
        if (!props?.onChange) return;
        props?.onChange(value);
      }}
      styles={{
        ...props.styles,
        input: {
          textAlign: textAlign || "left",
        },
      }}
    />
  );
};
