import { evaluteWithErrors as solve } from "@/utils/math";
import { atom as createAtom } from "jotai";
import { DollarAmount, PercentAmount, YearAmount } from "./types";

export const getPurchaseMultiple = ({
  price,
  cashFlow,
}: {
  price: DollarAmount;
  cashFlow: DollarAmount;
}): PercentAmount =>
  solve<PercentAmount>("price / cashFlow", {
    price: price,
    cashFlow: cashFlow,
  });

export const getMultipleFromPrice = ({
  price,
  cashFlow,
}: {
  price: DollarAmount;
  cashFlow: DollarAmount;
}): PercentAmount =>
  solve<PercentAmount>("price / cashFlow", {
    price: price,
    cashFlow: cashFlow,
  });

export const cashFlowAtom = createAtom<DollarAmount>(null);
export const listingPriceAtom = createAtom<DollarAmount>(null);
export const targetPurchaseMultipleAtom = createAtom<PercentAmount>(3.5);
export const sbaLoanPercentAtom = createAtom<PercentAmount>(0.8);
export const equityPercentAtom = createAtom<PercentAmount>(0.1);
export const sellersNotePercentAtom = createAtom<PercentAmount>(0.1);
export const sbaLoan7AInterestRateAtom = createAtom<PercentAmount>(0.11);
export const sbaLoan7AAmortizationScheduleAtom = createAtom<YearAmount>(10);
export const sellersNoteInterestRateAtom = createAtom<PercentAmount>(0.07);
export const sellersNoteAmortizationScheduleAtom = createAtom<YearAmount>(5);
export const buyersCompenstationAtom = createAtom<DollarAmount>(125000);
export const buyersTaxRateAtom = createAtom<PercentAmount>(0.37);
export const buyersDepreciationAndAmortizationPeriodAtom =
  createAtom<YearAmount>(15);

export const targetPurchasePriceAtom = createAtom((get) =>
  solve<DollarAmount>("cashFlow * targetPurchaseMultiple", {
    cashFlow: get(cashFlowAtom),
    targetPurchaseMultiple: get(targetPurchaseMultipleAtom),
  }),
);

export const listingPurchaseMultipleAtom = createAtom((get) =>
  getPurchaseMultiple({
    price: get(listingPriceAtom),
    cashFlow: get(cashFlowAtom),
  }),
);

export const purchaseMultipleAtom = createAtom((get) =>
  solve<PercentAmount>("targetPurchasePrice / cashFlow", {
    targetPurchasePrice: get(targetPurchasePriceAtom),
    cashFlow: get(cashFlowAtom),
  }),
);

// Finance Structure
export const sbaLoanAmountAtom = createAtom((get) =>
  solve<DollarAmount>("sbaLoanPercent * targetPurchasePrice", {
    targetPurchasePrice: get(targetPurchasePriceAtom),
    sbaLoanPercent: get(sbaLoanPercentAtom),
  }),
);

export const equityAmountAtom = createAtom((get) =>
  solve<DollarAmount>("targetPurchasePrice * equityPercent", {
    targetPurchasePrice: get(targetPurchasePriceAtom),
    equityPercent: get(equityPercentAtom),
  }),
);

export const sellersNoteAmountAtom = createAtom((get) =>
  solve<DollarAmount>("targetPurchasePrice * sellersNotePercent", {
    targetPurchasePrice: get(targetPurchasePriceAtom),
    sellersNotePercent: get(sellersNotePercentAtom),
  }),
);

//Annual Debt Payments (Principal & Interest)
export const annualDebtSbaLoanAtom = createAtom((get) =>
  solve<DollarAmount>(
    "pmt(sbaLoan7AInterestRate,sbaLoan7AAmortizationSchedule,sbaLoanAmount)",
    {
      sbaLoan7AInterestRate: get(sbaLoan7AInterestRateAtom),
      sbaLoan7AAmortizationSchedule: get(sbaLoan7AAmortizationScheduleAtom),
      sbaLoanAmount: get(sbaLoanAmountAtom),
    },
  ),
);

export const annualDebtSellersNoteAtom = createAtom((get) =>
  solve<DollarAmount>(
    "pmt(sellersNoteInterestRate,sellersNoteAmortizationSchedule,sellersNoteAmount)",
    {
      sellersNoteInterestRate: get(sellersNoteInterestRateAtom),
      sellersNoteAmortizationSchedule: get(sellersNoteAmortizationScheduleAtom),
      sellersNoteAmount: get(sellersNoteAmountAtom),
    },
  ),
);

export const annualDebtServiceAtom = createAtom((get) =>
  solve<DollarAmount>("sum(annualDebtSbaLoan,annualDebtSellersNote)", {
    annualDebtSbaLoan: get(annualDebtSbaLoanAtom),
    annualDebtSellersNote: get(annualDebtSellersNoteAtom),
  }),
);

// Assumptions & Calculations that feed into DSCR:
export const buyersEBITDAAtom = createAtom((get) =>
  solve<PercentAmount>("sum(cashFlow,-buyersCompenstation)", {
    cashFlow: get(cashFlowAtom),
    buyersCompenstation: get(buyersCompenstationAtom),
  }),
);

export const buyersInterestExpenseAtom = createAtom((get) =>
  solve<DollarAmount>(
    "impt(sellersNoteInterestRate,1,sellersNoteAmortizationSchedule, sellersNoteAmount) + impt(sbaLoan7AInterestRate,1,sbaLoan7AAmortizationSchedule,sbaLoanAmount)",
    {
      sellersNoteAmount: get(sellersNoteAmountAtom),
      sellersNoteInterestRate: get(sellersNoteInterestRateAtom),
      sellersNoteAmortizationSchedule: get(sellersNoteAmortizationScheduleAtom),
      sbaLoanAmount: get(sbaLoanAmountAtom),
      sbaLoan7AInterestRate: get(sbaLoan7AInterestRateAtom),
      sbaLoan7AAmortizationSchedule: get(sbaLoan7AAmortizationScheduleAtom),
    },
  ),
);

export const buyersDepreciationAmortizationAtom = createAtom((get) =>
  solve<DollarAmount>(
    "-(targetPurchasePrice/buyersDepreciationAndAmortizationPeriod)",
    {
      targetPurchasePrice: get(targetPurchasePriceAtom),
      buyersDepreciationAndAmortizationPeriod: get(
        buyersDepreciationAndAmortizationPeriodAtom,
      ),
    },
  ),
);

export const buyersTaxableIncomeAtom = createAtom((get) =>
  solve<DollarAmount>(
    "sum(buyersEBITDA,buyersInterestExpense,buyersDepreciationAmortization)",
    {
      buyersEBITDA: get(buyersEBITDAAtom),
      buyersInterestExpense: get(buyersInterestExpenseAtom),
      buyersDepreciationAmortization: get(buyersDepreciationAmortizationAtom),
    },
  ),
);

export const buyersIncomeTaxesAtom = createAtom((get) =>
  solve<DollarAmount>("-buyersTaxableIncome * buyersTaxRate", {
    buyersTaxableIncome: get(buyersTaxableIncomeAtom),
    buyersTaxRate: get(buyersTaxRateAtom),
  }),
);

export const buyersNetIncomeAtom = createAtom((get) =>
  solve<DollarAmount>("sum(buyersTaxableIncome,buyersIncomeTaxes)", {
    buyersTaxableIncome: get(buyersTaxableIncomeAtom),
    buyersIncomeTaxes: get(buyersIncomeTaxesAtom),
  }),
);

export const buyersUnleveredCashFlowAtom = createAtom((get) =>
  solve<DollarAmount>(
    "sum(-buyersInterestExpense,-buyersDepreciationAmortization, buyersNetIncome)",
    {
      buyersInterestExpense: get(buyersInterestExpenseAtom),
      buyersDepreciationAmortization: get(buyersDepreciationAmortizationAtom),
      buyersNetIncome: get(buyersNetIncomeAtom),
    },
  ),
);

export const dscrAtom = createAtom((get) =>
  solve<number>("-(buyersUnleveredCashFlow / annualDebtService)", {
    buyersUnleveredCashFlow: get(buyersUnleveredCashFlowAtom),
    annualDebtService: get(annualDebtServiceAtom),
  }),
);
