import { DeferredRender } from "@/components/DeferedRender/DeferedRender";
import {
  addbacksAndAdjustmentsItemization,
  annualDeprAmortizationExpensesMolecule,
  annualMaintenanceCapExMolecule,
  buyersAdjustedEBITDAMarginsMolecule,
  buyersAdjustedEBITDAMolecule,
  buyersCompensationMolecule,
  companysTaxRateMolecule,
  costOfGoodsSoldItemization,
  distributableFreeCashFlowMolecule,
  grossMarginsMolecule,
  grossProfitMolecule,
  grossRevenueItemization,
  incomeTaxesMolecule,
  lineOfCreditInterestMolecule,
  lineOfCreditPricipalMolecule,
  netIncomeMolecule,
  newOperatingExpensesItemization,
  operatingExpensesItemization,
  otherIncomeMolecule,
  sba7aLoanExpenseInterestMolecule,
  sba7aLoanExpensePricipalMolecule,
  sba7aLoanExpenseTotalMolecule,
  sellersDeprAmortMolecule,
  sellersDiscretionaryEarningsMarginsMolecule,
  sellersDiscretionaryEarningsMolecule,
  sellersEBITDAMolecule,
  sellersIncomeTaxMolecule,
  sellersInterestExpenseMolecule,
  sellersNoteExpenseInterestMolecule,
  sellersNoteExpensePricipalMolecule,
  sellersNoteExpenseTotalMolecule,
  taxableIncomeMolecule,
  totalDebtServiceMolecule,
  unleveredFreeCashFlowMolecule,
} from "@/financeModels/owasco/lookback";
import { Badge, Divider } from "@mantine/core";
import { CellRow } from "../CellGrid/CellRow";
import { ComputedLabel } from "../CellGrid/ComputedLabel";
import { Grid } from "../CellGrid/Grid";
import { Itemization } from "../CellGrid/Itemization";
import { TitleRow } from "../CellGrid/TitleRow";
import { Waterfall } from "../CellGrid/Waterfall";
import { EpochRow } from "./EpochRow";

export const PanelLookback = () => {
  let tabYIndex = 0;

  return (
    <Grid w={"100%"} h={"100%"} pos={"relative"}>
      <EpochRow />
      <TitleRow>Company Financials</TitleRow>

      <Waterfall>
        <Itemization
          itemizationAtom={grossRevenueItemization}
          label={<Waterfall.Dot variant="blank">Gross Revenue</Waterfall.Dot>}
          tabY={tabYIndex++}
        />
        <Itemization
          itemizationAtom={costOfGoodsSoldItemization}
          label={
            <Waterfall.Dot variant="minus">
              Cost of Goods Sold (COGS)
            </Waterfall.Dot>
          }
          tabY={tabYIndex++}
        />
        <CellRow
          tabY={tabYIndex++}
          molecule={grossProfitMolecule}
          label={<Waterfall.Formula>Gross Profit</Waterfall.Formula>}
        />
        <CellRow
          variant="transparentWaterfall"
          labelWidth={140}
          tabY={tabYIndex++}
          molecule={grossMarginsMolecule}
          label={
            <ComputedLabel
              tooltip={
                <>
                  = <Badge color="blue">Gross Profit</Badge>/
                  <Badge color="blue">Cost Of Goods</Badge>
                </>
              }
            >
              Gross Margins
            </ComputedLabel>
          }
        />
        <Itemization
          itemizationAtom={operatingExpensesItemization}
          label={
            <Waterfall.Dot variant="minus">Operating Expenses</Waterfall.Dot>
          }
          tabY={tabYIndex++}
        />
        <CellRow
          molecule={otherIncomeMolecule}
          label={<Waterfall.Dot>Other Income</Waterfall.Dot>}
          tabY={tabYIndex++}
        />
        <CellRow
          tabY={tabYIndex++}
          molecule={netIncomeMolecule}
          label={<Waterfall.Formula>Net Income</Waterfall.Formula>}
        />
      </Waterfall>

      <TitleRow children="Seller's Financial Adjustments" />
      <Waterfall>
        <Waterfall.Stub>
          <Waterfall.Dot variant="blank">
            <Waterfall.Pill>Net Income</Waterfall.Pill>
          </Waterfall.Dot>
        </Waterfall.Stub>
        <CellRow
          tabY={tabYIndex++}
          molecule={sellersInterestExpenseMolecule}
          label={
            <Waterfall.Dot variant="add">
              Seller's Interest Expenses
            </Waterfall.Dot>
          }
        />
        <CellRow
          tabY={tabYIndex++}
          molecule={sellersDeprAmortMolecule}
          label={
            <Waterfall.Dot>Seller's Depreciation & Amortization</Waterfall.Dot>
          }
        />
        <CellRow
          tabY={tabYIndex++}
          molecule={sellersIncomeTaxMolecule}
          label={<Waterfall.Dot>Seller's Income Taxes</Waterfall.Dot>}
        />

        <CellRow
          tabY={tabYIndex++}
          molecule={sellersEBITDAMolecule}
          label={<Waterfall.Formula>Seller's EBITDA</Waterfall.Formula>}
        />

        <Itemization
          label={<Waterfall.Dot>Seller's Addbacks</Waterfall.Dot>}
          itemizationAtom={addbacksAndAdjustmentsItemization}
          tabY={tabYIndex++}
        />

        <CellRow
          tabY={tabYIndex++}
          molecule={sellersDiscretionaryEarningsMolecule}
          label={
            <Waterfall.Formula>
              Seller's Adjusted EBITDA (SDE)
            </Waterfall.Formula>
          }
        />
      </Waterfall>
      <CellRow
        variant="transparent"
        labelWidth={260}
        tabY={tabYIndex++}
        molecule={sellersDiscretionaryEarningsMarginsMolecule}
        label={
          <ComputedLabel
            tooltip={
              <>
                = <Badge color="blue">Seller's Adjusted EBITDA</Badge>/
                <Badge color="blue">Gross Revenue</Badge>
              </>
            }
          >
            Seller's Adjusted EBITDA Margins
          </ComputedLabel>
        }
      />

      <TitleRow children="Buyer's Pro-Forma Adjustments" />
      <Waterfall>
        <Waterfall.Stub>
          <Waterfall.Dot variant="blank">
            <Waterfall.Pill>Seller's Adjusted EBITDA (SDE)</Waterfall.Pill>
          </Waterfall.Dot>
        </Waterfall.Stub>
        <CellRow
          tabY={tabYIndex++}
          molecule={buyersCompensationMolecule}
          label={
            <Waterfall.Dot variant="minus">Buyer's Compensation</Waterfall.Dot>
          }
        />
        <Itemization
          itemizationAtom={newOperatingExpensesItemization}
          label={
            <Waterfall.Dot variant="minus">
              New Operating Expenses
            </Waterfall.Dot>
          }
          tabY={tabYIndex++}
        />
        <CellRow
          tabY={tabYIndex++}
          molecule={buyersAdjustedEBITDAMolecule}
          label={<Waterfall.Formula>Buyer's Adjusted EBITDA</Waterfall.Formula>}
        />
      </Waterfall>
      <CellRow
        labelWidth={265}
        variant="transparent"
        tabY={tabYIndex++}
        molecule={buyersAdjustedEBITDAMarginsMolecule}
        label={
          <ComputedLabel
            tooltip={
              <>
                = <Badge color="blue">Buyer's Adjusted EBITDA</Badge>/
                <Badge color="blue">Gross Revenue</Badge>
              </>
            }
          >
            Buyer's Adjusted EBITDA Margins
          </ComputedLabel>
        }
      />
      <DeferredRender>
        <TitleRow children="Cash Flow Metrics & Distributions" />

        <Waterfall.Collapse
          title={"Unlevered Free Cash Flow"}
          tabY={tabYIndex++}
          molecule={unleveredFreeCashFlowMolecule}
        >
          <Waterfall.Stub>
            <Waterfall.Dot variant="blank">
              <Waterfall.Pill>Buyer's Adjusted EBITDA</Waterfall.Pill>
            </Waterfall.Dot>
          </Waterfall.Stub>

          <Waterfall.Collapse
            title={"Income Taxes"}
            tabY={tabYIndex++}
            molecule={incomeTaxesMolecule}
            offsetResult
            dotVariant="minus"
          >
            <Waterfall.Stub>
              <Waterfall.Dot variant="blank">
                <Waterfall.Pill>Buyer's Adjusted EBITDA</Waterfall.Pill>
              </Waterfall.Dot>
            </Waterfall.Stub>
            <CellRow
              tabY={tabYIndex++}
              molecule={annualMaintenanceCapExMolecule}
              label={
                <Waterfall.Dot variant="minus">
                  Annual Maintenance CapEx
                </Waterfall.Dot>
              }
            />
            <CellRow
              tabY={tabYIndex++}
              molecule={sba7aLoanExpenseInterestMolecule}
              label={
                <Waterfall.Dot variant="minus">
                  Interest Expense - SBA 7(a) Loan
                </Waterfall.Dot>
              }
            />
            <CellRow
              tabY={tabYIndex++}
              molecule={sellersNoteExpenseInterestMolecule}
              label={
                <Waterfall.Dot variant="minus">
                  Interest Expense - Sellers Note
                </Waterfall.Dot>
              }
            />
            <CellRow
              tabY={tabYIndex++}
              molecule={annualDeprAmortizationExpensesMolecule}
              label={
                <Waterfall.Dot variant="minus">
                  Depreciation & Amortization
                </Waterfall.Dot>
              }
            />
            <CellRow
              tabY={tabYIndex++}
              molecule={taxableIncomeMolecule}
              label={<Waterfall.Formula>Taxable Income</Waterfall.Formula>}
            />
            <CellRow
              tabY={tabYIndex++}
              molecule={companysTaxRateMolecule}
              label={
                <Waterfall.Dot variant="multiply">
                  Company's Tax Rate
                </Waterfall.Dot>
              }
            />
          </Waterfall.Collapse>

          <CellRow
            tabY={tabYIndex++}
            molecule={annualMaintenanceCapExMolecule}
            label={
              <Waterfall.Dot variant="minus">
                Annual Maintenance CapEx
              </Waterfall.Dot>
            }
          />
        </Waterfall.Collapse>

        <Divider mb="sm" />

        <Waterfall.Collapse
          tabY={tabYIndex++}
          molecule={distributableFreeCashFlowMolecule}
          title={"Distributable Free Cash Flow"}
        >
          <Waterfall.Stub>
            <Waterfall.Dot variant="blank">
              <Waterfall.Formula>Unlevered Free Cash Flow</Waterfall.Formula>
            </Waterfall.Dot>
          </Waterfall.Stub>
          <Waterfall.Collapse
            tabY={tabYIndex++}
            molecule={totalDebtServiceMolecule}
            title={"Total Debt Service"}
            offsetResult
            dotVariant="minus"
          >
            <Waterfall.Collapse
              title={"SBA 7(a) Loan Total"}
              tabY={tabYIndex++}
              molecule={sba7aLoanExpenseTotalMolecule}
              offsetResult
              dotVariant="add"
              cap
            >
              <CellRow
                tabY={tabYIndex++}
                molecule={sba7aLoanExpensePricipalMolecule}
                label={<Waterfall.Dot>Principal - SBA 7(a) Loan</Waterfall.Dot>}
              />
              <CellRow
                tabY={tabYIndex++}
                molecule={sba7aLoanExpenseInterestMolecule}
                label={
                  <Waterfall.Dot variant="add">
                    Interest - SBA 7(a) Loan
                  </Waterfall.Dot>
                }
              />
            </Waterfall.Collapse>

            <Waterfall.Collapse
              title={
                <>
                  Line Of Credit{" "}
                  <span
                    style={{
                      fontSize: 11,
                      paddingTop: 2,
                      verticalAlign: "bottom",
                    }}
                  >
                    (Working Capital)
                  </span>
                </>
              }
              tabY={tabYIndex++}
              molecule={lineOfCreditInterestMolecule}
              offsetResult
              dotVariant="add"
            >
              <CellRow
                tabY={tabYIndex++}
                molecule={lineOfCreditPricipalMolecule}
                label={
                  <Waterfall.Dot variant="blank">
                    Principal - Line Of Credit
                  </Waterfall.Dot>
                }
              />
              <CellRow
                tabY={tabYIndex++}
                molecule={lineOfCreditInterestMolecule}
                label={
                  <Waterfall.Dot variant="add">
                    Interest - Line Of Credit
                  </Waterfall.Dot>
                }
              />
            </Waterfall.Collapse>

            <Waterfall.Collapse
              title={"Seller's Note Total"}
              tabY={tabYIndex++}
              molecule={sellersNoteExpenseTotalMolecule}
              offsetResult
              dotVariant="add"
            >
              <CellRow
                tabY={tabYIndex++}
                molecule={sellersNoteExpensePricipalMolecule}
                label={
                  <Waterfall.Dot variant="blank">
                    Principal - Seller's Note
                  </Waterfall.Dot>
                }
              />
              <CellRow
                tabY={tabYIndex++}
                molecule={sellersNoteExpenseInterestMolecule}
                label={
                  <Waterfall.Dot variant="add">
                    Interest - Seller's Note
                  </Waterfall.Dot>
                }
              />
            </Waterfall.Collapse>
          </Waterfall.Collapse>
        </Waterfall.Collapse>
        {/* 
        <Divider mb="sm" /> */}

        {/* <Waterfall.Collapse
          tabY={tabYIndex++}
          molecule={useableFreeCashFlowMolecule}
          title={"Useable Free Cash Flow"}
        >
          <Waterfall.Stub>
            <Waterfall.Dot variant="blank">
              <Waterfall.Pill>Distributable Free Cash Flow</Waterfall.Pill>
            </Waterfall.Dot>
          </Waterfall.Stub>

          <CellRow
            tabY={tabYIndex++}
            molecule={equityInvestorDistributionsMolecule}
            label={
              <Waterfall.Dot variant="minus">
                Equity Investor Distributions
              </Waterfall.Dot>
            }
          />
        </Waterfall.Collapse> */}
        <Divider my="xl" />
      </DeferredRender>
    </Grid>
  );
};
