import { Logo } from "@/components/Logo/Logo";
import { Route } from "@/paths";
import {
  Anchor,
  Box,
  Container,
  Divider,
  Group,
  Stack,
  Text,
} from "@mantine/core";
import { Link } from "react-router-dom";

const menu = [
  {
    title: "Company",
    items: [
      { title: "About Us", url: "/about-us" },
      { title: "Privacy Policy", url: Route.PrivacyPolicy },
      { title: "Terms Of Service ", url: Route.TermOfService },
    ],
  },
  {
    title: "Learn",
    items: [
      { title: "Educate", url: Route.TermOfService },
      { title: "Search", url: Route.TermOfService },
      { title: "Evaluate", url: Route.TermOfService },
      { title: "Acquire", url: Route.TermOfService },
      { title: "Transition", url: Route.TermOfService },
    ],
  },
  {
    title: "Resources",
    items: [
      { title: "CashFlow", url: Route.TermOfService },
      { title: "Deal Tracker", url: Route.TermOfService },
      { title: "Evaluate", url: Route.TermOfService },
    ],
  },
];

export const Footer = () => {
  return (
    <Box component={"footer"} bg={"gray.8"}>
      <Container size={"lg"} pb="xl" pt={80}>
        <Group justify="space-between" align="flex-start">
          <Stack gap={"sm"} justify="flex-start" align="flex-start">
            <Logo width={100} variant="light" />
            <Text size="sm" maw={160} c="gray.4">
              Supercharge your Search Fund journey
            </Text>
            <Anchor
              href="mailto:hello@etaiq.com"
              target="_blank"
              underline="always"
              c="blue.2"
            >
              hello@etaiq.com
            </Anchor>
          </Stack>

          <Group gap={60} mr={30} justify="flex-end" align="flex-start">
            {menu.map((item) => (
              <Stack key={item.title} gap="sm" align="flex-start">
                <Text size="lg" c="gray.1" fw={"bold"}>
                  {item.title}
                </Text>
                <Stack gap="sm">
                  {item.items.map((subItem) => (
                    <Anchor
                      key={subItem.title}
                      component={Link}
                      to={subItem.url}
                    >
                      <Text size="sm" c="gray.4">
                        {subItem.title}
                      </Text>
                    </Anchor>
                  ))}
                </Stack>
              </Stack>
            ))}
          </Group>
        </Group>

        <Divider color="gray.7" my="xl" />
        <Group gap="md" justify="space-between">
          <Text c="gray.6">© {new Date().getFullYear()} ETA IQ.</Text>
          <Text c="gray.6">version. GK2123 • All Rights Reserved.</Text>
        </Group>
      </Container>
    </Box>
  );
};
