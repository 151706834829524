import rocketSvg from "@/assets/rocket.svg";
import {
  Model,
  useCloneModel,
  useCreateModel,
  useModels,
  useRemoveModel,
} from "@/hooks/useModels";
import { Route } from "@/paths";
import {
  ActionIcon,
  Alert,
  Anchor,
  Button,
  Container,
  Divider,
  Group,
  Modal,
  ScrollArea,
  Skeleton,
  Stack,
  Table,
  Text,
  Tooltip,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { BiPencil, BiTrash } from "react-icons/bi";
import { FaArrowRight, FaRegClone } from "react-icons/fa6";
import { Link, generatePath } from "react-router-dom";
import { HeaderButtons } from "../LayoutWorkspace/HeaderButtons";
import { LayoutWorkspace } from "../LayoutWorkspace/LayoutWorkspace";
import { Logo } from "../Logo/Logo";

export const PageModels = () => {
  const [models, isLoading, loadingError] = useModels();
  const [createModel, isCreating, creatingError] = useCreateModel();
  const sortedModels = models.sort((a, b) =>
    a.name.localeCompare(b.name, "en", { sensitivity: "base" }),
  );

  return (
    <Group
      h="100dvh"
      w="100dvw"
      bg={"gray.3"}
      flex={1}
      gap={0}
      justify="center"
      align="center"
      style={{ overflow: "hidden" }}
    >
      <LayoutWorkspace
        containerSize="lg"
        header={
          <>
            <Logo variant="dark" width={60} to={Route.Models} />
            <HeaderButtons />
          </>
        }
      >
        <Container size={"lg"}>
          <Stack>
            {loadingError && (
              <Alert>{loadingError && loadingError.message}</Alert>
            )}
            {creatingError && (
              <Alert>{creatingError && creatingError.message}</Alert>
            )}

            {isLoading && (
              <Stack>
                <Skeleton height={8} />
                <Skeleton height={8} />
                <Skeleton height={8} />
              </Stack>
            )}

            {!isLoading && sortedModels.length === 0 && (
              <Stack justify="center" align="center">
                <img src={rocketSvg} alt="Rocket Logo" width={400} />
                <Button
                  onClick={() => {
                    createModel();
                  }}
                  loading={isCreating}
                  rightSection={<FaArrowRight />}
                >
                  Create Your First Model
                </Button>
              </Stack>
            )}

            {!isLoading && sortedModels.length > 0 && (
              <>
                <Group justify="space-between">
                  <Text size="xl" fw={"bold"}>
                    Models
                  </Text>
                  <Button
                    onClick={() => {
                      createModel();
                    }}
                    loading={isCreating}
                  >
                    Create Model
                  </Button>
                </Group>
                <Divider color="gray.4" />
                <ScrollArea h={"calc(100vh - 140px)"} offsetScrollbars>
                  <Table verticalSpacing="sm">
                    <Table.Thead>
                      <Table.Tr>
                        <Table.Th px={0}>Name</Table.Th>
                        <Table.Th px={0}>Created At</Table.Th>
                        <Table.Th />
                      </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                      {sortedModels.map((model) => (
                        <Row model={model} key={model.id} />
                      ))}
                    </Table.Tbody>
                  </Table>
                </ScrollArea>
              </>
            )}
          </Stack>
        </Container>
      </LayoutWorkspace>
    </Group>
  );
};

const Row = ({ model }: { model: Model }) => {
  const [opened, { open, close }] = useDisclosure(false);
  const [removeModel] = useRemoveModel();
  const [cloneModel] = useCloneModel();

  return (
    <>
      <Table.Tr key={model.id}>
        <Table.Td p={0}>
          <Anchor
            py="md"
            display={"inline-block"}
            w={"100%"}
            component={Link}
            to={generatePath(Route.ModelTab, {
              modelId: model.id,
              modelTabId: "dashboard",
            })}
          >
            {model.name}
          </Anchor>
        </Table.Td>
        <Table.Td p={0}>
          <Anchor
            py="md"
            display={"inline-block"}
            w={"100%"}
            component={Link}
            to={generatePath(Route.ModelTab, {
              modelId: model.id,
              modelTabId: "dashboard",
            })}
          >
            {model.createdAt?.toDate()?.toLocaleDateString()}
          </Anchor>
        </Table.Td>
        <Table.Td p={0}>
          <Group gap={0} justify="flex-end" py="md">
            <Tooltip label="Edit">
              <ActionIcon
                variant="subtle"
                component={Link}
                to={generatePath(Route.ModelTab, {
                  modelId: model.id,
                  modelTabId: "dashboard",
                })}
              >
                <BiPencil />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Clone">
              <ActionIcon variant="subtle" onClick={() => cloneModel(model.id)}>
                <FaRegClone />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Trash">
              <ActionIcon variant="subtle" color="red.8" onClick={open}>
                <BiTrash />
              </ActionIcon>
            </Tooltip>
          </Group>
        </Table.Td>
      </Table.Tr>
      <Modal centered opened={opened} onClose={close} title="Delete Model">
        <Text ta="center">
          Are you sure you want to delete this model? <br />
          This can't be undone.
        </Text>
        <Group justify="space-between" mt="md">
          <Button variant="outline" onClick={close}>
            Cancel
          </Button>
          <Button onClick={() => removeModel(model.id)} color="red">
            Delete "{model.name}"
          </Button>
        </Group>
      </Modal>
    </>
  );
};
