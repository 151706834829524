import { PrimitiveAtom } from "jotai";
import { ModuleBuyersCompensation } from "./ModuleBuyersCompensation";
import { ModuleFinanceStructure } from "./ModuleFinanceStructure";
import { ModuleLenderLineOfCredit } from "./ModuleLenderLineOfCredit";
import { ModuleOperationalAssumptions } from "./ModuleOperationalAssumptions";
import { ModulePurchase } from "./ModulePurchase";
import { ModuleRealEstate } from "./ModuleRealEstate";
import { ModuleSBA7aLoan } from "./ModuleSBA7aLoan";
import { ModuleSellersNote } from "./ModuleSellersNote";

export const items: {
  key: string;
  icon: React.ReactNode;
  control: string;
  panel: React.ReactNode;
  errorAtom?: PrimitiveAtom<boolean>;
}[] = [
  ModulePurchase,
  ModuleRealEstate,
  ModuleFinanceStructure,
  ModuleLenderLineOfCredit,
  ModuleSBA7aLoan,
  ModuleSellersNote,
  ModuleBuyersCompensation,
  ModuleOperationalAssumptions,
];
