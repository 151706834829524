import { auth } from "@/firebase";
import { Button, Container, Stack, Text } from "@mantine/core";
import { useInterval } from "@mantine/hooks";
import { useEffect } from "react";
import {
  useAuthState,
  useSendEmailVerification,
  useSignOut,
} from "react-firebase-hooks/auth";
import { MdMarkEmailRead } from "react-icons/md";
import { LayoutFullscreen } from "../LayoutFullscreen/LayoutFullscreen";

export const PageVerifyYourEmail = () => {
  const [user] = useAuthState(auth);
  const [signOut] = useSignOut(auth);
  const [sendEmailVerification, sending] = useSendEmailVerification(auth);

  const interval = useInterval(() => {
    auth.currentUser?.reload();
    if (auth.currentUser?.emailVerified) window.location.reload();
  }, 1000);

  useEffect(() => {
    interval.start();
    return interval.stop;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LayoutFullscreen>
      <Container size={"xs"}>
        <Stack justify="center" align="center" mb={60}>
          <Text
            size="xl"
            fz={{
              base: 20,
              sm: 34,
            }}
            ta={"center"}
          >
            Please verify your email.
          </Text>
          <MdMarkEmailRead size={140} />
          <Text ta={"center"} size="xl" mb="sm">
            We have sent email to:
          </Text>{" "}
          <Text
            ta="center"
            fz={{
              base: 20,
              sm: 34,
            }}
            fw={700}
            mt={-20}
          >
            {user?.email}
          </Text>
          <Text ta="center">
            Just click on the link in that email to complete your sign up. If
            you dont see it you may need to{" "}
            <strong>check your spam folder.</strong>
          </Text>
          <Button onClick={sendEmailVerification} loading={sending}>
            Resend Verification Email
          </Button>
          <Button variant="outline" color="gray" onClick={signOut}>
            I want to change emails
          </Button>
        </Stack>
      </Container>
    </LayoutFullscreen>
  );
};
