import { DeferredRender } from "@/components/DeferedRender/DeferedRender";
import { Box, Group, Tooltip } from "@mantine/core";
import { ReactNode } from "react";
import { RiQuestionFill } from "react-icons/ri";

export const ComputedLabel = ({
  tooltip,
  children,
}: {
  tooltip: ReactNode;
  children: ReactNode;
}) => (
  <DeferredRender>
    <Tooltip position="right" label={<Group gap={4}>{tooltip}</Group>}>
      <Box
        style={{
          alignSelf: "flex-start",
        }}
      >
        {/* <LabelExplainer explanation="Test 123"> */}
        <Group
          gap={4}
          align="center"
          lh={1.2}
          justify="left"
          style={{
            cursor: "help",
          }}
        >
          {children}
          <RiQuestionFill size={18} />
        </Group>
        {/* </LabelExplainer> */}
      </Box>
    </Tooltip>
  </DeferredRender>
);
