import { ReactNode } from "react";

export const defaultHeight = 36;
export const defaultWidth = 168;

export const Wrapper = ({
  h = defaultHeight,
  w = defaultWidth,
  className,
  children,
  id,
  style,
}: {
  h?: number;
  w?: number;
  children: ReactNode;
  id?: string;
  className?: string;
  style?: React.CSSProperties;
}) => {
  return (
    <div
      className={className}
      style={{
        height: h,
        width: w,
        display: "flex",
        ...style,
      }}
      id={id}
    >
      {children}
    </div>
  );
};
