import { useAuthContext } from "@/contexts/AuthProvider";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const useRedirectWhenUserLoads = (path: string) => {
  const { user } = useAuthContext();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) return;
    const url = location.state?.returnTo || path;
    navigate(url);
    return;
  }, [user, navigate, location.state?.returnTo, path]);
};
