import {
  cashFlowAtom,
  purchaseMultipleAtom,
  targetPurchasePriceAtom,
} from "@/financeModels/owasco/general";
import { toNumberOrNull } from "@/utils/math";
import { Stack } from "@mantine/core";
import { useAtom, useAtomValue } from "jotai";
import { TbPigMoney } from "react-icons/tb";
import { ComputedResult } from "./ComputedResult";
import { InputDollar } from "./InputDollar";

export const Panel = () => {
  const [targetPurchasePrice, setTargetPurchasePrice] = useAtom(
    targetPurchasePriceAtom,
  );

  const [cashFlow, setCashflow] = useAtom(cashFlowAtom);

  const purchaseMultiple = useAtomValue(purchaseMultipleAtom);

  return (
    <Stack gap={4}>
      <InputDollar
        label="Purchase Price"
        value={Number(targetPurchasePrice)}
        onChange={(val) => setTargetPurchasePrice(toNumberOrNull(val))}
      />
      <InputDollar
        label="Cash Flow / SDE"
        value={Number(cashFlow)}
        onChange={(val) => setCashflow(toNumberOrNull(val))}
      />

      <ComputedResult
        label="Purchase Multiple"
        value={
          Number(toNumberOrNull(purchaseMultiple))
            .toFixed(2)
            .replace(".00", "") + "x"
        }
      />
    </Stack>
  );
};

export const ModulePurchase = {
  key: "Purchase Item",
  icon: <TbPigMoney />,
  control: "Purchase Information",
  panel: <Panel />,
};
