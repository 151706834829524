import {
  Anchor,
  Divider,
  Drawer,
  Group,
  Title,
  TypographyStylesProvider,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { ReactNode } from "react";

import { IoSchool } from "react-icons/io5";
import { css } from "vite-plugin-inline-css-modules";

const classes = css`
  .styles {
    h1,
    h2,
    h3 {
      margin-top: 0px !important;
    }
  }

  .anchor {
    text-decoration: underline dotted var(--mantine-color-accent-5) 1px !important;
    text-underline-offset: 3px;
    vertical-align: top;
    line-height: 1.2;
    &:hover {
    }
  }
`;

export const LabelExplainer = ({
  children,
  explanation,
  preventDefault,
}: {
  explanation: ReactNode | string;
  children: ReactNode | string;
  preventDefault?: boolean;
}) => {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <Drawer
        opened={opened}
        onClose={close}
        title={
          <Group justify="center" align="center">
            <IoSchool size={30} />
            <Title order={3} pt={4}>
              Learning Center
            </Title>
          </Group>
        }
        offset={8}
        position="right"
      >
        <Divider mb={"md"} />
        <TypographyStylesProvider p={0} className={classes.styles}>
          {explanation}
        </TypographyStylesProvider>
      </Drawer>
      <Anchor
        inherit
        onClick={(e) => {
          if (preventDefault) e.preventDefault();
          open();
        }}
        className={classes.anchor}
      >
        {children}
      </Anchor>
    </>
  );
};
