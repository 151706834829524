import { Alert, Box, Group, Paper, Stack, Text } from "@mantine/core";
import { IconType } from "react-icons";
import { TiWarning } from "react-icons/ti";

export const QuestionnaireSection = ({
  label,
  children,
  error,
  icon,
  iconSize = "1.8rem",
}: {
  label: string | React.ReactNode;
  children: React.ReactNode;
  withBorder?: boolean;
  error?: string;
  iconSize?: string;
  icon: IconType;
}) => {
  return (
    <Paper
      pos={"relative"}
      p={{ md: "lg", base: "md" }}
      style={{
        borderRight: "1px solid var(--mantine-color-gray-3)",
        borderTop: "1px solid var(--mantine-color-gray-3)",
        borderBottom: "1px solid var(--mantine-color-gray-3)",
        borderLeft: "4px solid var(--mantine-color-infomatic-2)",
      }}
    >
      <Group
        pos="relative"
        justify="left"
        align="center"
        style={{
          //overflow: "hidden",
          width: "100%",
          borderBottom: "1px dashed var(--mantine-color-gray-3)",
          //backgroundImage: `url(${dotsPng})`,
        }}
        pb={18}
        px={10}
      >
        {icon({
          size: iconSize,
          //opacity: 0.9,
        })}

        <Text
          //tt={"uppercase"}
          component="h2"
          fw={700}
          variant="white"
          size="lg"
        >
          {label}
        </Text>
      </Group>
      <Box p={"xl"}>{children}</Box>

      <Box
        aria-hidden
        className="cornerPageFold"
        pos={"absolute"}
        right={-1}
        top={-1}
        style={{
          width: 0,
          borderWidth: "0 20px 20px 0",
          borderStyle: "solid",
          borderColor:
            "#F4F6F9 #F4F6F9 var(--mantine-color-gray-3) var(--mantine-color-gray-3)",
          background: "var(--mantine-color-gray-3)",
          //boxShadow: "0 1px 1px rgba(0,0,0,0.3), -1px 1px 1px rgba(0,0,0,0.2)",
        }}
      ></Box>

      {error && (
        <Stack
          w="100%"
          justify="center"
          align="center"
          style={{
            position: "absolute",
            bottom: "0",
            left: 0,
            //transform: "translateY(100%)",
          }}
        >
          <Alert
            ta={"center"}
            w="100%"
            color="red"
            radius={0}
            title={error}
            icon={<TiWarning size={50} />}
            p={4}
            styles={{
              label: { textAlign: "center", width: "100%", fontWeight: 600 },
            }}
          />
        </Stack>
      )}
    </Paper>
  );
};
