import { DeferredRender } from "@/components/DeferedRender/DeferedRender";
import { RenderWhenInViewportY } from "@/components/RenderWhenInViewportY/RenderWhenInViewportY";
import { BoxComponentProps, Group } from "@mantine/core";
import { Fragment, ReactNode } from "react";
import { defaultHeight } from "./cell/Wrapper";

export const Row = ({
  children,
  alwaysRender = false,
  ...props
}: BoxComponentProps & { children: ReactNode; alwaysRender?: boolean }) => {
  const Wrapper = alwaysRender ? Fragment : RenderWhenInViewportY;
  return (
    <Wrapper>
      <Group
        wrap="nowrap"
        gap={0}
        justify="flex-start"
        {...props}
        h={defaultHeight}
      >
        <DeferredRender>{children} </DeferredRender>
      </Group>
    </Wrapper>
  );
};
