import { useInViewport } from "@mantine/hooks";
import { ReactNode } from "react";
import { defaultHeight } from "../PageModel/Main/CellGrid/cell/Wrapper";

export const RenderWhenInViewportY = ({
  children,
  height = defaultHeight,
}: {
  children: ReactNode;
  height?: number;
}) => {
  const { ref, inViewport } = useInViewport();
  const parent = getScrollParent(ref.current);

  const minWidth = !parent ? 10 : parent.scrollWidth - parent.clientWidth / 2;

  return (
    <div
      ref={ref}
      style={{
        height,
        minWidth,
        //alignSelf: "stretch",
        //background: "red",
      }}
      //className="RRQ"
    >
      {inViewport && children}
    </div>
  );
};

function getScrollParent(node: HTMLElement | null): HTMLElement | null {
  if (node == null) {
    return null;
  }

  if (node.className.includes("mantine-ScrollArea-viewport")) {
    return node;
  } else {
    return getScrollParent(node.parentNode as HTMLElement);
  }
}
