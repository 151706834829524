import { Atom, PrimitiveAtom, atom } from "jotai";
import { createStorableCellAtom } from "./cell";
import { CellTypes } from "./types";

export const createOverridableCellAtom = (
  id: string,
  originAtom: PrimitiveAtom<CellTypes> | Atom<CellTypes>,
) => {
  const overrideAtom = createStorableCellAtom(id, { value: null } as CellTypes);

  return atom(
    (get) => {
      if (get(overrideAtom)?.value === null)
        return { ...get(originAtom), isOverrided: false, isLocked: false };

      return {
        ...get(originAtom),
        ...get(overrideAtom),
        isOverrided: true,
        isLocked: false,
      } as CellTypes;
    },
    (get, set, arg: CellTypes) => {
      set(
        overrideAtom,
        typeof arg === "function"
          ? (arg as (prev: CellTypes) => CellTypes)(get(originAtom))
          : arg,
      );
    },
  ) as PrimitiveAtom<CellTypes>;
};
