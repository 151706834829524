import { HeaderButtons } from "@/components/LayoutWorkspace/HeaderButtons";
import { LayoutWorkspace } from "@/components/LayoutWorkspace/LayoutWorkspace";
import { Logo } from "@/components/Logo/Logo";
import { Route } from "@/paths";
import { Box, Group, Stack, Tabs } from "@mantine/core";
import { BiSolidBinoculars, BiSolidCalendarCheck } from "react-icons/bi";
import { MdViewTimeline } from "react-icons/md";
import { PiPresentationChartFill } from "react-icons/pi";
import { RiEdit2Fill } from "react-icons/ri";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { PanelAmortizationTables } from "./PanelAmortizationTables/PanelAmortizationTables";
import { PanelDashboard } from "./PanelDashboard/PanelDashboard";
import { PanelInputs } from "./PanelInputs/PanelInputs";
import { PanelLookback } from "./PanelLookback/PanelLookback";
import { PanelProjections } from "./PanelProjections/PanelProjections";

const iconProps = { size: 18 };

const tabsData = [
  {
    modelTabId: "dashboard",
    icon: <PiPresentationChartFill {...iconProps} />,
    panel: <PanelDashboard />,
    label: "Dashboard",
  },
  {
    modelTabId: "edit",
    icon: <RiEdit2Fill {...iconProps} />,
    panel: <PanelInputs />,
    label: "Inputs",
  },
  {
    modelTabId: "lookback",
    icon: <MdViewTimeline {...iconProps} />,
    panel: <PanelLookback />,
    label: "Historicals",
  },
  {
    modelTabId: "projections",
    icon: <BiSolidBinoculars {...iconProps} />,
    panel: <PanelProjections />,
    label: "Projections",
  },
  // {
  //   modelTabId: "summary",
  //   icon: <HiMiniDocumentText {...iconProps} />,
  //   panel: <PanelSummary />,
  //   label: "Summary",
  // },
  {
    modelTabId: "tables",
    icon: <BiSolidCalendarCheck {...iconProps} />,
    panel: <PanelAmortizationTables />,
    label: "Amortization",
  },
];

export const Main = () => {
  const { modelTabId = "dashboard" } = useParams();

  const currentTab = modelTabId
    ? tabsData.find((tab) => tab.modelTabId.includes(modelTabId))
    : tabsData[0];

  return (
    <LayoutWorkspace header={<Header />}>{currentTab?.panel}</LayoutWorkspace>
  );
};

const Header = () => {
  const navigate = useNavigate();
  const { modelTabId = "dashboard", modelId = "error" } = useParams();

  const currentTab = modelTabId
    ? tabsData.find((tab) => tab.modelTabId.includes(modelTabId))
    : tabsData[0];

  return (
    <>
      <Group>
        <Box hiddenFrom="md">
          <Logo variant="dark" width={60} to={Route.Models} />
        </Box>
        <Stack h={"100%"} justify="flex-end">
          <Tabs
            color="blue"
            keepMounted={false}
            value={currentTab?.modelTabId}
            onChange={(modelTabId) =>
              navigate(
                generatePath(Route.ModelTab, {
                  modelTabId,
                  modelId,
                }),
              )
            }
            ml={-4}
          >
            <Tabs.List>
              {tabsData.map((tab) => (
                <Tabs.Tab
                  key={tab.modelTabId}
                  value={tab.modelTabId}
                  leftSection={tab.icon}
                  hiddenFrom={tab.label === "Inputs" ? "md" : "base"}
                  fw={600}
                  px={{
                    sm: "xs",
                    md: 16,
                    lg: "lg",
                  }}
                >
                  <Box visibleFrom="sm">{tab.label}</Box>
                </Tabs.Tab>
              ))}
            </Tabs.List>
          </Tabs>
        </Stack>
      </Group>

      <HeaderButtons />
    </>
  );
};
