import { CellTypes } from "@/financeModels/owasco/types";
import { BoxComponentProps } from "@mantine/core";
import { Atom, PrimitiveAtom, useAtomValue } from "jotai";
import { ReactNode } from "react";
import { css } from "vite-plugin-inline-css-modules";
import { CellSlot } from "./CellSlot";
import { LabelColumn } from "./LabelColumn";
import { Row } from "./Row";

const classes = css`
  .transparentVariant {
    box-shadow: none !important;
    border-bottom: none;
    font-style: italic;

    :global(.label-column-sticky) {
      background: #dee2e6 !important;
    }
    :global(.label-column) {
      background: #dee2e6 !important;
      box-shadow: inset -1px 0 0 #ccc !important;
    }
    :global(.stub-cell) {
      background: #dee2e6 !important;
      box-shadow:
        inset -1px 0 0 #ccc,
        inset 0 -1px 0 #ccc,
        inset 0 1px 0 #ccc;
    }
  }

  .transparentWaterfall {
    box-shadow: none !important;
    border-bottom: none;
    font-style: italic;

    :global(.label-column-sticky) {
      background: linear-gradient(
        to right,
        #dee2e6 0px,
        #dee2e6 13px,
        var(--mantine-color-brand-8) 13px,
        var(--mantine-color-brand-8) 15px,
        #dee2e6 15px
      ) !important;
    }
    :global(.label-column) {
      background-color: #dee2e6 !important;
      box-shadow: inset -1px 0 0 #ccc !important;
    }
    :global(.stub-cell) {
      background: #dee2e6 !important;
      box-shadow: inset -1px 0 0 #ccc;
    }
  }
`;

export type CellRowProps = BoxComponentProps & {
  molecule: Atom<PrimitiveAtom<CellTypes>[]> | Atom<Atom<CellTypes>[]>;
  label: ReactNode;
  bg?: string;
  tabX?: number;
  tabY: number;
  labelWidth?: number;
  variant?: "default" | "transparent" | "transparentWaterfall";
  backdropColor?: string;
};

export const CellRow = ({
  molecule,
  label,
  tabX = 0,
  tabY,
  bg = "transparent",
  backdropColor,
  labelWidth,
  variant = "default",
  ...props
}: CellRowProps) => {
  const atoms = useAtomValue(molecule);

  const variantToClassName = {
    default: "",
    transparent: classes.transparentVariant,
    transparentWaterfall: classes.transparentWaterfall,
  };

  return (
    <Row bg={bg} className={variantToClassName[variant] || ""} {...props}>
      <LabelColumn w={labelWidth} bg={"white"} backdropColor={backdropColor}>
        {label}
      </LabelColumn>
      {atoms.map((atom, index) => (
        <CellSlot key={`${atom}`} atom={atom} tabX={tabX + index} tabY={tabY} />
      ))}
    </Row>
  );
};
