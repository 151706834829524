import * as Sentry from "@sentry/react";

export const getSentry = () => {
  if (import.meta.env.DEV) return;

  Sentry.init({
    dsn: "https://5e762dfbc5dfb505efef7a36ff22fb1f@o4507390497193984.ingest.us.sentry.io/4507390498177024",
    integrations: [],
  });
};
