import { textFontFamily } from "@/theme";
import { Autocomplete, Button, Group } from "@mantine/core";
import { memo, useRef, useState } from "react";
import { RiAddBoxFill } from "react-icons/ri";
import { Stub } from "./cell/Stub";
import { Wrapper } from "./cell/Wrapper";
import {
  getFocusableElement,
  useTabCoordinates,
} from "./hooks/useTabCoordinates";

export type AutocompleteCellProps = {
  tabX: number;
  tabY: number;
  isFullWidth?: boolean;
  onSubmit?: (value: string) => void;
};

export const autoCompleteClassName = "autocomplete-cell";

export const AutocompleteCell = memo(
  ({ tabX, tabY, onSubmit, isFullWidth }: AutocompleteCellProps) => {
    const [textValue, setTextValue] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const maybeEnterPressedInDropdownTime = useRef(0);

    const { id, moveFocus, getTabByDir } = useTabCoordinates({
      tabX,
      tabY,
      isFullWidth,
    });

    return (
      <Wrapper id={id} w={240} className={autoCompleteClassName}>
        {!isEditing && (
          <Stub
            justifyContent="left"
            bg={"linear-gradient(to right,white 80%, transparent)"}
            moveFocus={moveFocus}
            label={
              <Group gap={4}>
                <Button
                  size="compact-sm"
                  variant="light"
                  color="accent"
                  onClick={() => setIsEditing(true)}
                  leftSection={<RiAddBoxFill size={18} />}
                  ff={textFontFamily}
                  fz={12}
                >
                  Add Item
                </Button>
              </Group>
            }
            enableEditing={() => {
              maybeEnterPressedInDropdownTime.current = Date.now();
              setIsEditing(true);
            }}
            startEditRegex={/^[a-zA-Z0-9]/}
          />
        )}
        {isEditing && (
          <Autocomplete
            w={240}
            aria-label="Add Item"
            radius={0}
            autoFocus
            data={[]}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                const timeDiff =
                  Date.now() - maybeEnterPressedInDropdownTime.current;

                if (timeDiff < 220) return;

                setIsEditing(false);
                setTextValue("");
                onSubmit && onSubmit(`${textValue || ""}`);
                setTimeout(() => {
                  const t = getTabByDir(
                    {
                      id,
                      tabX,
                      tabY,
                    },
                    "up",
                  )[0];
                  if (!t) return;
                  const ele = document.getElementById(t.id);
                  if (!ele) return;
                  getFocusableElement(ele)?.focus();
                }, 200);
              }
            }}
            placeholder="Enter title for new item"
            value={`${textValue || ""}`}
            onChange={(value) => {
              if (Math.abs(value.length - textValue.length) > 2) {
                maybeEnterPressedInDropdownTime.current = Date.now();
              }

              setTextValue(value);
            }}
            onBlur={() => {
              setIsEditing(false);
              setTextValue("");
            }}
          />
        )}
      </Wrapper>
    );
  },
);
