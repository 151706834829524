import { useNewsletterSignup } from "@/hooks/useNewsletterSignup";
import {
  ActionIcon,
  Box,
  BoxComponentProps,
  Container,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { useState } from "react";
import { FaArrowRight } from "react-icons/fa";

export const NewsletterBanner = (props: BoxComponentProps) => {
  const [email, setEmail] = useState("");
  const [signup, loading] = useNewsletterSignup();
  const [mailSent, setMailSent] = useState(false);

  return (
    <Box bg={"styledBackgrounds.5"} {...props}>
      <Container py={"10vh"}>
        <Stack px={"xl"} align="center">
          <Title fz={40} ta={"center"} c="white">
            Never Miss an Update
          </Title>
          <Text c="white" fz={{ base: 16, sm: 20, md: 24 }} ta={"center"}>
            Subscribe to our newsletter for the latest updates
            <br /> and be the first to know when we go live!
          </Text>
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              await signup(email);
              setMailSent(true);
            }}
          >
            {!mailSent && (
              <TextInput
                value={email}
                onChange={(e) => setEmail(e.currentTarget.value)}
                type="email"
                size="lg"
                placeholder="Enter your email"
                rightSection={
                  <ActionIcon type="submit" size={"lg"} loading={loading}>
                    <FaArrowRight />
                  </ActionIcon>
                }
              />
            )}
            {mailSent && (
              <Text c="white" fz={20} ta={"center"}>
                Thank you for signing up!
              </Text>
            )}
          </form>
        </Stack>
      </Container>
    </Box>
  );
};
