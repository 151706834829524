import { BigDollarInput } from "@/components/BigDollarInput/BigDollarInput";
import { DollarCell as DollarCellType } from "@/financeModels/owasco/types";
import { formatDollarCell } from "@/utils/format";
import { isNumber } from "@/utils/math";
import { PrimitiveAtom, useAtom } from "jotai";
import parseMoney from "parse-money";
import { memo, useCallback, useState } from "react";
import { Stub } from "./cell/Stub";
import { Wrapper } from "./cell/Wrapper";
import { useTabCoordinates } from "./hooks/useTabCoordinates";

export type DollarCellProps = {
  tabX: number;
  tabY: number;
  atom: PrimitiveAtom<DollarCellType>;
};

export const DollarCell = memo(({ tabX, tabY, atom }: DollarCellProps) => {
  const [cell, setCell] = useAtom(atom);
  const [inputValue, setInputValue] = useState(cell.value);
  const [isEditing, setIsEditing] = useState(false);

  const { isLocked, isNegative, isOverrided } = cell;

  const onPaste = useCallback(
    (value: string) => {
      const money = parseMoney(value);
      if (money == null) return;
      try {
        setCell((c) => ({ ...c, value: money.amount }));
      } catch (e) {
        console.error(e);
      }
    },
    [setCell],
  );
  const onCopy = useCallback(() => {
    return `${cell.value}`;
  }, [cell]);
  const { id, moveFocus } = useTabCoordinates({
    tabX,
    tabY,
    onPaste,
    onCopy,
  });
  const isEditMode = !isLocked && isEditing;

  const onChange = (value: string | number) => {
    setInputValue(value === "" ? null : Number(value));
  };

  const onExitEditing = () => {
    setIsEditing(false);
    const value = isNumber(inputValue) ? inputValue : null;
    if (value === cell.value) return;
    setCell((c) => ({ ...c, value }));
  };

  const resetCell = () => {
    setCell((c) => ({ ...c, value: null }));
  };

  //console.log("DollarCell Render", tabX, tabY, `${atom}`);
  // useTraceUpdate({
  //   tabX,
  //   tabY,
  //   atomId: `${atom}`,

  return (
    <Wrapper id={id}>
      {!isEditMode && (
        <Stub
          animationKey={cell.value?.toString()}
          ta="right"
          resetCell={resetCell}
          moveFocus={moveFocus}
          label={
            isNumber(cell.value) && (
              <span
                style={{
                  color:
                    isNegative || cell.value < 0
                      ? "var(--mantine-color-red-9)"
                      : undefined,
                  whiteSpace: "pre",
                }}
              >
                {formatDollarCell(
                  isNegative ? Math.abs(cell.value) * -1 : cell.value,
                )}
              </span>
            )
          }
          enableEditing={(value) => {
            setInputValue(
              value === undefined ? cell.value : (value as typeof cell.value),
            );
            setIsEditing(true);
          }}
          locked={isLocked}
          overrided={isOverrided}
        />
      )}
      {isEditMode && (
        <BigDollarInput
          value={Number(inputValue)}
          onChange={onChange}
          autoFocus
          radius={0}
          size="sm"
          textAlign="right"
          w={"100%"}
          onBlur={onExitEditing}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onExitEditing();
              moveFocus("down");
            }

            if (e.ctrlKey && ["z", "y"].includes(e.key)) {
              onExitEditing();
            }
          }}
        />
      )}
    </Wrapper>
  );
});
