import { QuestionTooltip } from "@/components/QuestionTooltip/QuestionTooltip";
import {
  buyersCompenstationAtom,
  buyersDepreciationAndAmortizationPeriodAtom,
  buyersTaxRateAtom,
} from "@/financeModels/liteAtoms";
import { toNumberOrNull } from "@/utils/math";
import {
  Button,
  Grid,
  Group,
  NumberFormatter,
  Paper,
  Stack,
  Text,
} from "@mantine/core";
import { useAtom } from "jotai";
import { AiFillLock } from "react-icons/ai";
import { FaCircleDollarToSlot } from "react-icons/fa6";

export const SegmentDSCR = () => {
  const [buyersCompenstation] = useAtom(buyersCompenstationAtom);
  const [buyersTaxRate] = useAtom(buyersTaxRateAtom);
  const [buyersDepreciationAndAmortizationPeriod] = useAtom(
    buyersDepreciationAndAmortizationPeriodAtom,
  );

  return (
    <Grid gutter={{ base: "xl", md: "xs" }} align="center" mb={"xl"}>
      <Grid.Col span={{ base: 12, md: 9 }}>
        <Paper
          radius={0}
          px={"lg"}
          pt={"lg"}
          pb={46}
          w="100%"
          pos={"relative"}
          style={{
            backgroundColor: "var(--mantine-color-blue-1)",
            border: "1px solid var(--mantine-color-blue-3)",
          }}
        >
          <Group
            pb={"md"}
            mb="xl"
            w={"100%"}
            justify="center"
            align="center"
            style={{ borderBottom: "1px dashed var(--mantine-color-blue-3)" }}
          >
            <AiFillLock size={23} />
            <Text
              //tt={"uppercase"}
              component="h2"
              fw={700}
              variant="white"
              size="lg"
            >
              DSCR (Buyer’s Assumptions)
            </Text>
          </Group>
          <Group w={"100%"} justify="space-around">
            <Stat
              title={
                <NumberFormatter
                  thousandSeparator
                  prefix="$"
                  decimalScale={0}
                  value={toNumberOrNull(buyersCompenstation) ?? 0}
                />
              }
              subtitle={
                <QuestionTooltip
                  removeQuestionMarkWidth
                  label="Lorum ipsum chip atel"
                >
                  Buyer's <br />
                  Compensation
                </QuestionTooltip>
              }
            />
            <Stat
              title={`${Math.round(
                (toNumberOrNull(buyersTaxRate) ?? 0) * 100,
              )}%`}
              subtitle={
                <QuestionTooltip
                  removeQuestionMarkWidth
                  label="Lorum ipsum halat asal"
                >
                  Tax
                  <br />
                  Rate
                </QuestionTooltip>
              }
            />
            <Stat
              title={`${buyersDepreciationAndAmortizationPeriod} years`}
              subtitle={
                <QuestionTooltip
                  removeQuestionMarkWidth
                  label="Depreciation & Amortization Schedule"
                >
                  D & A<br />
                  Schedule
                </QuestionTooltip>
              }
            />
          </Group>
        </Paper>
      </Grid.Col>
      <Grid.Col span={{ base: 12, md: 3 }}>
        <Stack align="center" gap={"xs"} ta={"center"}>
          <FaCircleDollarToSlot size={40} />
          <Text size="sm" px="sm" mb={"xs"}>
            Create an account and save this model to your Deal Tracker
          </Text>
          <Button>Save Model</Button>
        </Stack>
      </Grid.Col>
    </Grid>
  );
};

const Stat = ({
  title,
  subtitle,
}: {
  title: React.ReactNode;
  subtitle: React.ReactNode;
}) => {
  return (
    <Stack gap={4}>
      <Text size="md" ta="center" fw={700} c="gray.8">
        {subtitle}
      </Text>
      <Text size="xl" ta="center" c="gray.9">
        {title}
      </Text>
    </Stack>
  );
};
