import {
  isLenderLineOfCreditInErrorAtom,
  lenderLineOfCreditDayOneDrawdownAtom,
  lenderLineOfCreditErrorMessageAtom,
  lenderLineOfCreditTotalAmountAtom,
} from "@/financeModels/owasco/general";
import { toNumberOrNull } from "@/utils/math";
import { Alert, Stack } from "@mantine/core";
import { useAtom, useAtomValue } from "jotai";
import { TbCashBanknote } from "react-icons/tb";
import { InputDollar } from "./InputDollar";
import { LabelTooltip } from "./LabelTooltip";

const Panel = () => {
  const [total, setTotal] = useAtom(lenderLineOfCreditTotalAmountAtom);
  const [dayOneDrawdown, setDayOneDrawdown] = useAtom(
    lenderLineOfCreditDayOneDrawdownAtom,
  );
  const errorMessage = useAtomValue(lenderLineOfCreditErrorMessageAtom);

  return (
    <Stack gap={4}>
      <InputDollar
        label={
          <LabelTooltip label="Line Of Credit from Lender - Total amount of Credit for working capital recieved">
            Total Amount
          </LabelTooltip>
        }
        value={toNumberOrNull(total) || ""}
        onChange={(value) => setTotal(toNumberOrNull(value))}
      />
      <InputDollar
        label={
          <LabelTooltip label="Drawdown for day 1 draw down">
            Day One Drawdown
          </LabelTooltip>
        }
        value={toNumberOrNull(dayOneDrawdown) || ""}
        onChange={(value) => setDayOneDrawdown(toNumberOrNull(value))}
      />

      {errorMessage && (
        <Alert variant="light" color="red" ta={"center"}>
          {errorMessage}
        </Alert>
      )}
    </Stack>
  );
};

export const ModuleLenderLineOfCredit = {
  key: "Lender Line Of Credit",
  icon: <TbCashBanknote />,
  control: "Lender Line Of Credit",
  panel: <Panel />,
  errorAtom: isLenderLineOfCreditInErrorAtom,
};
